export const convertTime = (time) => {
    if (!time) {
        // If the time is empty, return an empty string or handle it as needed

    } else {
        // Log to verify the input value
        const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];

        // Create a new Date object using UTC to avoid timezone issues
        const date = new Date(`${time}T00:00:00Z`);
        // Log to verify the converted date

        // Ensure the date is valid
        if (isNaN(date.getTime())) {
            console.error("Fecha inválida:", time); // Log an error if the date is invalid
            return "Fecha inválida";
        }

        const day = date.getUTCDate();
        const monthName = months[date.getUTCMonth()];
        const year = date.getUTCFullYear();

        return `${day} de ${monthName} del ${year}`;
    }

};
