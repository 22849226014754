import React from 'react';
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

const StatusAndSeatTypes = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil
    const isSurface = useMediaQuery("(max-width:1370px)"); // Verifica si la pantalla es móvil

    return (
        <Stack spacing={4} justifyContent="center" alignItems="center" style={{width:"100%"}} >
            <Typography style={{ color: "#fff", fontFamily: "FuturaP-ExtraBold", fontSize: "16px" }}>ESTADO</Typography>
            <Stack direction={isMobile || isTablet || isSurface? "row" : "column"} spacing={isMobile || isSurface? 4 : 4} alignItems="center">
                <img src="/content/img/SC/seating/estadobutacaOcupado.png" width={isMobile ? "60px" : "80px"} />
                <img src="/content/img/SC/seating/estadobutacaSeleccionado.png" width={isMobile ? "60px" : "80px"} />
                <img src="/content/img/SC/estadoDisponible.svg" width={isMobile ? "45px" : "60px"} />
            </Stack>
            <Typography style={{ color: "#fff", fontFamily: "FuturaP-ExtraBold", fontSize: "16px" }}>ASIENTO</Typography>
            <Stack direction={isMobile || isTablet || isSurface? "row" : "column"} spacing={isMobile ? 4 : 4} alignItems="center" style={{borderTop: "1px solid #fff", paddingTop: "25px"}} >
                <img src="/content/img/SC/asientoRegular.svg" width={isMobile ? "50px" : "60px"} />
                <img src="/content/img/SC/asientoley7600.svg" width={isMobile ? "35px" : "50px"} />
                <img src="/content/img/SC/seating/asientoAcompanante.png" width={isMobile ? "70px" : "95px"} />
            </Stack>
        </Stack>
    );
};

export default StatusAndSeatTypes;
