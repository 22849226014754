import Footer from "../components/Footer";
import Navbar from "../components/NavBar";
import ErrorDialog from "../components/componentsGlobal/ErrorDialog";
import MessageDialog from "../components/componentsGlobal/MessageDialog";



export const AppLayout = ({ children }) => {
    return (
        <>
            <Navbar />

            {/* <ErrorDialog /> */}
            {/* <MessageDialog/> */}
            {/* <ErrorDialog/> */}
            {/* // <SideBar /> */}
            {children}
            <Footer />
        </>
    );
}
