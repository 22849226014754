import { createSlice } from "@reduxjs/toolkit";

// Slice para el estado de autenticación
export const authSlice = createSlice({
    name: "auth",
    initialState: {
        // Estado inicial del slice
        status: "checking", // Estado de autenticación: 'checking', 'not-authenticated', 'authenticated', 'recoveringAccess', 'errorLog'
        id_user: null, // ID de usuario
        name_user: null, // Nombre de usuario
        email_user: null, // Correo electrónico de usuario
        status_user: null, // Estado de usuario
        lasname_user: null, // Apellido de usuario
        phone_user: null, // Teléfono de usuario
        indetification_user: null, // Identificación de usuario
        birthdate: null, // Fecha de nacimiento de usuario
        token_user: null, // Token de autenticación
        mensajeError: null, // Mensaje de error, si lo hay
        mensajeErrorLogin: null, // Mensaje de error, si lo hay
        type_user: null, // Tipo de usuario
    },
    reducers: {
        restaurarDatosProcesodePago: (state, { payload }) => {
            state.status = "authenticated";
            state.id_user = payload.id_user;
            state.name_user = payload.name_user;
            state.email_user = payload.email_user;
            state.status_user = payload.status_user;
            state.lasname_user = payload.lasname_user;
            state.phone_user = payload.phone_user;
            state.indetification_user = payload.indetification_user;
            state.birthdate = payload.birthdate;
            state.token_user = payload.token_user;
            state.mensajeError = "";
            state.type_user = payload.type_user;
        },

        // Reducer para establecer la autenticación del usuario
        setUserAuthentication: (state, { payload }) => {
            state.status = "authenticated";
            state.id_user = payload.id_user;
            state.name_user = payload.name_user;
            state.email_user = payload.email_user;
            state.status_user = payload.status_user;
            state.lasname_user = payload.lasname_user;
            state.phone_user = payload.phone_user;
            state.indetification_user = payload.indetification_user;
            state.birthdate = payload.birthdate;
            state.token_user = payload.token_user;
            state.mensajeError = "";
            state.type_user = payload.type_user;
        },
        // Reducer para cerrar la sesión del usuario
        setUserLogout: (state, { payload }) => {
            state.status = "checking"; // Estado de autenticación= 'checking'; 'not-authenticated'; 'authenticated'; 'recoveringAccess'; 'errorLog'
            state.id_user = null; // ID de usuario
            state.name_user = null; // Nombre de usuario
            state.email_user = null; // Correo electrónico de usuario
            state.status_user = null; // Estado de usuario
            state.lasname_user = null; // Apellido de usuario
            state.phone_user = null; // Teléfono de usuario
            state.indetification_user = null; // Identificación de usuario
            state.birthdate = null; // Fecha de nacimiento de usuario
            state.token_user = null; // Token de autenticación
            state.mensajeError = null; // Mensaje de error, si lo hay
            state.type_user = null; // Tipo de usuario;
            localStorage.removeItem("payload_1");
            localStorage.removeItem("payload_2");
        },
        // Reducer para establecer el estado de verificación de credenciales
        setCheckingCredentials: (state) => {
            state.status = "checking";
        },
        // Reducer para establecer el estado de verificación de estado
        setLoadingCredentials: (state) => {
            state.status = "loading";
            state.mensajeError = null;
        },
        setMessageError: (state, { payload }) => {
            state.status = "errorLog";
            state.mensajeError = payload?.mensajeError;
        },
        setMessageErrorLogin: (state, { payload }) => {
            state.mensajeErrorLogin = payload?.mensajeErrorLogin;
        },
    },
});

// Exporta los action creators generados para cada reducer
export const { restaurarDatosProcesodePago , setMessageErrorLogin, setMessageError, setUserAuthentication, setUserLogout, setCheckingCredentials, setLoadingCredentials } = authSlice.actions;
