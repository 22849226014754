// Importar React y los componentes necesarios
import React from "react"; // Importar React
import { AppLayout } from "../../layout"; // Importar el layout principal de la aplicación
import Movie from "../../components/componentsMovie/Movie"; // Importar el componente de película
import CommonPage from "../PageCommon/PageCommon"; // Importar el componente de página común

// Definir el componente de página de película
export const PageMovie = () => {
    // Renderizar el componente de página común
    return (
        // Utilizar el componente de página común como contenedor
        <CommonPage>
            {/* // Utilizar el layout principal de la aplicación */}
            <AppLayout>
                {/* // Mostrar el componente de película */}
                <Movie />
            </AppLayout>
        </CommonPage>
    );
};
