import { Route, Routes, Navigate } from "react-router-dom";
import { LoginPage, RecoverPasLogin } from "../pages";

export const AuthRoutes = () => {
    return (
        <Routes>
            {/* si es login mostrar <Login /> */}
            <Route path="login" element={<LoginPage />} />

            {/* recuperar contraseña del usuario  */}
            <Route path="recover-password" element={<RecoverPasLogin />} />

            {/* si no es ninguna de las anteriores entonces debe mostrar el home page */}
            <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
    );
};
