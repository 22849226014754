// Importar React y los componentes necesarios
import React, { useEffect } from "react";
import { AppLayout } from "../../layout";
import Carousel from "../../components/componentsHome/Carousel";
import CarouselPreventa from "../../components/componentsHome/CarouselPreventa";
import Publicidad from "../../components/componentsHome/Publicidad";
import Slider from "../../components/componentsHome/Slider";
import CommonPage from "../PageCommon/PageCommon";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { clearArticuleList, clearPriceList, clearSeat, clearTarjet, getAritucule, insertSessionIdSlice } from "../../../store/checkout";
// Definir el componente de página principal
export const PageHome = () => {
    const { slidesPreSale, slidesBillboard, slidesHome } = useSelector((state) => state.home);

    const dispatch = useDispatch();
    useEffect(() => {
        localStorage.removeItem("pytmi");
        localStorage.removeItem("ss");
        localStorage.removeItem("rft");
        localStorage.removeItem("invoiceIdBp");
        localStorage.removeItem("step");
        localStorage.removeItem("movieParam");
        localStorage.removeItem("lastExternalReferrerTime");
        localStorage.removeItem("movieParamCon");

        const insertSession = async () => {
            await dispatch(insertSessionIdSlice(""));
            await dispatch(getAritucule());
            dispatch(clearArticuleList());
            dispatch(clearPriceList());
            dispatch(clearTarjet(""));
            dispatch(clearSeat(""));
        };

        insertSession();
    }, [dispatch]);

    return (
        // Utilizar el componente de página común como contenedor
        <CommonPage>
            <AppLayout>
                {/* Mostrar el slider si hay diapositivas disponibles */}
                {slidesHome.length > 0 && <Slider />}
                {/* Mostrar el carrusel si hay diapositivas de cartelera disponibles */}
                {slidesBillboard.length > 0 && <Carousel />}
                {/* Mostrar el carrusel de preventa si hay diapositivas de preventa disponibles */}
                {slidesPreSale.length > 0 && <CarouselPreventa />}
                {/* Mostrar el componente de publicidad */}
                <Publicidad />
            </AppLayout>
        </CommonPage>
    );
};
