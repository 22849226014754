import { createSlice } from "@reduxjs/toolkit";
import { all } from "axios";

// Creamos el slice de Redux para el estado de la página de inicio
export const homeSlice = createSlice({
    name: "home",
    initialState: {
        isPageError500: true,
        isPageError210: true,

        /* Indica si la página de inicio está cargando. Debe empezar en falso para forzar la espera antes de montar los componentes del home */
        isLoadingPgeHome: false,

        /* Estado y arreglo de la lista de películas para el slider del home */
        statusGetSlider: false,
        slidesHome: [],

        /* Estado y arreglo de la cartelera de películas */
        statusGetBillboard: false,
        slidesBillboard: [],

        /* Estado y arreglo de las películas en preventa */
        statusGetPreSale: false,
        slidesPreSale: [],

        /* Arreglo de todas las películas en cartelera, mezclando estreno, normales, próximas y preventa */
        allMoviesBillboard: [],

        /* JSON con todas las películas obtenidas de la respuesta del API */
        dataArrayMovies: [],

        /* ID de la película activa para mostrar su información */
        activeMovieInfo: [],

        /* Datos de la película activa según activeMovieInfo */
        arrayInfoMovie: [],

        /* Indica si la información de la película está cargando */
        isLoadingInfo: false,

        /* Arreglo de tandas de películas */
        arrayTandaMovie: [],

        /* Mensaje de error */
        errorMessage: "",
    },
    reducers: {
        restoreStateHomeNewBd(state, action) {
            const { extraInformacion, informacionPelicula, peliculaActiva } = action.payload;
            state.activeMovieInfo = peliculaActiva;
            state.allMoviesBillboard = informacionPelicula;
            state.arrayInfoMovie = extraInformacion;
        },

        restoreStateHome(state, action) {
            const { allMoviesBillboard, activeMovieInfo, dataArrayMovies, arrayInfoMovie } = action.payload;
            state.allMoviesBillboard = allMoviesBillboard;
            state.activeMovieInfo = activeMovieInfo;
            state.dataArrayMovies = dataArrayMovies;
            state.arrayInfoMovie = arrayInfoMovie;
        },
        /* Reducer para manejar el estado de la carga de la página de inicio */
        isPageError500: (state, { payload }) => {
            state.isPageError500 = payload;
        },

        isPageError210: (state, { payload }) => {
            state.isPageError210 = payload;
        },

        /* Reducer para manejar el estado de la carga de la página de inicio */
        isLoadingPgeHome: (state, { payload }) => {
            state.isLoadingPgeHome = payload;
            state.isPageError500 = true;
        },
        /* Reducer para manejar el estado de la carga de la información de la película */
        setisLoadingInfo: (state, { payload }) => {
            state.isLoadingInfo = payload;
            state.isPageError500 = true;
        },

        /* Reducer para manejar el estado de la lista de películas para el slider del home */
        statusGetSlider: (state) => {
            state.statusGetSlider = true;
            state.isPageError500 = true;
        },
        /* Reducer para actualizar el arreglo de slides del home */
        setslidesHome: (state, { payload }) => {
            state.slidesHome = payload;
            state.isPageError500 = true;
        },

        /* Reducer para manejar el estado de la cartelera */
        statusGetBillboard: (state) => {
            state.statusGetBillboard = true;
        },
        /* Reducer para actualizar el arreglo de la cartelera */
        setslidesBillboard: (state, { payload }) => {
            state.slidesBillboard = payload;
        },

        /* Reducer para manejar el estado de la preventa */
        statusGetPreSale: (state) => {
            state.statusGetPreSale = true;
        },
        /* Reducer para actualizar el arreglo de la preventa */
        setslidesPreSale: (state, { payload }) => {
            state.slidesPreSale = payload;
        },

        /* Reducer para actualizar el arreglo de todas las películas en cartelera */
        setsallMoviesBillboard: (state, { payload }) => {
            state.allMoviesBillboard = payload;
        },
        /* Reducer para guardar el JSON de todas las películas */
        dataArrayMovies: (state, { payload }) => {
            state.dataArrayMovies = payload;
        },

        /* Reducer para activar la información de una película y limpiar el arreglo de información */
        setActiveMovieInfo: (state, { payload }) => {
            state.activeMovieInfo = payload;
            state.arrayInfoMovie = [];
        },

        /* Reducer para actualizar el arreglo de información de la película activa */
        setarrayInfoMovie: (state, { payload }) => {
            state.arrayInfoMovie = payload;
        },

        /* Reducer para vaciar el arreglo de tandas de películas */
        setarrayTandaMovieEmpty: (state, { payload }) => {
            state.arrayTandaMovie = [];
        },
        /* Reducer para actualizar el arreglo de tandas de películas */
        setarrayTandaMovie: (state, { payload }) => {
            state.arrayTandaMovie = payload;
        },

        seterrorMessage: (state, { payload }) => {
            state.errorMessage = payload;
        },
    },
});

// Action creators generados para cada función reductora
export const { restoreStateHomeNewBd, restoreStateHome, isPageError210, isPageError500, setarrayTandaMovieEmpty, statusGetSlider, setslidesHome, statusGetBillboard, setslidesBillboard, statusGetPreSale, setslidesPreSale, isLoadingPgeHome, dataArrayMovies, setsallMoviesBillboard, setActiveMovieInfo, setarrayInfoMovie, setisLoadingInfo, setarrayTandaMovie, seterrorMessage } = homeSlice.actions;
