import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, Rating, Stack, Typography, Backdrop, CircularProgress } from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import "../../styles/nosotros.css";
import axios from "axios";
import { sendApiMail } from "../../../store/mailer/mailer";
import MessageDialog from "../componentsGlobal/MessageDialog";

const theme = createTheme({
    palette: {
        primary: {
            main: "#1976d2",
        },
    },
});

const message = {
    title: "OPINIÓN ENVIADA con éxito",
    icon: "/content/img/SC/check.svg",
    description: "Tu opinión es muy importante para nosotros y nos ayuda a seguir ofreciendo el mejor servicio posible.",
};

const ReviewDialog = ({ handleOpen, open, handleClose }) => {
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [nombre, setNombre] = useState("");
    const [correo, setCorreo] = useState("");
    const [telefono, setTelefono] = useState("");
    const [ratingCompra, setRatingCompra] = useState(0);
    const [ratingWeb, setRatingWeb] = useState(0);
    const [comentario, setComentario] = useState("");
    const [enviado, setEnviado] = useState(false);
    const [alertShow, setAlertShow] = useState(false);
    const [mensajeAlerta, setMensajeError] = useState('');
    const [loadingMail, setLoadingMail] = useState(false);


    const validateEmail = (correo) => {
        // Expresión regular para validar el formato de correo electrónico
        // Esta expresión regular es básica y no cubre todos los casos posibles
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(correo);
    };

    const handleSubmit = async (tipoFormulario) => {
        if (!nombre || nombre.trim() === '' ||
                !correo || correo.trim() === '' ||
                !telefono || telefono.trim() === '' ||
                ratingCompra === 0 ||
                ratingWeb === 0 ||
                !comentario || comentario.trim() === '') {
                setAlertShow(true);
                setMensajeError("Por favor completa todos los campos.");
                return;
            }
        
        if (!validateEmail(correo)) {
            setAlertShow(true);
            setMensajeError("Por favor ingresa un correo válido.");
            return;
        }
        if (isNaN(telefono)) {
            setAlertShow(true);
            setMensajeError("El teléfono debe contener sólo números.");
            return;
        }
        const longitudTelefono = telefono.length;
        if (longitudTelefono < 8 || longitudTelefono > 11) {
            setAlertShow(true);
            setMensajeError("El teléfono debe tener entre 8 y 11 caracteres.");
            return;
          }

        const requestData = {
            nombre: nombre,
            correo: correo,
            telefono: telefono,
            ratingCompra : ratingCompra,
            ratingWeb :ratingWeb,
            comentario: comentario,
        };
        setLoadingMail(true);
        
       
        const result = await sendApiMail({ data: requestData, type: tipoFormulario});
        
        
        if (result.ok === true) {
            setNombre("");
            setCorreo("");
            setTelefono("");
            setRatingCompra(0);
            setRatingWeb(0);
            setComentario("");
            setEnviado(true);
            setAlertShow(false);
            setMensajeError("");
        } else {
            alert("Error al enviar el mensaje");
        }
        setLoadingMail(false);
        setTimeout(() => {
            setEnviado(false); // Establece enviado a true si el envío fue exitoso
        }, 4000); // Ejemplo de espera de 1 segundo antes de simular el resultado
    };



    return (
        <>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
            open={loadingMail}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <ThemeProvider theme={theme}>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    sx: {
                        backgroundImage: "url(/content/img/SC/fondo-CP.svg)",
                        backgroundSize: "cover",
                        color: "white",
                        width: enviado ? "25%" : "50%",
                        height: "auto",
                        borderRadius: "20px",
                        border: "3px solid rgba(255, 255, 255, 0.15)",
                        paddingTop: "2%",
                        paddingLeft: enviado ? "1%" : "2%",
                        paddingBottom: "2%",
                    },
                }}
            >
                {enviado ? (
                    <Stack spacing={2} justifyContent="center" alignItems="center">
                        <img src={message.icon} width={"20%"} />
                        <Typography
                            sx={{
                                color: "#C7C7C7",
                                textAlign: "center",
                                fontFamily: "FuturaP-ExtraBold",
                                fontSize: "28px",
                                textTransform: "uppercase",
                                width: "60%",
                            }}
                        >
                            OPINIÓN ENVIADA con éxito
                        </Typography>
                        <Typography
                            sx={{
                                color: "#C7C7C7",
                                textAlign: "center",
                                fontFamily: "FuturaP-ExtraBold",
                                fontSize: "14px",
                                textTransform: "uppercase",
                                width: "90%",
                            }}
                        >
                            Tu opinión es muy importante para nosotros y nos ayuda a seguir ofreciendo el mejor servicio posible.
                        </Typography>
                    </Stack>
                ) : (
                    <Stack spacing={2}>
                        <Typography
                            sx={{
                                color: "#C7C7C7",
                                fontFamily: "FuturaP-ExtraBold",
                                fontSize: "36px",
                                textTransform: "uppercase",
                            }}
                        >
                            OPINIÓN DE USUARIO
                        </Typography>
                        <Typography
                            sx={{
                                color: "#fff",
                                fontFamily: "FuturaP-ExtraBold",
                                fontSize: "14px",
                            }}
                        >
                            En caso necesitemos contactarte de vuelta, te agradecemos la siguiente información
                        </Typography>
                        <Stack direction="column" spacing={0}>
                            <Typography
                                style={{
                                    color: "#C7C7C7",
                                    fontFamily: "FuturaP-Bold",
                                    fontSize: isMobile ? "15px" : "16px",
                                    textTransform: "uppercase",
                                }}
                            >
                                NOMBRE
                            </Typography>
                            <input className="inputReview" type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                        </Stack>
                        <Stack direction="column" spacing={0}>
                            <Typography
                                style={{
                                    color: "#C7C7C7",
                                    fontFamily: "FuturaP-Bold",
                                    fontSize: isMobile ? "15px" : "16px",
                                    textTransform: "uppercase",
                                }}
                            >
                                CORREO ELECTRÓNICO
                            </Typography>
                            <input className="inputReview" type="text" value={correo} onChange={(e) => setCorreo(e.target.value)} />
                        </Stack>
                        <Stack direction="column" spacing={0}>
                            <Typography
                                style={{
                                    color: "#C7C7C7",
                                    fontFamily: "FuturaP-Bold",
                                    fontSize: isMobile ? "15px" : "16px",
                                    textTransform: "uppercase",
                                }}
                            >
                                TELÉFONO
                            </Typography>
                            <input className="inputReview" type="text" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                        </Stack>
                        <Stack direction="column" spacing={0}>
                            <Typography
                                style={{
                                    color: "#C7C7C7",
                                    fontFamily: "FuturaP-Bold",
                                    fontSize: isMobile ? "15px" : "16px",
                                    textTransform: "uppercase",
                                }}
                            >
                                ¿Cómo calificas nuestro proceso de compra en línea?
                            </Typography>
                            <Rating name="size-medium" defaultValue={0} value={ratingCompra} onChange={(e, newValue) => setRatingCompra(newValue)} />
                        </Stack>
                        <Stack direction="column" spacing={0}>
                            <Typography
                                style={{
                                    color: "#C7C7C7",
                                    fontFamily: "FuturaP-Bold",
                                    fontSize: isMobile ? "15px" : "16px",
                                    textTransform: "uppercase",
                                }}
                            >
                                ¿Qué calificación general le darías a Studio Cinemas?
                            </Typography>
                            <Rating name="size-medium" defaultValue={0} value={ratingWeb} onChange={(e, newValue) => setRatingWeb(newValue)} />
                        </Stack>
                        <Stack direction="column" spacing={1}>
                            <Stack spacing={0}>
                                <Typography
                                    style={{
                                        color: "#C7C7C7",
                                        fontFamily: "FuturaP-Bold",
                                        fontSize: isMobile ? "15px" : "16px",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    Dudas, consultas, comentarios...
                                </Typography>
                                <Typography
                                    style={{
                                        color: "#C7C7C7",
                                        fontFamily: "FuturaP-Bold",
                                        fontSize: isMobile ? "15px" : "11px",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    Nos encanta recibir tus mensajes, esto nos permite estar en constante mejora. ¡Gracias!
                                </Typography>
                            </Stack>
                            <textarea className="textAreaReview scrollable-content" rows="4" cols="50" value={comentario} onChange={(e) => setComentario(e.target.value)}></textarea>
                        </Stack>
                        {alertShow && <Typography style={{ fontSize: isMobile ?"10px" : "10", color: "#FF5733", fontFamily:"FuturaP-Bold",textTransform:"uppercase"}}>{mensajeAlerta}</Typography>}
                        <Button variant="contained" className="btnReview" onClick={() => handleSubmit('reviews')}>
                            ENVIAR
                        </Button>
                    </Stack>
                )}
            </Dialog>
        </ThemeProvider>
        </>
    );
};

export default ReviewDialog;
