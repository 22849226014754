/********************************************** MODULE IMPORTS ****************************************************/
import { errorMessages, secretKey } from "../../api";
import { setCheckingCredentials, setLoadingCredentials, setMessageError, setMessageErrorLogin, setUserAuthentication, setUserLogout } from "./authSlice";

import { exitLogout, recoverPasswordAsync, setLoginAuth, setRegisterAuth } from "./Api_Auth_Providers";
import CryptoJS from "crypto-js";


// Esta acción verifica la autenticación del usuario de forma asíncrona
export const verifyAuthenticationAsync = () => async (dispatch) => {
    // Despacha una acción para indicar que se están verificando las credenciales
    dispatch(setCheckingCredentials());
};

export const startRegisterUser = (formData ) => {
    // Retorna una función asíncrona que recibe el parámetro dispatch
    return async (dispatch) => {
        // Despacha una acción para indicar que se están verificando las credenciales
        dispatch(setLoadingCredentials());

        try {
            //registrar usuario
            const result = await setRegisterAuth({ formData });

            // Inicializar statusCode como undefined
            let statusCode;

            // Verificar si existe en result.data.insertResult.statusCode
            if (result?.data?.insertResult?.statusCode) {
                statusCode = result.data.insertResult.statusCode;
            }
            // Verificar si existe en result.data.statusCode
            else if (result?.data?.statusCode) {
                statusCode = result.data.statusCode;
            }
            // Verificar si existe en result.statusCode
            else if (result?.statusCode) {
                statusCode = result.statusCode;
            }

            if (statusCode === 409) {
                const mensajeError = "Lo sentimos, el correo electrónico ya está registrado";
                dispatch(setMessageError({ mensajeError: mensajeError }));
                dispatch(setCheckingCredentials());
                return { statusCode: statusCode };
            }

            // Verifica el resultado de la autenticación
            if (!result.ok || statusCode === 500) {
                // Si la autenticación falla o hay un error en el servidor, despacha una acción para cerrar la sesión del usuario
                dispatch(setMessageError({ mensajeError: "Lo sentimos pero en este momento no podemos procesar tu solicitud" }));
                dispatch(setCheckingCredentials());
                return { statusCode: statusCode };
            }

            if (statusCode === 210) {
                const mensajeError = "Lo sentimos, pero no se pudo registrar el usuario, favor de intentarlo más tarde";
                dispatch(setMessageError({ mensajeError: mensajeError }));
                dispatch(setCheckingCredentials());
                return { statusCode: statusCode };
            }

            const userData = result.data;
            const insertId = userData.insertResult.data.insertId;
            const token_user = result.data.tokenUser;
            // Crea un objeto con todas las propiedades necesarias
            const userPayload = {
                id_user: insertId,
                name_user: formData.nombre, // Asegúrate de que estos valores existan en userData
                email_user: formData.confirmCorreoElectronico,
                status_user: 1,
                lasname_user: formData.apellidos,
                phone_user: formData.telefono,
                indetification_user: formData.cedula,
                birthdate: formData.fechaNacimiento,
                token_user,
                statusCode,
                type_user: "register",
            };

            // Despacha la acción con el objeto userPayload
            dispatch(setUserAuthentication(userPayload));

            // Si todo sale bien guardamos al usuario en nuestro navegador pendiente de localStorage encriptado
            const payload_1 = CryptoJS.AES.encrypt(JSON.stringify(userPayload), secretKey).toString();
            localStorage.setItem("payload_1", payload_1);

            return { statusCode: statusCode, userPayload };
        } catch (error) {
           // console.log("error: ", error);
            // En caso de cualquier error durante el proceso de autenticación, cierra la sesión y muestra un mensaje de error
            dispatch(setUserLogout());
        }
    };
};


export const startLoginUser = (email, password) => {
    // Retorna una función asíncrona que recibe el parámetro dispatch
    return async (dispatch) => {
        // Despacha una acción para indicar que se están verificando las credenciales
        dispatch(setLoadingCredentials());
        dispatch(setMessageErrorLogin({ mensajeErrorLogin: "" }));

        try {
            //registrar usuario
            const result = await setLoginAuth({ email, password });

            // Inicializar statusCode como undefined
            let statusCode;

            // Verificar si existe en result.data.insertResult.statusCode
            if (result?.data?.insertResult?.statusCode) {
                statusCode = result.data.insertResult.statusCode;
            }
            // Verificar si existe en result.data.statusCode
            else if (result?.data?.statusCode) {
                statusCode = result.data.statusCode;
            }
            // Verificar si existe en result.statusCode
            else if (result?.statusCode) {
                statusCode = result.statusCode;
            }

            if (statusCode === 409) {
                const mensajeError = "Lo sentimos, pero el correo electrónico no se encuentra registrado";
                dispatch(setMessageErrorLogin({ mensajeErrorLogin: mensajeError }));
                dispatch(setCheckingCredentials());
                return { statusCode: statusCode };
            }


             if (statusCode === 401) {
                 const mensajeError = "Lo sentimos, pero no pudimos validar tu correo electrónico y contraseña. Por favor, verifica tus datos e inténtalo nuevamente.";
                 dispatch(setMessageErrorLogin({ mensajeErrorLogin: mensajeError }));
                 dispatch(setCheckingCredentials());
                 return { statusCode: statusCode };
             }


            // Verifica el resultado de la autenticación
            if (!result.ok || statusCode === 500) {
                const mensajeError = "Lo sentimos pero en este momento no podemos procesar tu solicitud";
                dispatch(setMessageErrorLogin({ mensajeErrorLogin: mensajeError }));
                dispatch(setCheckingCredentials());
                return { statusCode: statusCode };
            }

            if (statusCode === 210) {
                const mensajeError = "Lo sentimos, pero no se pudo registrar el usuario, favor de intentarlo más tarde";
                dispatch(setMessageError({ mensajeError: mensajeError }));
                dispatch(setCheckingCredentials());
                return { statusCode: statusCode };
            }


            const userData = result.data.data;


            const userPayload = {
                id_user: userData.id_client_cv,
                name_user: userData.name_client_cv, // Asegúrate de que estos valores existan en userData
                email_user: userData.email_client_cv,
                status_user: userData.status_client_cv,
                lasname_user: userData.apellido_client_cv,
                phone_user: userData.telefono_client_cv,
                indetification_user: userData.cedula_client_cv,
                birthdate: userData.fecha_nacimiento_client_cv,
                token_user: userData.token_exp_client_cv,
                statusCode,
                type_user: "login",
            };

            // Despacha la acción con el objeto userPayload
            dispatch(setUserAuthentication(userPayload));

            // Si todo sale bien guardamos al usuario en nuestro navegador pendiente de localStorage encriptado
            const payload_1 = CryptoJS.AES.encrypt(JSON.stringify(userPayload), secretKey).toString();
            localStorage.setItem("payload_1", payload_1);

            return { statusCode: statusCode, userPayload };
        } catch (error) {
            // console.log("error: ", error);
            // En caso de cualquier error durante el proceso de autenticación, cierra la sesión y muestra un mensaje de error
            dispatch(setUserLogout());
        }
    };
};


// Función exportada que inicia el proceso de cierre de sesión
export const startLogout = () => {
    // Retorna una función asíncrona que recibe el parámetro dispatch
    return async (dispatch) => {
        // Despacha una acción para indicar que se están verificando las credenciales
        dispatch(setCheckingCredentials());

        // Llama a la función exitLogout para realizar el proceso de cierre de sesión

        dispatch(setUserLogout());
    };
};

export const recoverPassword = (email) => {
    return async (dispatch, getState) => {
        try {

            const result = await recoverPasswordAsync({ email });
            return result;

        } catch (error) {
        }
    };
};