

import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { geTemporaryPaymentUpdate } from "../store/checkout";

const useInsertTemporarySession = (movieParam, iformacionBan = "") => {
    const dispatch = useDispatch();
    const checkoutState = useSelector((state) => state.checkout);
    const homeState = useSelector((state) => state.home);
    const authUser = useSelector((state) => state.auth);

    const insertTemporarySession = useCallback(async () => {
        const urlParams = new URLSearchParams(window.location.search);
        // Extraemos el valor asociado al parámetro 'movie' de la URL
        const movieParam = urlParams.get("movie");

        if (!movieParam) {
            return;
        }
        try {
            const setepPyme = 1;
            await dispatch(
                geTemporaryPaymentUpdate(
                    {
                        peliculaActiva: homeState.activeMovieInfo,
                        informacionPelicula: homeState.allMoviesBillboard,
                        extraInformacion: homeState.arrayInfoMovie,
                    },
                    {
                        peliculaActiva: checkoutState.activeTanda,
                        precioDelista: checkoutState.step2,
                        precioDelistaArray: checkoutState.step2.priceList,
                        asientosSeleccionados: checkoutState.step3,
                        articuloSeleccionado: checkoutState.step4.articules,
                        timeToken: checkoutState.timeToken,
                        tokenSessions: checkoutState.tokenSessions,
                        payment: checkoutState.payment,
                    },
                    {
                        datosUsuario: authUser,
                    },
                    {
                        iformacionBanco: iformacionBan,
                    },
                    setepPyme,
                    movieParam,
                ),
            );
            // console.log("insertartSessionTemporal", re);
        } catch (error) {
        }
    }, [dispatch, homeState, checkoutState, authUser]);

    useEffect(() => {
        insertTemporarySession();
    }, [insertTemporarySession]);
};

export default useInsertTemporarySession;
