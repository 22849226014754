// Importar React y los hooks de Redux
import React, { useEffect } from "react"; // Importar React y el hook useEffect
import { useDispatch, useSelector } from "react-redux"; // Importar los hooks de Redux
import { fetchSlides } from "../../../store/home"; // Importar la acción para obtener las diapositivas
import SimpleBackdrop from "../../components/componentsGlobal/SimpleBackdrop"; // Importar el componente de backdrop simple
import { PageError } from "../PageError/PageError"; // Importar el componente de error
import { PageError210 } from "../PageError/PageError210"; // Importar el componente de error 210

// Definir el componente de página común
const CommonPage = ({ children }) => {
    // Obtener el dispatch de Redux
    const dispatch = useDispatch();

    // Obtener el estado del store de Redux
    const { isPageError500, isPageError210, isLoadingPgeHome, slidesHome } = useSelector((state) => state.home);

    // Efecto que se ejecuta cuando el componente se monta
    useEffect(() => {
        // Función para cargar las diapositivas
        const handleFetchSlides = async () => {
            // Despachar la acción para obtener las diapositivas
            await dispatch(fetchSlides());
        };

        // Solo obtener las diapositivas si no están cargadas
        if (slidesHome.length === 0) {
            handleFetchSlides();
        }
    }, [dispatch, slidesHome]);

    // Manejo de errores
    if (!isLoadingPgeHome && isPageError500 && isPageError210) {
        // Si no hay errores y la página no está cargando, mostrar un backdrop simple
        return <SimpleBackdrop />;
    }

    if (!isPageError500 && !isLoadingPgeHome && isPageError210) {
        // Si hay un error de página 210, mostrar el componente de error correspondiente
        return <PageError />;
    }

    if (isPageError500 && !isLoadingPgeHome && !isPageError210) {
        // Si hay un error de página 500, mostrar el componente de error correspondiente
        return <PageError210 />;
    }

    // Renderizar los hijos del componente
    return children;
};

// Exportar el componente de página común
export default CommonPage;
