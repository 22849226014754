import { useState, useEffect, useRef } from "react";
import { Box, Button, Card, IconButton, Typography, useMediaQuery, useTheme, Stack } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMovieInfo } from "../../../store/home";
import { useNavigate } from "react-router-dom";

const CarouselPreventa = () => {
    // obtener la lista de los slider desde el store homeSlice y el estado de la lista de peliculas
    const { statusGetPreSale, slidesPreSale } = useSelector((state) => state.home);
    const dispatch = useDispatch();

    const navigate = useNavigate();


    const [currentIndex, setCurrentIndex] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil
    const isSurface = useMediaQuery("(max-width:1370px)"); // Verifica si la pantalla es móvil
    const transitionDuration = 500; // Duración de la transición en milisegundos
    const autoSlideInterval = 3000; // Intervalo para cambiar automáticamente de imagen
    const [isTransitioning, setIsTransitioning] = useState(false);
    const timeoutRef = useRef(null);
    const MoviesQuantity = slidesPreSale.length;

    useEffect(() => {
        const handleAutoSlide = () => {
            handleNext();
        };

        timeoutRef.current = setInterval(handleAutoSlide, autoSlideInterval);

        return () => {
            clearInterval(timeoutRef.current);
        };
    }, [currentIndex]);

    const handlePrev = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : slidesPreSale.length - 1));
            setIsTransitioning(false);
        }, transitionDuration);
    };

    const handleNext = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex < slidesPreSale.length - 1 ? prevIndex + 1 : 0));
            setIsTransitioning(false);
        }, transitionDuration);
    };

    let visibleItems;

    let focusedItem;

    if (MoviesQuantity === 1) {
        visibleItems = isMobile ? [slidesPreSale[currentIndex]] : [slidesPreSale[currentIndex]];
        focusedItem = isMobile ? slidesPreSale[currentIndex] : slidesPreSale[currentIndex];
    } else if (MoviesQuantity === 2) {
        visibleItems = isMobile ? [slidesPreSale[currentIndex]] : [slidesPreSale[(currentIndex - 1 + slidesPreSale.length) % slidesPreSale.length], slidesPreSale[currentIndex]];
        focusedItem = isMobile ? slidesPreSale[currentIndex] : slidesPreSale[currentIndex];
    } else if (MoviesQuantity === 3) {
        visibleItems = isMobile ? [slidesPreSale[currentIndex]] : [slidesPreSale[(currentIndex - 1 + slidesPreSale.length) % slidesPreSale.length], slidesPreSale[currentIndex], slidesPreSale[(currentIndex + 1) % slidesPreSale.length]];
        focusedItem = isMobile ? slidesPreSale[currentIndex] : slidesPreSale[currentIndex];
    }else if (MoviesQuantity === 4) {
        visibleItems = isMobile ? [slidesPreSale[currentIndex]] : [slidesPreSale[(currentIndex - 1 + slidesPreSale.length) % slidesPreSale.length], slidesPreSale[currentIndex], slidesPreSale[(currentIndex + 1) % slidesPreSale.length], slidesPreSale[(currentIndex + 2) % slidesPreSale.length]];
        focusedItem = isMobile ? slidesPreSale[currentIndex] : slidesPreSale[currentIndex];
    } else {
        visibleItems = isMobile ? [slidesPreSale[currentIndex]] : [slidesPreSale[(currentIndex - 1 + slidesPreSale.length) % slidesPreSale.length], slidesPreSale[currentIndex], slidesPreSale[(currentIndex + 1) % slidesPreSale.length], slidesPreSale[(currentIndex + 2) % slidesPreSale.length], slidesPreSale[(currentIndex + 3) % slidesPreSale.length]];
        focusedItem = isMobile ? slidesPreSale[currentIndex] : slidesPreSale[(currentIndex + 1) % slidesPreSale.length];
    }

    // const visibleItems = isMobile ? [slidesPreSale[currentIndex]] : [slidesPreSale[(currentIndex - 1 + slidesPreSale.length) % slidesPreSale.length], slidesPreSale[currentIndex], slidesPreSale[(currentIndex + 1) % slidesPreSale.length], slidesPreSale[(currentIndex + 2) % slidesPreSale.length], slidesPreSale[(currentIndex + 3) % slidesPreSale.length]];

    const CardBox = styled(Box)(({ theme }) => ({
        flex: "0 0 auto",
        width: isMobile ? "100%" : "20%",
        margin: "0 10px",
        transition: "transform 0.5s, opacity 0.5s",
        marginTop: "20px", // Para bajar las tarjetas
    }));

    const getIdMovieInfo = (idMovie) => {
        /* Elimina la información de la película actual en el almacenamiento local */
        localStorage.removeItem("idLocalMovie");
        /* Establece la nueva película */
        localStorage.setItem("idLocalMovie", idMovie);
        /* Redirige a la página de la película */
        dispatch(setActiveMovieInfo(idMovie));
        navigate("/movie");
    };
    // const focusedItem = isMobile ? slidesPreSale[currentIndex] : slidesPreSale[(currentIndex + 1) % slidesPreSale.length];

    return (
        <Box>
            <Typography
                variant="h4"
                align="center"
                gutterBottom
                style={{
                    position: "absolute",
                    fontSize: isMobile || isTablet? "30px" : "48px",
                    textTransform: "uppercase",
                    color: "#fff",
                    fontFamily: "FuturaP-ExtraBold",
                    marginTop: "3%",
                    marginLeft: isMobile ? "3%" : isTablet ? "19%": isSurface? "24%" : "29%",
                }}
            >
                PREVENTAS Y PRÓXIMOS ESTRENOS
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    width: "100%",
                    padding: "20px 0",
                    justifyContent: "center",
                    overflow: "hidden", // Asegura que el efecto de desenfoque no se desborde
                    paddingTop: isMobile ? "15%" : "10%", // Para bajar el carrusel
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url("/content/img/SC/menu.png")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        zIndex: -1,
                    }}
                />

                <IconButton onClick={handlePrev} sx={{ position: "absolute", left: 0, backgroundColor: "#000", width: "50px", height: "40px", borderRadius: "0px" }}>
                    <ArrowBackIosIcon style={{ color: "#fff" }} />
                </IconButton>
                <Box sx={{ display: "flex", overflow: "hidden", width: "80%", justifyContent: "center", height: "500px" }}>
                    {visibleItems.map((item, index) => {
                        const isFocused = isMobile ? index === 0 : index === 2; // En responsive, el foco está en el primer elemento; en escritorio, en el segundo
                        return (
                            <CardBox
                                key={index}
                                sx={{
                                    transform: isFocused ? "scale(0.9)" : "scale(0.9)",
                                    opacity: isFocused ? 1 : 1,
                                    width: "246px",
                                    position: "relative",
                                }}
                            >
                                {item.type === "preventa" && (
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: "0",
                                            left: "0",
                                            backgroundColor: "#FF03A9",
                                            color: "#fff",
                                            padding: "5px 10px",
                                            fontFamily: "FuturaP-ExtraBold",
                                            fontSize: "19px",
                                            textTransform: "uppercase",
                                            zIndex: 1,
                                            width: "91.5%",
                                            textAlign: "center",
                                        }}
                                    >
                                        preventa
                                    </Box>
                                )}
                                {item.type === "proximamente" && (
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: "66.5%",
                                            left: "0",
                                            backgroundColor: "#F76800",
                                            color: "#fff",
                                            padding: "5px 10px",
                                            fontFamily: "FuturaP-ExtraBold",
                                            fontSize: "19px",
                                            textTransform: "uppercase",
                                            zIndex: 1,
                                            width: "91.5%",
                                            textAlign: "center",
                                        }}
                                    >
                                        PRÓXIMAMENTE
                                    </Box>
                                )}
                                <Card sx={{ maxWidth: 345, margin: "0 auto", backgroundColor: "transparent", boxShadow: "none" }}>
                                    <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                                        <img src={item.image} style={{ width: "246px", height: "352px" }} />
                                        <Typography
                                            variant="h5"
                                            component="div"
                                            style={{
                                                fontSize: "15px",
                                                textTransform: "uppercase",
                                                color: "#fff",
                                                fontFamily: "FuturaP-Bold",
                                            }}
                                        >
                                            {item.title}
                                        </Typography>

                                        {item.type === "preventa" && (
                                            <Button
                                                size="small"
                                                style={{
                                                    fontSize: "15px",
                                                    textTransform: "uppercase",
                                                    color: "#fff",
                                                    fontFamily: "FuturaP-Bold",
                                                    backgroundColor: "#67BF47",
                                                    borderRadius: "6px",
                                                    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
                                                    height: "40px",
                                                }}
                                                className="buttonCartelera"
                                                onClick={() => getIdMovieInfo(item.idMasterMovie)}
                                                
                                            >
                                                {item.buttonText}
                                            </Button>
                                        )}

                                        {item.type === "proximamente" && (
                                            <Button
                                                size="small"
                                                style={{
                                                    fontSize: "15px",
                                                    textTransform: "uppercase",
                                                    color: "#fff",
                                                    fontFamily: "FuturaP-Bold",
                                                    backgroundColor: "#F76800",
                                                    borderRadius: "6px",
                                                    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
                                                    height: "40px",
                                                    width: "168px",
                                                }}
                                            >
                                                <Stack direction="row" justifyContent="center" alignItems="center">
                                                    <img src="/content/img/SC/notificacion.png" style={{ width: "21px", marginRight: "5px" }} />
                                                    {item.buttonText}
                                                </Stack>
                                            </Button>
                                        )}
                                    </Stack>
                                </Card>
                            </CardBox>
                        );
                    })}
                </Box>
                <IconButton onClick={handleNext} sx={{ position: "absolute", right: 0, backgroundColor: "#000", width: "50px", height: "40px", borderRadius: "0px" }}>
                    <ArrowForwardIosIcon style={{ color: "#fff" }} />
                </IconButton>
            </Box>
        </Box>
    );
};

export default CarouselPreventa;
