

import React from 'react'
import { AppLayout } from '../../layout';
import Legales from '../../components/componentsLegales/Legales';






export const PageLegales = () => {


  return (
      <AppLayout>
        <Legales />
      </AppLayout>
  );
}
