import { Route, Routes } from "react-router-dom";
import { AuthRoutes } from "../auth/router";
import { RoutesApp } from "../app/router";
import { useCheckAuth } from "../hooks/useCheckAuth";

export const AppRouter = () => {

    /* Verifica el estado de autenticación del usuario */
    useCheckAuth();

    return (
        <Routes>
            {/* si es login mostrar <Login /> */}
            <Route path="/auth/*" element={<AuthRoutes />} />

            {/* Navegar en cualquier pantalla aunque no este autenticado */}
            <Route path="/*" element={<RoutesApp />} />
        </Routes>
    );
};
