import React from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Input from "@mui/material/Input";
import SeatingChart from "./SeatingChart";
import StatusAndSeatTypes from "./StatusAndSeatTypes ";
import { useSelector } from "react-redux";

import { convertTime } from "../../../hooks/useTimeBillboard";


const Step3 = ({ movieParam }) => {
    const ariaLabel = { "aria-label": "description" };
    const theme = useTheme(); // Obtiene el tema actual
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Determina si es móvil basado en el tamaño de la pantalla
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil
    const isSurface = useMediaQuery("(max-width:1370px)"); // Verifica si la pantalla es móvil

    const { allMoviesBillboard } = useSelector((state) => state.home);
    const { activeTanda } = useSelector((state) => state.checkout);

    // Encontrar la película correspondiente en allMoviesBillboard usando idMasterMovie y activeTanda.idMaster
    const imageMaster = allMoviesBillboard.find((movie) => movie.idMasterMovie === activeTanda.idMaster);

    return (
        <Stack direction={isMobile || isTablet ? "column" : "row"} justifyContent="space-evenly" alignItems={isSurface ? "flex-start" : "center"} style={{ marginTop: isMobile ? "25%" : "1%" }}>
            <Stack direction={isMobile || isTablet ? "row" : "column"} spacing={isMobile ? 2 : 1} alignItems={isMobile || isTablet ? "center" : "flex-start"}>
                <img src={imageMaster.image} alt="imagen" width={isMobile || isTablet ? "120px" : isSurface ? "240px" : "313px"} height={isMobile || isTablet ? "155px" : isSurface ? "350px" : "441px"} />
                <Stack>
                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", textTransform: "uppercase" }}>Pelicula:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", fontWeight: "100", textTransform: "uppercase" }}>{activeTanda.name_movie_bp}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", textTransform: "uppercase" }}>DÍA:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", fontWeight: "100", textTransform: "uppercase" }}>{convertTime(activeTanda.date_time_shifts_bp)}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", textTransform: "uppercase" }}>SALA:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", fontWeight: "100", textTransform: "uppercase" }}>{activeTanda.room_name_shifts_bp}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", textTransform: "uppercase" }}>HORA:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", fontWeight: "100", textTransform: "uppercase" }}>{activeTanda.hora}</Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Stack spacing={isMobile || isTablet ? 2 : isSurface ? -4 : 5} direction={isMobile || isTablet || isSurface ? "column" : "row"} justifyContent="flex-start" alignItems={isMobile || isTablet ? "center" : "flex-start"}>
                <Stack direction="row" spacing={2} style={{ marginTop: isMobile || isTablet ? "8%" : "0%" }}>
                    <Typography style={{ color: "white", fontFamily: "FuturaP-ExtraBold", fontSize: isMobile ? "15px" : "32px", textTransform: "uppercase", backgroundColor: "#66B02E", paddingTop: isMobile ? "3px" : "3px", paddingLeft: isMobile ? "8px" : "15px", paddingRight: isMobile ? "8px" : "14px", paddingBottom: isMobile ? "1px" : "1px", borderRadius: "100%", display: isMobile || isTablet ? "block" : "none" }}>3</Typography>
                    <Typography style={{ color: "white", fontFamily: "FuturaP-ExtraBold", fontSize: isMobile ? "15px" : "32px", fontWeight: "100", textTransform: "uppercase", display: isMobile || isTablet ? "block" : "none" }}>ASIENTOS</Typography>
                </Stack>

                <Stack direction={isMobile ? "column" : "row"} spacing={2} style={{ borderTop: isMobile || isTablet ? "1px solid rgba(255, 255, 255, 0.20)" : "none", width: "100%" }}>
                    {isMobile ? (
                        <SeatingChart movieParam={movieParam} />
                    ) : (
                        <>
                            <Stack direction="column">
                                <Stack direction="column" justifyContent="center" alignItems="center" spacing={0} style={{ display: isMobile || isTablet || isSurface ? "none" : "flex" }}>
                                    <img src="/content/img/SC/pantalla.png" style={{ marginTop: isTablet ? "-8%" : "-14%", width: "100%" }} />
                                    <Typography style={{ color: "#fff", fontFamily: "FuturaP-ExtraBold", fontSize: "32px", marginTop: "-16%", marginBottom: "3%" }}>PANTALLA</Typography>
                                </Stack>
                                <SeatingChart movieParam={movieParam} />
                            </Stack>
                        </>
                    )}
                </Stack>
                <StatusAndSeatTypes />
            </Stack>
        </Stack>
    );
};

export default Step3;
