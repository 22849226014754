import React from "react";
import { Container, Typography, Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";

export const PageError210 = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Container
            sx={{
                textAlign: isMobile ? "center" : "left",
                minHeight: "100vh",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#B4D0C4",
                padding: 0,
            }}
            maxWidth={false}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "centert",
                    alignItems: "center",
                    paddingLeft: isMobile ? "-20px" : "50px",
                    maxWidth: "50%",
                }}
            >
                <Stack style={{ width: isMobile ? "100%" : "80%" }} spacing={5}>
                    <Typography
                        variant="h1"
                        component="div"
                        gutterBottom
                        sx={{
                            fontSize: isMobile ? "96px" : "150px",
                            color: "#4F5B69",
                            fontFamily: "FuturaP-ExtraBold",
                        }}
                    >
                        Oops!
                    </Typography>
                    <Typography variant="h6" component="div" gutterBottom sx={{ color: "#3F3D56", fontFamily: "FuturaP-Light", fontSize: isMobile ? "20px" : "42px", lineHeight: " 93%", fontWeight: "100" }}>
                    Este sitio web se encuentra en mantenimiento
                    </Typography>

                    <Typography variant="h6" component="div" gutterBottom sx={{width: isMobile ? "140%" : "80%" , color: "#3F3D56", fontFamily: "FuturaP-Light", fontSize: isMobile ? "18px" : "35px", lineHeight: " 93%", fontWeight: "100", marginLeft: isMobile ? "-40px !important" : "0px" }}>
                    Pronto estará disponible nuevamente
                    </Typography>
                    <Stack>
                    <Typography variant="body1" sx={{ color: "#3F3D56", fontFamily: "FuturaP-Medium", fontSize: isMobile ? "16px" : "20px", display: isMobile ? "none" : "block" }}>
                            STUDIO CINEMAS
                        </Typography>
                        <Grid container spacing={2} style={{ marginLeft: isMobile ? "20px" : "-16px" }}>
                            <Grid item>
                                <Box component="a" href="https://www.facebook.com/studiocinemascr/" target="_blank" rel="noopener noreferrer">
                                    <img src="/content/img404/facebook.png" alt="Facebook" style={{ width: isMobile ? "28px" : "30px", marginRight: "10px" }} />
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box component="a" href="https://www.instagram.com/_studiocinemascr_/" target="_blank" rel="noopener noreferrer">
                                    <img src="/content/img404/ig.png" alt="Instagram" style={{ width: isMobile ? "28px" : "30px", marginRight: "10px" }} />
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box component="a" href="https://www.tiktok.com/@studiocinemascr" target="_blank" rel="noopener noreferrer">
                                    <img src="/content/img404/tiktok.png" alt="Twitter" style={{ width: isMobile ? "28px" : "30px", marginRight: "10px" }} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Stack>
                </Stack>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "50%",
                }}
            >
                <Box component="img" src="/content/img404/404.png" alt="Error 503" sx={{ maxWidth: isMobile ? "150%" : "110%", height: "auto", marginTop: "10%", marginLeft: "-10%" }} />
            </Box>
            <Typography variant="body1" sx={{ color: "#3F3D56", fontFamily: "FuturaP-Medium", fontSize: isMobile ? "16px" : "20px", display: isMobile ? "block" : "none", marginTop:"10%" }}>
                STUDIO CINEMAS
            </Typography>
        </Container>
    );
};
