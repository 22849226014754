import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Stack, Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { recoverPassword, startLoginUser } from "../../../store/auth/thunks";
import { useDispatch, useSelector } from "react-redux";
import { saveStateToLocalStorage } from "../componentsCheckout/saveStateToLocalStorage";

import { secretKey } from "../../../api";
import CryptoJS from "crypto-js";
import { updateStep1Complete } from "../../../store/checkout";
import { NavLink } from "react-router-dom";

export default function LoginDialog({ handleOpen, open, handleClose }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)");
    const isSurface = useMediaQuery("(max-width:1370px)");
    const [rememberPassword, setRememberPassword] = useState(false);
    const [passwordSent, setPasswordSent] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [messageError, setMessageError] = useState("");
   const dispatch = useDispatch();
  
    const { mensajeErrorLogin } = useSelector((state) => state.auth);
    const checkoutState = useSelector((state) => state.checkout);
    const homeState = useSelector((state) => state.home);

    useEffect(() => {
        let timer;
        if (rememberPassword) {
            timer = setTimeout(() => {
                handleClose();
                setRememberPassword(false);
            }, 15000);
        }

        return () => clearTimeout(timer);
    }, [rememberPassword, handleClose]);

    const handleRememberPassClick = () => {
        setRememberPassword(true);
    };


    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSendRememberPass = async () => {
         setMessageError("");
        const rest = await dispatch(recoverPassword(inputValue)); // Indicar que el pago está en progreso
       // console.log("asasasa", rest.data.statusCode);
        if (rest.data.statusCode === 409) {
             setMessageError("No se encontró el correo electrónico, favor revisar he intentar nuevamente");
        }

        if (rest.data.statusCode === 200) {
            setPasswordSent(true);
        }


      setTimeout(() => {
          setMessageError("");
      }, 5000);

    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleLoginClick  = async () => {
        let isValid = true;
        if (!validateEmail(email)) {
            setEmailError("El formato del correo no es válido");
            isValid = false;
        } else {
            setEmailError("");
        }

        if (password.trim() === "") {
            setPasswordError("La contraseña no puede estar vacía");
            isValid = false;
        } else {
            setPasswordError("");
        }

        if (isValid) {
          // Lógica de inicio de sesión aquí
          const result = await dispatch(startLoginUser(email, password));

          if (result?.statusCode === 200) {
              const userData = result.userPayload;
              saveStateToLocalStorage(checkoutState, homeState);
              const info = {
                  nombre: userData.name_user,
                  apellidos: userData.lasname_user,
                  correo: userData.email_user,
                  cedula: userData.indetification_user,
                  telefono: userData.phone_user,
              };

              const payload_2 = CryptoJS.AES.encrypt(JSON.stringify(info), secretKey).toString();
              localStorage.setItem("payload_2", payload_2);
              dispatch(updateStep1Complete(true));

              setTimeout(() => {
                  handleClose();
              }, 1000);


          } else {
              localStorage.removeItem("payload_2");
          }

        }
    };

    const cerrarModal = () => {
        setRememberPassword(false);
        setPasswordSent(false);
    }

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    sx: {
                        backgroundImage: "url(/content/img/SC/fondo-CP.svg)",
                        backgroundSize: "cover",
                        color: "white",
                        width: rememberPassword ? (isTablet ? "70%" : isSurface ? "35%" : isMobile ? "80%" : "25%") : isMobile ? "80%" : isTablet ? "50%" : "30%",
                        height: "auto",
                        borderRadius: "20px",
                        border: "3px solid rgba(255, 255, 255, 0.15)",
                        padding: "2%",
                        maxWidth: "80%",
                        minWidth: "25%",
                    },
                }}
            >
                {rememberPassword ? (
                    <Stack spacing={2} direction={"column"} alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
                        <img src="/content/img/SC/rememberPass.svg" style={{ width: isTablet ? "20%" : "20%" }} />
                        <Stack alignItems="center" justifyContent="center" spacing={2}>
                            <Typography
                                style={{
                                    color: "#fff",
                                    fontSize: isTablet ? "18px" : "28px",
                                    fontFamily: "FuturaP-ExtraBold",
                                    textTransform: "uppercase",
                                    textAlign: "center",
                                }}
                            >
                                RECUPERACIÓN DE CONTRASEÑA
                            </Typography>
                            {passwordSent ? (
                                <Typography style={{ color: "#fff", fontSize: isTablet ? "12px" : "15px", fontFamily: "FuturaP-Medium", textAlign: "center" }}>
                                    Hemos enviado las instrucciones a tu correo, <br /> Por favor, verifica tu bandeja de entrada
                                </Typography>
                            ) : (
                                <Typography style={{ color: "#fff", fontSize: isTablet ? "12px" : "15px", fontFamily: "FuturaP-Medium", textAlign: "center" }}>
                                    Por favor ingresa tu correo electrónico para <br /> recuperar tu contraseña
                                </Typography>
                            )}

                            <Stack alignItems="center" justifyContent="center" spacing={2}>
                                {passwordSent ? (
                                    <>
                                        <Typography
                                            style={{
                                                color: "#fff",
                                                fontSize: isTablet ? "10px" : "13px",
                                                fontFamily: "FuturaP-Medium",
                                                textAlign: "center",
                                            }}
                                        >
                                            ¿NO RECIBISTE EL CORREO?
                                        </Typography>
                                        <Button onClick={handleSendRememberPass} variant="contained" className="btnResendPass" style={{ width: isTablet ? "100%" : "100%", fontSize: isTablet || isSurface ? "15px" : "19px", height: isTablet || isSurface ? "47.689px" : "57.689px" }}>
                                            ENVIAR NUEVAMENTE
                                        </Button>
                                        <NavLink onClick={cerrarModal} style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "4%", textDecoration: "none", fontSize: "10px" }}>
                                            ¿Ya tienes acceso a tu cuenta?
                                        </NavLink>
                                    </>
                                ) : (
                                    <>
                                        <Stack justifyContent="center" alignItems="center">
                                            <p style={{ color: "red" }}>{messageError}</p>

                                            <Typography style={{ color: "#C7C7C7", fontSize: isMobile ? "13px" : "16px", fontFamily: "FuturaP-Medium" }}>CORREO ELECTRÓNICO *</Typography>

                                            <input type="text" className="inputLogin" style={{ textTransform: "none", width: "140%" }} value={inputValue} onChange={handleInputChange} />
                                        </Stack>

                                        <Button
                                            variant="contained"
                                            className="btnResendPass"
                                            onClick={handleSendRememberPass}
                                            style={{
                                                width: "100%",
                                                fontSize: "19px",
                                                height: "57.689px",
                                            }}
                                        >
                                            ENVIAR
                                        </Button>
                                        <NavLink onClick={cerrarModal} style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "4%", textDecoration: "none", fontSize: "10px" }}>
                                            ¿Ya tienes acceso a tu cuenta?
                                        </NavLink>
                                    </>
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack spacing={isMobile ? 2 : 0}>
                        <Typography style={{ color: "#C7C7C7", fontSize: isTablet || isSurface ? "26px" : isMobile ? "19px" : "36px", fontFamily: "FuturaP-ExtraBold", textAlign: isMobile ? "center" : "left" }}>INICIO DE SESIÓN</Typography>
                        {mensajeErrorLogin && <Typography style={{ color: "red", fontSize: "14px" }}>{mensajeErrorLogin}</Typography>}
                        <br />
                        <Stack spacing={2}>
                            <Stack style={{ paddingLeft: isMobile ? "0%" : "2%" }}>
                                <Typography style={{ color: "#C7C7C7", fontSize: isMobile ? "13px" : "16px", fontFamily: "FuturaP-Medium" }}>CORREO ELECTRÓNICO *</Typography>
                                <input type="text" className="inputLogin" value={email} onChange={(e) => setEmail(e.target.value)} style={{ textTransform: "none", width: isMobile ? "90%" : "100%" }} />
                                {emailError && <Typography style={{ color: "red", fontSize: "12px" }}>{emailError}</Typography>}
                            </Stack>
                            <Stack style={{ paddingLeft: isMobile ? "0%" : "2%", position: "relative" }}>
                                <Typography style={{ color: "#C7C7C7", fontSize: isMobile ? "13px" : "16px", fontFamily: "FuturaP-Medium" }}>CONTRASEÑA *</Typography>
                                <input type={showPassword ? "text" : "password"} className="inputLogin" value={password} onChange={(e) => setPassword(e.target.value)} style={{ textTransform: "none", width: isMobile ? "90%" : "100%" }} />
                                {passwordError && <Typography style={{ color: "red", fontSize: "12px" }}>{passwordError}</Typography>}
                                <Button style={{ position: "absolute", right: "10px", top: "65%", transform: "translateY(-50%)" }} onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <VisibilityOff style={{color:"#67BF47"}}/> : <Visibility style={{color:"#67BF47"}}/>}
                                </Button>
                            </Stack>
                            <Stack alignItems={isMobile ? "center" : "flex-end"}>
                                <Typography style={{ color: "#29C8B9", fontSize: "13px", fontFamily: "FuturaP-Light", textDecoration: "underline", fontWeight: "100" }} onClick={handleRememberPassClick}>
                                    RECUPERAR CONTRASEÑA
                                </Typography>
                            </Stack>
                            <Stack alignItems="center">
                                <Button variant="contained" className="btnSendLogin" onClick={handleLoginClick} style={{ backgroundColor: isMobile ? "#29C8B9" : "#67BF47", color: "white", fontFamily: "FuturaP-Medium", width: isTablet || isSurface ? "50%" : "40%", fontSize: isTablet || isSurface ? "15px" : "19px", height: isTablet || isSurface ? "47.689px" : "57.689px" }}>
                                    INICIAR SESIÓN
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                )}
            </Dialog>
        </React.Fragment>
    );
}