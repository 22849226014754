import CryptoJS from "crypto-js";
import { secretKey } from "../../../api";


export const saveStateToLocalStorage = (checkoutState, homeState) => {

    const { timeToken, step1, tokenSessions, step2, step3, step4, addSeating, activeTanda, payment } = checkoutState;


   // console.log("checkoutState", step4);

    const { allMoviesBillboard, activeMovieInfo, dataArrayMovies, arrayInfoMovie } = homeState;
    const { id_movie_shifts_bp, id_shifts_bp, room_id_shifts_bp, date_time_shifts_bp, start_time_shifts_bp } = activeTanda || {};

    const stateToSave = {
        step1,
        step2,
        step3,
        step4: { articules: step4.articules },
        addSeating,
        activeTanda,
        allMoviesBillboard,
        arrayInfoMovie,
        activeMovieInfo,
        dataArrayMovies,
        tokenSessions,
        id_movie_shifts_bp,
        id_shifts_bp,
        room_id_shifts_bp,
        date_time_shifts_bp,
        start_time_shifts_bp,
        timeToken,
        payment,
    };

    const encryptedState = CryptoJS.AES.encrypt(JSON.stringify(stateToSave), secretKey).toString();
    localStorage.setItem("ss", encryptedState);
    localStorage.setItem("rft", true);
};
