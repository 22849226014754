import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { keyframes } from "@emotion/react";

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;
export const LoginPage = () => {
    return (
        <Container
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                textAlign: "center",
            }}
        >
            <Box
                sx={{
                    animation: `${bounce} 2s infinite`,
                    fontSize: "4rem",
                    fontWeight: "bold",
                }}
            >
                404
            </Box>
            <Typography variant="h4" component="h1" gutterBottom>
                Esta página aún no está en uso
            </Typography>
        </Container>
    );
};
