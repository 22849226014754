import { commonRequestData, fetchData } from "../../api";

export const sendApiMail = async ({ data, type, subject = "" }) => {
    // cuerpo de la solicitud que se enviará al servidor.
    const requestData = {
        ...commonRequestData,
        data,
        type,
        subject,
    };
    // Realiza una solicitud utilizando fetchData y retorna la respuesta
    return await fetchData("cineverse/sendMailer", requestData);
};
