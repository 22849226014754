import "../../styles/index.css";
import "../../styles/movie.css";
import { Box, Typography, ToggleButton, ToggleButtonGroup, useMediaQuery, Stack, useTheme } from "@mui/material";
import { Availabledays, fetchSlides, getTandasBd, setActiveMovieInfo, updatearrayInfoMovie } from "../../../store/home";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import React, { useEffect, useState } from "react";
import { clearSeat, setActiveChekout } from "../../../store/checkout/checkoutSlice";
import { useNavigate } from "react-router-dom";
import { getAritucule, insertSessionId } from "../../../store/checkout";
import { setUserLogout } from "../../../store/auth/authSlice";

// Constantes de estilos
const ColorRgba = "rgba(255, 255, 255, 0.70)";
const ColorWhite = "#fff";
const FonsFamily = "FuturaP-ExtraBold";
const TextTransform = "uppercase";

// Función para generar un ID de sesión único
const generateSessionId = async () => {
    // Generar ID de sesión aleatorio
    const randomNumbers = Math.random().toString().slice(2, 7);
    const randomLetters = Math.random().toString(36).substr(2, 7);
    const date = new Date();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `eyJhbGciOiJIUzI1Ni${randomNumbers}IsInR5cCI6IkpXVCIsI${month}kVNUCI6Il${day}JPQ0NBIiwiQ${year}0lURSI6IlNUVURJT0NJTkVNQVMifQ.sess${randomLetters}${seconds}`;
};

// Función para obtener la hora actual
const getCurrentTime = async () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
};

/* generador de token para la url de la pelcula seleccionada */


function generateToken() {
    const date = new Date();
    const components = ["session", Math.random().toString(36).slice(2, 11), "movie", date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), Math.floor(Math.random() * 1000)];
    return components.sort(() => Math.random() - 0.5).join("");
}

function generateIdSession() {
    return `session`;
}
/* generador de token para la url de la pelcula seleccionada */





// Componente Movie
const Movie = () => {
    const dispatch = useDispatch();

    // Estado local del componente
    const [days, setDays] = useState([]);
    const [selectedDay, setSelectedDay] = useState("");

    // Obtiene datos del estado global
    const { isLoadingPgeHome, errorMessage, dataArrayMovies, arrayInfoMovie, arrayTandaMovie, isLoadingInfo } = useSelector((state) => state.home);
    const { type_user } = useSelector((state) => state.auth);

    const storedMovieId = localStorage.getItem("idLocalMovie");
    const [selectedMovie, setSelectedMovie] = useState(storedMovieId || dataArrayMovies[0]?.id_master_movie_tb);
    const [alignment, setAlignment] = useState("web");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)");
    const isSurface = useMediaQuery("(max-width:1370px)"); // Verifica si la pantalla es móvil

    // Maneja el cambio de día seleccionado
    const handleDayChange = (event) => {
        const selectedDate = event.target.value;
        setSelectedDay(selectedDate);
        dispatch(getTandasBd(selectedDate, 1));
    };

    // Maneja el cambio de película seleccionada
    const handleMovieChange = async (event) => {
        const selectedMovie = event.target.value;
        const selectedDay = document.querySelector(".selectDayFilter").value;
        setSelectedMovie(selectedMovie);
        localStorage.removeItem("idLocalMovie");
        localStorage.setItem("idLocalMovie", selectedMovie);
        dispatch(setActiveMovieInfo(selectedMovie));
        dispatch(updatearrayInfoMovie());
        const daysMovie = await dispatch(Availabledays());
        setDays(daysMovie);
        if (daysMovie.length > 0) {
            setSelectedDay(daysMovie[0].value);
        }
        dispatch(getTandasBd(daysMovie, 2));
    };

    // Maneja el cambio de alineación de botones
    const handleChange = async (event, newAlignment) => {
        setAlignment(newAlignment);
        const newSessionId = await generateSessionId();
        const currentTime = await getCurrentTime();
        await insertSessionId(newSessionId, currentTime);
    };

    // Efecto que se ejecuta al montar el componente
    useEffect(() => {
        if (type_user === "guest") {
            dispatch(setUserLogout());
        }

        const fetchData = async () => {
            await dispatch(fetchSlides());
        };

        const executeFetchAndUpdate = async () => {
            if (dataArrayMovies.length === 0) {
                await fetchData();
            }
            const id = localStorage.getItem("idLocalMovie");
            dispatch(setActiveMovieInfo(id));
            dispatch(updatearrayInfoMovie());
            const daysMovie = await dispatch(Availabledays());
            setDays(daysMovie);
            if (daysMovie.length > 0) {
                setSelectedDay(daysMovie[0].value);
            }
            dispatch(getTandasBd(daysMovie, 3));
        };

        executeFetchAndUpdate();
    }, [dispatch, dataArrayMovies]);

    // Efecto que se ejecuta cuando cambia el ID de la película almacenada localmente
    useEffect(() => {
        const fetchData = async () => {
            const id = localStorage.getItem("idLocalMovie");
            if (id && id !== selectedMovie) {
                setSelectedMovie(id);
                dispatch(setActiveMovieInfo(id));
                dispatch(updatearrayInfoMovie());
                const daysMovie = await dispatch(Availabledays());
                setDays(daysMovie);
                if (daysMovie.length > 0) {
                    setSelectedDay(daysMovie[0].value);
                }
                dispatch(getTandasBd(daysMovie, 3));
            }
        };

        fetchData();
    }, [storedMovieId, selectedDay, dispatch, selectedMovie, days]);

    // Obtiene la información de la película seleccionada
    const movieInfo = arrayInfoMovie.length > 0 ? arrayInfoMovie[0] : null;

    // Maneja el clic en un horario de la película
    const navigate = useNavigate();
    const handleItemClicked = (data) => {
        // localStorage.removeItem("idLocalMovie");
        // localStorage.setItem("idLocalMovie", data.idMaster);

        const token = generateToken();
        dispatch(clearSeat());
        dispatch(setActiveMovieInfo(data.idMaster));
        dispatch(updatearrayInfoMovie());
        dispatch(setActiveChekout(data));
        navigate(`/checkout?movie=${encodeURIComponent(token)}`);
    };


    useEffect(() => {
        populateCategorySelect();
    }, []);

    const populateCategorySelect = async () => {
        localStorage.removeItem("pytmi");
        await dispatch(getAritucule());
        return;
    };

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    height: isMobile ? "184px" : "360px",
                    marginTop: "3%",
                    backgroundImage: movieInfo ? `url(${movieInfo.imgheader})` : "none",
                    backgroundSize: "cover",
                }}
            ></Box>
            <Stack
                spacing={0}
                sx={{
                    backgroundImage: "url('/content/img/SC/fondo-CP.svg')",
                    backgroundSize: "cover",
                    paddingTop: "50px",
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    paddingBottom: "5%",
                }}
            >
                <Stack
                    direction={isMobile ? "row" : "row"}
                    justifyContent={isMobile || isTablet ? "center" : "flex-end"}
                    alignItems={isMobile ? "center" : "flex-start"}
                    spacing={0}
                    style={{
                        height: "100%",
                        marginTop: isMobile ? "-11%" : isTablet ? "-7%" : isSurface ? "-4%" : "-3%",
                        paddingRight: "25px",
                    }}
                >
                    <select value={selectedDay} onChange={handleDayChange} className="selectDayFilter selectFilterMovie" style={{ textAlign: "center" }}>
                        {days.map((d, index) => (
                            <option key={index} value={d.value} className="optionSelect" style={{ textAlign: "center" }}>
                                {d.label}
                            </option>
                        ))}
                    </select>

                    <select value={selectedMovie} onChange={handleMovieChange} className="selectFilterMovie" style={{ textAlign: "left" }}>
                        {dataArrayMovies.map((movie, index) => (
                            <option key={index} value={movie.id_master_movie_tb} className="optionSelect" style={{ textAlign: "left" }}>
                                {movie.name_master_movie_tb}
                            </option>
                        ))}
                    </select>
                </Stack>

                <Stack className="carteleraContenedor" direction={isMobile || isTablet ? "column" : "row"} justifyContent="flex-start" alignItems="flex-start" spacing={5} style={{ paddingLeft: "5%" }}>
                    {!isLoadingInfo || !movieInfo ? (
                        <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
                            <LinearProgress color="success" />
                            <LinearProgress color="success" />
                            <LinearProgress color="success" />
                        </Stack>
                    ) : (
                        <>
                            <Stack style={{ width: isMobile ? "85%" : "722px", minWidth: isMobile ? "85%" : "770px", maxWidth: isMobile ? "85%" : "770px" }} spacing={4}>
                                <Stack>
                                    <Typography
                                        sx={{
                                            color: ColorWhite,
                                            fontSize: isMobile ? "28px" : "75px",
                                            fontFamily: FonsFamily,
                                            textTransform: TextTransform,
                                        }}
                                    >
                                        {movieInfo.title}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: isMobile ? ColorRgba : ColorWhite,
                                            fontSize: isMobile ? "14px" : "26px",
                                            fontFamily: "FuturaP-Light",
                                            fontWeight: "100",
                                            maxWidth: isTablet ? "650px" : "770px",
                                        }}
                                    >
                                        {movieInfo.description}
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                        <Typography
                                            sx={{
                                                color: isMobile ? ColorRgba : ColorWhite,
                                                fontSize: isMobile ? "12px" : "26px",
                                                fontFamily: FonsFamily,
                                                textTransform: TextTransform,
                                            }}
                                        >
                                            DURACIÓN:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: isMobile ? ColorRgba : ColorWhite,
                                                fontSize: isMobile ? "12px" : "26px",
                                                fontFamily: "FuturaP-Light",
                                                textTransform: TextTransform,
                                                fontWeight: "100",
                                            }}
                                        >
                                            {movieInfo.duration}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                        <Typography
                                            sx={{
                                                color: isMobile ? ColorRgba : ColorWhite,
                                                fontSize: isMobile ? "12px" : "26px",
                                                fontFamily: FonsFamily,
                                                textTransform: TextTransform,
                                            }}
                                        >
                                            CLASIFICACIÓN:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: isMobile ? ColorRgba : ColorWhite,
                                                fontSize: isMobile ? "12px" : "26px",
                                                fontFamily: "FuturaP-Light",
                                                textTransform: TextTransform,
                                                fontWeight: "100",
                                            }}
                                        >
                                            {movieInfo.censura}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                        <Typography
                                            sx={{
                                                color: isMobile ? ColorRgba : ColorWhite,
                                                fontSize: isMobile ? "12px" : "26px",
                                                fontFamily: FonsFamily,
                                                textTransform: TextTransform,
                                            }}
                                        >
                                            GÉNERO:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: isMobile ? ColorRgba : ColorWhite,
                                                fontSize: isMobile ? "12px" : "26px",
                                                fontFamily: "FuturaP-Light",
                                                textTransform: TextTransform,
                                                fontWeight: "100",
                                            }}
                                        >
                                            {movieInfo.genre}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <iframe width={isMobile ? "370" : "800"} height={isMobile ? "225" : "453"} src={movieInfo.trailer} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ display: isMobile || isTablet ? "none" : "block" }}></iframe>
                            </Stack>

                            <Stack spacing={3} style={{ marginTop: "5%", marginLeft: isMobile ? "13%" : "13%", width: "85%" }}>
                                {arrayTandaMovie && arrayTandaMovie.length > 0 ? (
                                    arrayTandaMovie.map((tanda, index) => (
                                        <Stack className="contenedorTandas" spacing={2} key={index} sx={{ flexGrow: 1, flexShrink: 0 }}>
                                            <Typography
                                                sx={{
                                                    color: ColorWhite,
                                                    fontSize: "20px",
                                                    fontFamily: FonsFamily,
                                                    textTransform: TextTransform,
                                                    borderBottom: "1px solid rgba(255, 255, 255, 0.20)",
                                                    padding: "10px",
                                                    paddingLeft: "20px",
                                                }}
                                            >
                                                {tanda.name}
                                            </Typography>
                                            <Stack direction="row" style={{ paddingLeft: "25px" }}>
                                                <ToggleButtonGroup color="primary" value={alignment} exclusive onChange={handleChange} aria-label="Platform" style={{ flexWrap: "wrap" }}>
                                                    {tanda.horarios.map((horario, i) => (
                                                        <ToggleButton
                                                            key={i}
                                                            value={`${horario.id}`}
                                                            onClick={() => handleItemClicked(horario)}
                                                            className="chipHorario"
                                                            sx={{
                                                                "&.Mui-selected": {
                                                                    backgroundColor: "#66B02E !important",
                                                                    color: "#fff !important",
                                                                },
                                                            }}
                                                        >
                                                            {horario.hora}
                                                        </ToggleButton>
                                                    ))}
                                                </ToggleButtonGroup>
                                            </Stack>
                                        </Stack>
                                    ))
                                ) : (
                                    <>
                                        {errorMessage ? (
                                            <Stack justifyContent="center" alignItems="center" spacing={2} style={{ borderRadius: "20px", border: " 1px solid rgba(255, 255, 255, 0.20)", backgroundColor: "rgba(0, 0, 0, 0.24)", width: isMobile ? "90%" : "75%", paddingTop: "5%", paddingBottom: "5%" }}>
                                                <img src="/content/img/SC/nohaypelicula.svg" width={"30%"} />
                                                <Typography style={{ fontFamily: "FuturaP-ExtraBold", color: "#fff", textTransform: "uppercase", fontSize: isMobile ? "30px" : "48px", marginBottom: "0%" }}>oops!</Typography>
                                                <Typography style={{ fontFamily: "FuturaP-Medium", color: "#fff", textTransform: "uppercase", fontSize: isMobile ? "15px" : "20px", textAlign: "center", marginTop: "-2%" }}>
                                                Tandas agotadas
                                                    <br />
                                                    por hoy
                                                </Typography>
                                                <Typography style={{ fontFamily: "FuturaP-Medium", color: "#fff", textTransform: "uppercase", fontSize: isMobile ? "15px" : "20px" }}>¡Revisá las de mañana!</Typography>
                                            </Stack>
                                        ) : (
                                            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
                                                <LinearProgress color="success" />
                                                <LinearProgress color="success" />
                                                <LinearProgress color="success" />
                                            </Stack>
                                        )}
                                    </>
                                )}
                            </Stack>
                            <iframe width={isMobile ? "305" : "600"} height={isMobile ? "215" : "353"} src={movieInfo.trailer} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ display: isMobile || isTablet ? "block" : "none" }}></iframe>
                        </>
                    )}
                </Stack>
            </Stack>
        </>
    );
};

export default Movie;
