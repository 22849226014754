import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress, Checkbox, FormControlLabel, Stack, Typography, useMediaQuery, useTheme, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import { cleararticule, clearArticuleList, insertSessionIdSlice, restoreStateCheckoutNewBd, setdeleteArticule, setPaymentInProgress, useNmaeUser, useTarjet } from "../../../store/checkout/checkoutSlice";
import { saveStateToLocalStorage } from "../../components/componentsCheckout/saveStateToLocalStorage";
import { geTemporaryPayment, geTemporaryPaymentInsert, insertSessionId, trasnsactionConfiteriaFirme, trasnsactionConfiteriaTemporal } from "../../../store/checkout";
import { restoreStateFromLocalStorage } from "../../components/componentsCheckout/restoreStateFromLocalStorage";
import MessageDialog from "../../components/componentsGlobal/MessageDialog";
import MessageDialogConfiteria from "../../components/componentsGlobal/MessageDialogConfiteria";
import LoadingDialog from "../../components/componentsGlobal/LoadingDialog";
import FirmeProcessDialog from "../../components/componentsGlobal/FirmeProcessDialog";
import { restaurarDatosProcesodePago } from "../../../store/auth/authSlice";
import { restoreStateHomeNewBd } from "../../../store/home";
import OptionDialog from "../../components/componentsGlobal/OptionDialog";
import useInsertTemporarySession from "../../../hooks/updatePymentClient";
import { startLogout } from "../../../store/auth/thunks";
import { insertarErrorDeFactura, insertartTempInvoiceConfiteria, insertartTempInvoiceConfiteriaAgain } from "../../../store/pymentStoree/pymentStoree";

const generateRandomNumbers = () => Math.floor(100000 + Math.random() * 900000);
const generateRandomLetters = () => {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < 3; i++) {
        result += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    return result;
};

function getCurrentDateTimeInCostaRica() {
    // Obtener la fecha y hora actual en UTC
    const now = new Date();

    // Crear una nueva fecha ajustando la zona horaria a Costa Rica (UTC -6)
    const offset = -6; // Costa Rica está en UTC -6
    const costaRicaTime = new Date(now.getTime() + offset * 60 * 60 * 1000);

    // Formatear la fecha y la hora por separado
    const fecha = costaRicaTime.toISOString().split("T")[0]; // YYYY-MM-DD
    const hora = costaRicaTime.toISOString().split("T")[1].split(".")[0]; // HH:MM:SS

    return { fecha, hora };
}
const ViewConfirmacionPago = () => {
    /* Hook para utilizar el estado global de Redux, permitiendo acceder a la información de la confitería seleccionada */
    const { step4, tokenSessions } = useSelector((state) => state.checkout);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loadingFirme, setLoadingFirme] = useState(false);
    const navigate = useNavigate();
    const [movieParam, setMovieParam] = useState(null);
    const [isEffectComplete, setIsEffectComplete] = useState(false);
    const [loadingPrincipal, setloadingPrincipal] = useState(true);
    const checkoutState = useSelector((state) => state.checkout);
    const homeState = useSelector((state) => state.home);
    const auth = useSelector((state) => state.auth);
    const authUser = useSelector((state) => state.auth);
    const [optionDialog, setOptionDialog] = useState(false); // Estado para controlar la apertura del diálogo de login
    const [iformacionBan, setBanck] = useState("");
    const [validarInfo, setvalidarInfo] = useState(0);

    const generateSessionId = async () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const seconds = currentDate.getSeconds();
        const randomNumbers1 = generateRandomNumbers();
        const randomNumbers2 = generateRandomNumbers();
        const randomLetters = generateRandomLetters();

        const newIdSession = `confiteria.${randomNumbers1}.${day}${randomNumbers2}${month}.${year}.${hours}.${minutes}.${seconds}.${randomLetters}`;
        // setIdSession(newIdSession);
        await dispatch(insertSessionIdSlice(newIdSession));
        await dispatch(insertSessionId(newIdSession));

        return newIdSession;
    };

    // Función asíncrona que contiene toda la lógica de useEffect
    const executeEffect = async (navigate, dispatch, setMovieParam) => {
        setloadingPrincipal(true);
        // Obtenemos los parámetros de la URL actual utilizando URLSearchParams
        const urlParams = new URLSearchParams(window.location.search);

        // Extraemos el valor asociado al parámetro 'movie' de la URL
        const movieValue = urlParams.get("confiteria");
        setMovieParam(movieValue);

        // Verificamos si el valor del parámetro 'movie' es nulo, vacío o indefinido
        if (movieValue === null || movieValue === "" || movieValue === undefined) {
            // Si no se ha seleccionado una película, mostramos una alerta al usuario
            alert("Lo sentimos, no se ha podido verificar la confitería. Por favor, seleccione los artículos antes de continuar.");

            // Redirigimos al usuario a la página de inicio
            dispatch(clearArticuleList());
            navigate("/confiteria");
            // Terminamos la ejecución para evitar lógica adicional
            return;
        }

        // Función para validar si la película ya existe y cargar los datos desde la API
        const fetchData = async () => {
            // Obtener datos temporales de pago usando el valor de la película
            const { data } = await dispatch(geTemporaryPayment(movieValue));

            // Verificar si el código de estado es 210 (requiere la creación de una sesión temporal)
            if (data.statusCode === 210) {
                // Eliminar el paso guardado previamente en el localStorage
                localStorage.removeItem("step");

                // Validar si el usuario ha seleccionado artículos de la confitería
                if (!step4.articules || step4.articules.length === 0) {
                    alert("Para continuar debe seleccionar al menos un artículo de la confitería.");
                    navigate("/confiteria"); // Redirigir a la página de confitería
                    return;
                }

                // Generar un token de sesión único para el pago
                const tokenU = await generateSessionId();

                // Obtener la fecha y hora actuales en la zona horaria de Costa Rica
                const { fecha, hora } = getCurrentDateTimeInCostaRica();

                // Establecer un valor por defecto para una variable adicional de la sesión
                const setepPyme = 1;

                // Insertar la sesión temporal de pago con la información correspondiente
                const insertartSessionTemporal = await dispatch(
                    geTemporaryPaymentInsert(
                        {
                            peliculaActiva: homeState.activeMovieInfo, // Información de la película activa
                            informacionPelicula: homeState.allMoviesBillboard, // Información general de las películas en cartelera
                            extraInformacion: "", // Información adicional vacía por defecto
                        },
                        {
                            peliculaActiva: checkoutState.activeTanda, // Tanda activa seleccionada
                            precioDelista: checkoutState.step2, // Precio seleccionado
                            precioDelistaArray: [], // Lista de precios vacía por defecto
                            asientosSeleccionados: checkoutState.step3, // Asientos seleccionados por el usuario
                            articuloSeleccionado: step4.articules || [], // Artículos seleccionados en la confitería
                            tokenSessions: tokenU, // Token de sesión generado
                            payment: checkoutState.payment, // Información de pago
                        },
                        { datosUsuario: authUser }, // Información del usuario autenticado
                        {
                            iformacionBanco: "Sin información", // Información del banco (placeholder)
                        },
                        setepPyme, // Valor adicional de la sesión
                        movieValue, // Valor de la película seleccionada
                        fecha, // Fecha actual
                        hora, // Hora actual
                    ),
                );

                // Desactivar el estado de carga
                setloadingPrincipal(false);

                // Si no se puede verificar la confitería, alertar y redirigir al usuario
                if (insertartSessionTemporal.data.statusCode !== 200) {
                    alert("Lo sentimos, no se ha podido verificar la confitería. Por favor, seleccione los artículos antes de continuar.");
                    navigate("/confiteria");
                    return;
                }

                // Validar si el nombre de usuario es inválido, si es así, cerrar sesión y mostrar un diálogo
                if (authUser.name_user === null || authUser.name_user === undefined || authUser.name_user === "") {
                    dispatch(startLogout());
                    setOptionDialog(true);
                    return;
                }
            } else {
                // Si los datos ya existen, restaurar los datos de usuario y el estado del pago desde la base de datos
                const dataUser = data.data[0];
                await dispatch(restaurarDatosProcesodePago(dataUser.temporary_payment_data_authUser.datosUsuario));

                // Obtener la respuesta del banco
                const { responseCode } = dataUser.temporary_payment_data_banck;
                setBanck(dataUser.temporary_payment_data_banck);

                // Restaurar los estados de la aplicación desde la base de datos
                await dispatch(restoreStateHomeNewBd(dataUser.temporary_payment_data_homeState));
                await dispatch(restoreStateCheckoutNewBd(dataUser.temporary_payment_data_checkoutState));
                setvalidarInfo(1);

                // Si la respuesta del banco es exitosa, redirigir a la página de documentos
                if (dataUser.temporary_payment_codeBanck === 100) {
                    navigate("/documentos-pdf?rev=" + dataUser.temporary_payment_data_checkoutState.tokenSessions);
                }

                // Validar la respuesta del banco y manejar la navegación o los mensajes de error
                if (responseCode > 0 || responseCode) {
                    setOptionDialog(false);

                    // Manejar respuestas de error del banco
                    if (responseCode === 100) {
                        navigate("/documentos-pdf?rev=" + dataUser.temporary_payment_data_checkoutState.tokenSessions);
                        seturlPayment(dataUser.temporary_payment_data_checkoutState.tokenSessions);
                        setresponseCodeV(100);
                        setIsResponseCodeVLoaded(true);
                        setloadingPrincipal(false);
                        return;
                    } else {
                        setPaymentMessage(`Error Respuesta del banco: ${dataUser.temporary_payment_data_banck.responseMessage} : ${dataUser.temporary_payment_data_banck.responsetext}`);
                        setIsResponseCodeVLoaded(true);
                        setloadingPrincipal(false);
                    }
                }

                // Validar si el nombre de usuario y el correo están vacíos, si es así, cerrar sesión
                if (!dataUser.temporary_payment_data_authUser.datosUsuario.name_user && !dataUser.temporary_payment_data_authUser.datosUsuario.email_user) {
                    dispatch(startLogout());
                    setloadingPrincipal(false);
                }

                // Validar si el nombre de usuario es inválido, si es así, cerrar sesión
                const validateUser = dataUser.temporary_payment_data_authUser.datosUsuario.name_user;
                if (validateUser === null || validateUser === undefined || validateUser === "") {
                    dispatch(startLogout());
                }

                // Validar si se han seleccionado artículos, si no, redirigir a la confitería
                if (dataUser.temporary_payment_data_checkoutState.articuloSeleccionado.length === 0) {
                    alert("Lo sentimos, pero para continuar debe agregar al menos un artículo a la confitería.");
                    navigate("/confiteria");
                }

                // Desactivar el estado de carga
                setloadingPrincipal(false);
            }
        };

        // Ejecutamos fetchData y esperamos a que termine
        await fetchData();

        // Guardamos el valor del parámetro 'movie' en el estado del componente
        setMovieParam(movieValue);

        // Indicamos que el efecto ha terminado
        setIsEffectComplete(true);
    };

    // Dentro de useEffect llamamos a la función asíncrona
    useEffect(() => {
        // Llamamos a la función asíncrona y esperamos a que se complete
        executeEffect(navigate, dispatch, setMovieParam);
    }, [navigate]); // Dependencia del useEffect para asegurarnos de que 'navigate' esté actualizado

    const insertTemporarySession = useInsertTemporarySession(movieParam, iformacionBan);
    useEffect(() => {
        if (validarInfo === 1) {
            insertTemporarySession();
        }

        // Verificar si ambos campos están completos
        const isUserComplete = authUser.name_user && authUser.email_user;

        // Configurar el diálogo y logout según sea necesario
        setOptionDialog(!isUserComplete);

        if (!isUserComplete) {
            dispatch(startLogout());
        }
    }, [authUser.name_user, authUser.email_user, validarInfo]);

    useEffect(() => {
        if (validarInfo === 1) {
            // Insertar sesión temporal
            insertTemporarySession();
        }
    }, [step4.articules, movieParam, validarInfo]);

    // Solo ejecutamos el console.log cuando el efecto asíncrono ha terminado
    useEffect(() => {
        if (isEffectComplete) {
            //console.log("Efecto asíncrono completado");
        }
    }, [isEffectComplete]);

    /* nueva integracion */

    const totalSubTotal = step4.articules.reduce((acc, producto) => acc + producto.subTotal, 0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil
    const isSurface = useMediaQuery("(max-width:1370px)"); // Verifica si la pantalla es móvil

    const [cardData, setCardData] = useState({
        cvc: "",
        expiry: "",
        name: "",
        number: "",
    });

    const [errors, setErrors] = useState({
        name: "",
        number: "",
        expiry: "",
        cvc: "",
    });

    const [isFormValid, setIsFormValid] = useState(false);
    const [focused, setFocused] = useState("");
    const [paymentMessage, setPaymentMessage] = useState("");
    const [responseCodeV, setresponseCodeV] = useState();
    const [isResponseCodeVLoaded, setIsResponseCodeVLoaded] = useState();
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [termsError, setTermsError] = useState("Para poder continuar debe aceptar las políticas.");
    const secretKeyBac = "p5fm35mA6wUcweRz8s8VEx793mRYNWz9"; // Asegúrate de que esta clave coincida con la que usas para encriptar

    useEffect(() => {
        const isValid = Object.values(errors).every((error) => error === "") && Object.values(cardData).every((value) => value !== "") && termsAccepted;
        setIsFormValid(isValid);
    }, [errors, cardData, termsAccepted]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCardData({ ...cardData, [name]: value });
        validateField(name, value);
    };

    const handleNameChange = (e) => {
        const { value } = e.target;
        dispatch(useNmaeUser(value));
        if (/^[a-zA-Z\s]*$/.test(value)) {
            setCardData({ ...cardData, name: value });
            setErrors({ ...errors, name: "" });
        } else {
            setErrors({ ...errors, name: "Nombre inválido" });
        }
    };

    const handleNumberChange = (e) => {
        const { value } = e.target;
        dispatch(useTarjet(value));
        const formattedValue = value
            .replace(/\s+/g, "")
            .replace(/(\d{4})/g, "$1 ")
            .trim();
        if (/^[\d\s]*$/.test(formattedValue)) {
            setCardData({ ...cardData, number: formattedValue });

            if (formattedValue.replace(/\s/g, "").length < 15 || formattedValue.replace(/\s/g, "").length > 16) {
                setErrors({ ...errors, number: "El número de tarjeta excede el límite" });
            } else {
                setErrors({ ...errors, number: "" });
            }
        }
    };

    const handleCVCChange = (e) => {
        const { value } = e.target;
        if (/^\d{0,4}$/.test(value)) {
            setCardData({ ...cardData, cvc: value });
            setErrors({ ...errors, cvc: "" });
        } else {
            setErrors({ ...errors, cvc: "CVC inválido" });
        }
    };

    const handleExpiryChange = (e) => {
        const { value } = e.target;
        const formattedValue = value.replace(/[^0-9]/g, "").replace(/(\d{2})(\d{2})/, "$1/$2");
        if (/^\d{0,2}\/?\d{0,2}$/.test(formattedValue)) {
            setCardData({ ...cardData, expiry: formattedValue });
            setErrors({ ...errors, expiry: "" });
        } else {
            setErrors({ ...errors, expiry: "Fecha de expiración inválida" });
        }
    };

    const handleTermsChange = (e) => {
        setTermsAccepted(e.target.checked);
        setTermsError(e.target.checked ? "" : "Para poder continuar debe aceptar las políticas.");
    };

    const validateField = (name, value) => {
        switch (name) {
            case "name":
                if (value.trim() === "") setErrors({ ...errors, name: "El nombre es requerido" });
                break;
            case "number":
                if (value.trim() === "") setErrors({ ...errors, number: "El número de tarjeta es requerido" });
                break;
            case "expiry":
                if (value.trim() === "") setErrors({ ...errors, expiry: "La fecha de expiración es requerida" });
                break;
            case "cvc":
                if (value.trim() === "") setErrors({ ...errors, cvc: "El CVC es requerido" });
                break;
            default:
                break;
        }
    };

    // Estilos comunes para Typography
    const typographyStyles = {
        bold: { fontFamily: "FuturaP-Bold", textTransform: "uppercase" },
        light: { fontFamily: "FuturaP-Light", fontWeight: "100", textTransform: "uppercase" },
        extraBold: { fontFamily: "FuturaP-ExtraBold", textTransform: "uppercase" },
        confirmation: { backgroundColor: "#66B02E", paddingTop: isMobile ? "3px" : "3px", paddingLeft: isMobile ? "8px" : "15px", paddingRight: isMobile ? "8px" : "14px", paddingBottom: isMobile ? "1px" : "1px", borderRadius: "100%", display: isMobile ? "block" : "none" },
        orderDetails: { border: isMobile || isTablet ? "none" : "1px solid rgba(255, 255, 255, 0.20)", padding: isMobile ? "1%" : "2%", backgroundColor: isMobile || isTablet ? "transparent" : "rgba(0, 0, 0, 0.24)", borderRadius: "20px" },
    };

    /* proceso de pago del bac credomatic */
    const [orderid, setOrderid] = useState("");
    const [amountBac, setamountBac] = useState(totalSubTotal);

    useEffect(() => {
        setamountBac(Number(totalSubTotal));
    }, [totalSubTotal]);

    const [ccnumber, setCcnumber] = useState("");
    const [ccexp, setCcexp] = useState("");
    const [cvv, setCvv] = useState("");
    const [time, setTime] = useState("");
    const [hash, setHash] = useState("");
    const [urlPayment, seturlPayment] = useState("url");

    useEffect(() => {
        const generateUniqueOrderId = () => {
            return `Order-${Math.random().toString(36).substr(2, 9)}`;
        };

        const currentTime = Math.floor(Date.now() / 1000);
        const newOrderId = generateUniqueOrderId();

        setOrderid(newOrderId);
        setTime(currentTime);
        setHash(createHash(newOrderId, amountBac, currentTime, secretKeyBac));
    }, [amountBac]);

    const createHash = (orderid, amountBac, time, secret) => {
        return CryptoJS.MD5(`${orderid}|${amountBac}|${time}|${secret}`).toString();
    };

    const handleButtonClick = () => {
        if (!termsAccepted) {
            setTermsError("Para poder continuar debe aceptar las políticas.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validar si el usuario está autenticado
        if (!authUser.name_user || !authUser.email_user) {
            setOptionDialog(true);
            return;
        }

        // Validar si hay artículos en la confitería
        if (step4.articules.length === 0) {
            alert("Lo sentimos, pero para continuar debe agregar al menos un artículo a la confitería.");
            navigate("/confiteria");
            return;
        }

        // Validar si los términos fueron aceptados
        if (!termsAccepted) {
            setTermsError("Para poder continuar debe aceptar las políticas.");
            return;
        }

        setLoading(true);

        // 1. Generar un nuevo orderid para cada intento de transacción

        const generateUniqueOrderIds = () => {
            // Generar una cadena aleatoria de solo letras, de 6 caracteres
            const randomPart = Math.random()
                .toString(36)
                .replace(/[^a-z]+/g, "")
                .substr(0, 6);
            const timeNow = Math.floor(Date.now() / 1000);

            // Obtener la hora, minutos y segundos actuales
            const date = new Date();
            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");
            const seconds = date.getSeconds().toString().padStart(2, "0");

            // Concatenar todos los elementos en el formato deseado
            return "confiteria-" + randomPart + timeNow + hours + minutes + seconds;
        };

        let orderid = generateUniqueOrderIds(); // Generar nuevo orderid para esta transacción

        // 2. Validar el número de tarjeta (debe ser de 16 dígitos)
        let ccnumber = cardData.number.replace(/\s/g, "");
        if (ccnumber.length < 15 || ccnumber.length > 16 || !/^\d+$/.test(ccnumber)) {
            alert("Error: El número de tarjeta debe tener 15 o 16 dígitos.");

            return;
        }

        // 3. Validar la fecha de expiración (formato MMYY)
        let ccexp = cardData.expiry.replace("/", "");
        if (ccexp.length !== 4 || !/^\d+$/.test(ccexp)) {
            alert("Error: La fecha de expiración debe estar en el formato MMYY.");

            return;
        }

        // 4. Validar el CVV (debe ser de 3 dígitos)
        let cvv = cardData.cvc;
        if (cvv.length < 3 || cvv.length > 4 || !/^\d+$/.test(cvv)) {
            alert("Error: El CVV debe tener 3 o 4 dígitos.");

            return;
        }

        // 5. Validar el monto (debe ser un número válido con dos decimales)
        let amountBac = parseFloat(totalSubTotal).toFixed(2);
        // console.log("amountBac", amountBac);
        if (isNaN(amountBac) || amountBac <= 0) {
            alert("Error: El monto debe ser un número válido mayor que 0.");
            return;
        }

        // 6. Generar el hash después de las validaciones
        let hash = createHash(orderid, amountBac, time, secretKeyBac);
        if (!hash) {
            alert("Error: No se pudo generar el hash. Favor de intentar de nuevo.");

            return;
        }
        setloadingPrincipal(true);

        // 7. Insertar sesión temporal y guardar movieParam en localStorage
        localStorage.setItem("movieParamCon", movieParam);
        const currentDomain = window.location.origin;
        await insertTemporarySession();
        await dispatch(insertartTempInvoiceConfiteria(movieParam, orderid));

        // 8. Crear el formulario y enviar los datos validados
        const form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", "https://credomatic.compassmerchantsolutions.com/api/transact.php");

        // 9. Añadir los campos ocultos al formulario dinámico
        const hiddenFields = [
            { name: "type", value: "sale" },
            { name: "key_id", value: "9745798" },
            { name: "hash", value: hash },
            { name: "time", value: time },
            { name: "redirect", value: `${currentDomain}/successConfiteria/${movieParam}/successConfiteria` },
            { name: "orderid", value: orderid }, // Usar el nuevo orderid generado
            { name: "amount", value: amountBac }, // Usar el monto validado
            { name: "ccnumber", value: ccnumber }, // Número de tarjeta validado
            { name: "ccexp", value: ccexp }, // Expiración validada
            { name: "cvv", value: cvv }, // CVV validado
        ];

        // 10. Hacer un console.log para verificar qué datos se están enviando (opcional)
        //console.log("Datos enviados al formulario de Credomatic:", hiddenFields);

        hiddenFields.forEach((field) => {
            const input = document.createElement("input");
            input.setAttribute("type", "hidden");
            input.setAttribute("name", field.name);
            input.setAttribute("value", field.value);
            form.appendChild(input);
        });

        document.body.appendChild(form);
        form.submit();
    };

    const getCurrentDate = () => {
        const date = new Date();
        const day = date.getDate();
        const monthNames = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    };

    /* Hook para utilizar el dispatcher de Redux, permitiendo acciones para modificar el estado global */
    const [idArticuleToDelete, setIdArticuleToDelete] = useState(null);

    // Función para eliminar el artículo
    const deleteArticule = (idArticule) => {
        setIdArticuleToDelete(idArticule); // Guardar el ID del artículo a eliminar
        dispatch(setdeleteArticule({ idArticule }));
    };

    // Función asíncrona para generar una factura temporal
    const generarTemporal = async () => {
        localStorage.setItem("movieParamCon", movieParam);
        // Indicamos que el proceso está cargando
        setloadingPrincipal(true);

        // Ejecutamos la acción de insertar la factura temporal de confitería y butaca
        await insertTemporarySession();
        const orde = "temporal";
        await dispatch(insertartTempInvoiceConfiteria(movieParam, orde));
        navigate(`/successConfiteria/${movieParam}/successConfiteria?response=2&responsetext=INVALID+SECURITY+CODE&authcode=&transactionid=9901038044&avsresponse=U&cvvresponse=P&orderid=Order-2pm521ltr&type=sale&response_code=100&username=9745798&time=1725473307&amount=0.50&hash=f325b1fd7714cdbaeaedb54eeb10891dd7714cdbaeaedb54eeb10891d`);

        // Indicamos que el proceso ha terminado
        setloadingPrincipal(false);

        return;
    };

    const volverConfiteria = () => {
        navigate(`/confiteria?confiteria=${encodeURIComponent(movieParam)}`);
    };

    const handleCloseOption = () => {
        // Función para cerrar el diálogo registro
        setOptionDialog(false);
    };

    return (
        <>
            <Stack style={{ marginTop: isMobile || isTablet ? "8%" : "3%", backgroundImage: "url(/content/img/SC/fondo-CP.svg)", backgroundSize: "cover", padding: "50px" }}>
                <Stack direction={isMobile || isTablet ? "column" : "row"} justifyContent={isMobile || isTablet ? "space-evenly" : "flex-start"} alignItems="flex-start" spacing={isMobile || isTablet ? 12 : 20} style={{ marginTop: isMobile ? "25%" : "2%", paddingLeft: isMobile ? "" : "10%", border: isMobile || isTablet ? "1px solid rgba(255, 255, 255, 0.20)" : "none", borderRadius: "20px" }}>
                    <Stack spacing={isMobile || isTablet ? 2 : 5} direction="column" justifyContent="flex-start" alignItems={isMobile || isTablet ? "center" : "flex-start"} style={{ width: "100%" }}>
                        <Typography style={{ color: "white", ...typographyStyles.extraBold, fontSize: isMobile ? "15px" : "32px", fontWeight: "100", display: isMobile || isTablet ? "none" : "block" }}>CONFIRMACIÓN Y PAGO</Typography>

                        <Backdrop open={loadingPrincipal} style={{ zIndex: 9999 }}>
                            <CircularProgress color="success" />
                        </Backdrop>
                        <OptionDialog open={optionDialog} handleClose={handleCloseOption} />

                        {/* <LoadingDialog LoadingOpen={loading} /> */}

                        <FirmeProcessDialog LoadingOpen={loadingFirme} />
                        <Stack direction="column" justifyContent="center" alignItems={isMobile || isTablet ? "center" : "space-evenly"} spacing={2} style={{ marginTop: isMobile || isTablet ? "0%" : "0%", marginLeft: isMobile || isTablet ? "0%" : "0%", ...typographyStyles.orderDetails, width: "80%" }}>
                            <Typography style={{ color: "white", ...typographyStyles.light, fontSize: isMobile ? "15px" : "24px", fontWeight: "100" }}>DETALLES DE ORDEN</Typography>

                            <Stack direction="row" justifyContent="space-evenly" alignItems="center">
                                <Stack>
                                    <Stack>
                                        <Typography style={{ color: "white", ...typographyStyles.extraBold, fontSize: isMobile ? "15px" : "24px", display: isMobile || isTablet ? "none" : "block" }}>Santa Ana - City Place</Typography>

                                        <Typography
                                            style={{
                                                color: "white",
                                                ...typographyStyles.light,
                                                fontSize: "15px",
                                                fontWeight: "100",
                                                display: isMobile || isTablet ? "none" : "block",
                                            }}
                                        >
                                            {getCurrentDate()}
                                        </Typography>
                                    </Stack>
                                    <br />
                                    <Stack>
                                        <Typography style={{ color: "white", ...typographyStyles.extraBold, fontSize: isMobile ? "15px" : "20px", display: isMobile || isTablet ? "none" : "block" }}>
                                            {auth.name_user} {auth.lasname_user}
                                        </Typography>

                                        <Typography
                                            style={{
                                                color: "white",
                                                ...typographyStyles.light,
                                                fontSize: "16px",
                                                fontWeight: "100",
                                                display: isMobile || isTablet ? "none" : "block",
                                                textTransform: "none",
                                            }}
                                        >
                                            {auth.email_user}
                                        </Typography>
                                    </Stack>
                                </Stack>

                                {isResponseCodeVLoaded ? responseCodeV === 100 ? <MessageDialogConfiteria url={urlPayment} title="Compra realizada con éxito" icon="/content/img/SC/check.svg" description="Descarga el PDF de tu compra haciendo clic en el botón." download={true} /> : <MessageDialog title="Transacción denegada." description="Asegúrate de que los datos en los campos sean correctos." icon="/content/img/SC/alertIcon.png" width="50%" alert={true} /> : null}
                                <Stack spacing={1}>
                                    <Typography style={{ color: "white", ...typographyStyles.bold, fontSize: isMobile ? "12px" : "16px", textTransform: "uppercase" }}>CONFITERÍA</Typography>
                                    {step4.articules.map((producto, index) => (
                                        // Agregar key único aquí
                                        <Stack key={producto.idArt + producto.key + index} direction="row" justifyContent="space-between">
                                            <Stack>
                                                <Stack direction="row" spacing={1}>
                                                    <Typography className="detalleOrden">{producto.cantProduct}</Typography>
                                                    <Stack>
                                                        <Typography className="detalleOrden">{producto.nombre}</Typography>
                                                        <Stack direction="column" spacing={0.5}>
                                                            <Typography className="detalle2Orden">
                                                                {producto.size.name && `${producto.size.name} ,`} {producto.flavors.name && `${producto.flavors.name} ,`}
                                                            </Typography>

                                                            {producto.drinks.map((drink, drinkIndex) => (
                                                                // Agregar key único aquí
                                                                <Typography key={`${drink.name}-${drink.id}-${drink.key}-${producto.idArt}-${producto.key}-${index}-${drinkIndex}`} className="detalle2Orden">
                                                                    {drink.name && `${drink.name},`}
                                                                </Typography>
                                                            ))}
                                                            {producto.extras.map((extra, extraIndex) => (
                                                                // Agregar key único aquí
                                                                <Typography key={`${extra.id}-${extra.key}-${producto.idArt}-${producto.key}-${index}-${extraIndex}`} className="detalle2Orden">
                                                                    {extra.name && `${extra.name},`}
                                                                </Typography>
                                                            ))}
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Stack>

                                            <Typography className="detalleOrden">₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([producto.subTotal])}</Typography>
                                            <Button onClick={() => deleteArticule(producto.idArt)}>
                                                <img src="/content/img/SC/delete.svg" />
                                            </Button>
                                        </Stack>
                                    ))}
                                </Stack>

                                <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={3}>
                                    <Typography style={{ color: "white", ...typographyStyles.bold, fontSize: isMobile ? "9px" : isTablet ? "15px" : "24px", textTransform: "uppercase" }}>total</Typography>
                                    <Typography style={{ color: "white", ...typographyStyles.extraBold, fontSize: isMobile ? "10px" : isTablet ? "15px" : "40px", textTransform: "uppercase" }}>₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([totalSubTotal])}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>

                        <Stack direction={isMobile || isTablet ? "column" : "row"} spacing={isMobile || isTablet ? 2 : 8} justifyContent="center" alignItems="center" style={{ border: isMobile || isTablet ? "none" : "1px solid rgba(255, 255, 255, 0.20)", backgroundColor: isMobile || isTablet ? "transparent" : "rgba(0, 0, 0, 0.24)", borderRadius: "20px", padding: "4%", marginLeft: isMobile ? "0%" : "0%", width: "76%" }}>
                            <Stack justifyContent="center" alignItems={isMobile || isTablet ? "center" : "flex-start"}>
                                <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile || isTablet ? "15px" : "16px", textTransform: "uppercase" }}>CÓDIGOS PROMOCIONALES</Typography>
                                <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile || isTablet ? "9px" : "14px", textTransform: "uppercase", fontWeight: "100", textAlign: isTablet ? "start" : "center" }}>INGRESÁ ACÁ TUS CÓDIGOS PROFESIONALES Y OBTEN DESCUENTOS EN TU ORDEN</Typography>
                            </Stack>

                            <input className="inputPromo" type="text" />
                        </Stack>

                        <Typography style={{ color: "rgba(199, 199, 199, 1)", fontFamily: "FuturaP-ExtraBold", fontSize: isMobile ? "15px" : "24px", textTransform: "uppercase" }}>MÉTODO DE PAGO</Typography>
                        {paymentMessage && <Typography style={{ color: "white", fontFamily: "FuturaP-Medium", fontSize: "15px", textTransform: "uppercase", marginTop: "10px" }}>{paymentMessage}</Typography>}
                        <Stack direction={isMobile || isTablet || isSurface ? "column" : "row"} justifyContent={isSurface ? "center" : "flex-start"} alignItems={isSurface ? "center" : "flex-start"} style={{ border: isMobile || isTablet ? "none" : "1px solid rgba(255, 255, 255, 0.20)", backgroundColor: isMobile || isTablet ? "transparent" : "rgba(0, 0, 0, 0.24)", borderRadius: "20px", padding: "5%", marginTop: "0%", width: "74%" }} spacing={10}>
                            <Stack direction="column" spacing={2}>
                                <Stack style={{ display: isMobile || isTablet ? "none" : "block" }}>
                                    <Cards cvc={cardData.cvc} expiry={cardData.expiry} name={cardData.name} number={cardData.number} focused={focused} />
                                </Stack>
                                <Stack style={{ marginLeft: isSurface ? "17%" : "0%" }}>
                                    <Stack>
                                        <Typography style={{ color: "rgba(199, 199, 199, 1)", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "15px" : "16px", textTransform: "uppercase" }}>NOMBRE EN LA TARJETA *</Typography>
                                        <input className="inputMP" type="text" name="name" value={cardData.name} onChange={handleNameChange} onFocus={() => setFocused("name")} onBlur={() => setFocused("")} />
                                        {errors.name && <Typography style={{ color: "red", fontSize: "12px" }}>{errors.name}</Typography>}
                                    </Stack>
                                    <Stack>
                                        <Typography style={{ color: "rgba(199, 199, 199, 1)", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "15px" : "16px", textTransform: "uppercase" }}>NÚMERO DE TARJETA *</Typography>
                                        <input className="inputMP" type="text" name="number" value={cardData.number} onChange={handleNumberChange} onFocus={() => setFocused("number")} onBlur={() => setFocused("")} />
                                        {errors.number && <Typography style={{ color: "red", fontSize: "12px" }}>{errors.number}</Typography>}
                                    </Stack>
                                </Stack>
                                <Stack direction="row">
                                    <Stack style={{ display: isMobile || isTablet ? "block" : "none" }}>
                                        <Typography style={{ color: "rgba(199, 199, 199, 1)", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "15px" : "16px", textTransform: "uppercase" }}>EXPIRA *</Typography>
                                        <input className="inputMP" type="text" name="expiry" value={cardData.expiry} onChange={handleExpiryChange} onFocus={() => setFocused("expiry")} onBlur={() => setFocused("")} style={{ width: "100px" }} />
                                        {errors.expiry && <Typography style={{ color: "red", fontSize: "12px" }}>{errors.expiry}</Typography>}
                                    </Stack>

                                    <Stack direction={isMobile || isTablet ? "column" : "row"} justifyContent="flex-start" alignItems="center" spacing={1} style={{ display: isMobile || isTablet ? "block" : "none" }}>
                                        <Stack spacing={0}>
                                            <Typography style={{ color: "rgba(199, 199, 199, 1)", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "15px" : "16px", textTransform: "uppercase" }}>CVC *</Typography>
                                            <input className="inputMP" type="password" name="cvc" value={cardData.cvc} onChange={handleCVCChange} onFocus={() => setFocused("cvc")} onBlur={() => setFocused("")} maxLength="4" style={{ width: isMobile ? "100px" : "116px" }} />
                                            {errors.cvc && <Typography style={{ color: "red", fontSize: "12px" }}>{errors.cvc}</Typography>}
                                        </Stack>
                                        <Typography style={{ color: "rgba(255, 255, 255, 1)", fontFamily: "FuturaP-Light", fontSize: isMobile ? "8px" : "12px", fontWeight: "100" }}>
                                            El código de 3 o 4 dígitos en la parte
                                            <br />
                                            posterior de su tarjeta
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>

                            <Stack direction="column" spacing={0} style={{ marginTop: isMobile || isTablet ? "0%" : "0%" }}>
                                <Stack style={{ display: isMobile || isTablet ? "none" : "block" }}>
                                    <Typography style={{ color: "rgba(199, 199, 199, 1)", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "15px" : "16px", textTransform: "uppercase" }}>EXPIRA *</Typography>
                                    <input className="inputMP" type="text" name="expiry" value={cardData.expiry} onChange={handleExpiryChange} onFocus={() => setFocused("expiry")} onBlur={() => setFocused("")} />
                                    {errors.expiry && <Typography style={{ color: "red", fontSize: "12px" }}>{errors.expiry}</Typography>}
                                </Stack>

                                <Stack direction={isMobile || isTablet ? "column" : "row"} justifyContent="flex-start" alignItems="center" spacing={1} style={{ display: isMobile || isTablet ? "none" : "flex" }}>
                                    <Stack spacing={0}>
                                        <Typography style={{ color: "rgba(199, 199, 199, 1)", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "15px" : "16px", textTransform: "uppercase" }}>CVC *</Typography>
                                        <input className="inputMP" type="password" name="cvc" value={cardData.cvc} onChange={handleCVCChange} onFocus={() => setFocused("cvc")} onBlur={() => setFocused("")} style={{ width: isMobile ? "100px" : "116px" }} />
                                        {errors.cvc && <Typography style={{ color: "red", fontSize: "12px" }}>{errors.cvc}</Typography>}
                                    </Stack>
                                    <Typography style={{ color: "rgba(255, 255, 255, 1)", fontFamily: "FuturaP-Light", fontSize: isMobile ? "8px" : "12px", fontWeight: "100" }}>
                                        El código de 3 o 4 dígitos en la parte
                                        <br />
                                        posterior de su tarjeta
                                    </Typography>
                                </Stack>

                                <br />
                            </Stack>

                            <Stack style={{ marginTop: "0%" }}>
                                <FormControlLabel value="end" control={<Checkbox defaultChecked />} label="Deseo recibir información y ofertas de Studio Cinemas" labelPlacement="end" style={{ marginTop: "8%" }} />
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox checked={termsAccepted} onChange={handleTermsChange} />}
                                    label={
                                        <span>
                                            Acepto los{" "}
                                            <Link to="#" style={{ color: "#2C98E0" }}>
                                                términos y condiciones
                                            </Link>
                                            de uso
                                        </span>
                                    }
                                    labelPlacement="end"
                                />
                                {termsError && <Typography style={{ color: "red", fontFamily: "FuturaP-Medium", fontSize: "11px", textTransform: "uppercase" }}>{termsError}</Typography>}

                                <Button className="btnPay" disabled={!isFormValid} onClick={handleSubmit} onClickCapture={handleButtonClick}>
                                    Pagar
                                </Button>
                                <Button className="btnPay" onClick={volverConfiteria}>
                                    Volver
                                </Button>
                                {/* <br></br>
                                <br></br>
                                <Button className="btnNext" style={{ marginTop: "10%" }} onClick={generarTemporal}>
                                    Generar Temporal
                                </Button> */}
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};

export default ViewConfirmacionPago;
