/* El fragmento de código que proporcionó importa varios módulos y funciones de diferentes bibliotecas
y archivos. A continuación se muestra un desglose de lo que hace cada declaración de importación: */
import { Button, Divider, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Input from "@mui/material/Input";
import { useDispatch, useSelector } from "react-redux";
// import { useActiveTandaEffect } from "./Step1Helpers";
import { setInsertDataStep1 } from "../../../store/checkout/checkoutSlice";
import { secretKey } from "../../../api";
import CryptoJS from "crypto-js";
import { convertTime } from "../../../hooks/useTimeBillboard";
import { useNavigate } from "react-router-dom";
import { getTandasBd, setActiveMovieInfo, updatearrayInfoMovie } from "../../../store/home";
import "../../styles/Login.css";
import LoginDialog from "../componentsGlobal/LoginDialog";
import RegisterDialog from "../componentsGlobal/RegisterDialog";
import GuestDialog from "../componentsGlobal/GuestDialog";

const ariaLabel = { "aria-label": "description" };
const Step1 = () => {
    // Importar el tema de Material-UI
    const theme = useTheme();

    const statusRegister = useSelector((state) => state.auth.status);
    const disableButton = statusRegister === "loading" ? true : false;

    // Verificar si la pantalla es móvil utilizando el tema de Material-UI
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil
    const isSurface = useMediaQuery("(max-width:1370px)"); // Verifica si la pantalla es móvil
    // Obtener datos de la tienda de Redux
    const { allMoviesBillboard } = useSelector((state) => state.home);
    const { activeTanda, step1 } = useSelector((state) => state.checkout);

    const [openDialog, setOpenDialog] = useState(false); // Estado para controlar la apertura del diálogo
    const [openRegister, setOpenRegister] = useState(false); // Estado para controlar la apertura del diálogo
    const [openGuest, setOpenGuest] = useState(false); // Estado para controlar la apertura del diálogo

    // Recuperar y desencriptar el payload desde localStorage
    const encryptedPayload_2 = localStorage.getItem("payload_2");

    let parsedPayload_2 = {};
    if (encryptedPayload_2) {
        try {
            // Desencriptar el payload utilizando CryptoJS
            const decryptedPayload_2 = CryptoJS.AES.decrypt(encryptedPayload_2, secretKey).toString(CryptoJS.enc.Utf8);
            parsedPayload_2 = JSON.parse(decryptedPayload_2);
        } catch (error) {
            parsedPayload_2 = {}; // Asegurarse de que parsedPayload_2 sea un objeto incluso si falla la desencriptación
        }
    }

    const handleClickOpenDialog = () => {
        // Función para abrir el diálogo
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        // Función para cerrar el diálogo
        setOpenDialog(false);
    };

    const handleClickOpenRegister = () => {
        // Función para abrir el diálogo
        setOpenRegister(true);
    };

    const handleCloseRegister = () => {
        // Función para cerrar el diálogo
        setOpenRegister(false);
    };

    const handleClickOpenGuest = () => {
        // Función para abrir el diálogo
        setOpenGuest(true);
    };

    const handleCloseGuest = () => {
        // Función para cerrar el diálogo
        setOpenGuest(false);
    };

    // Inicializar el estado de los datos del formulario
    const [formData, setFormData] = useState({
        nombre: step1?.data?.nombre || parsedPayload_2?.nombre || "",
        apellidos: step1?.data?.apellidos || parsedPayload_2?.apellidos || "",
        correo: step1?.data?.correo || parsedPayload_2?.correo || "",
        cedula: step1?.data?.cedula || parsedPayload_2?.cedula || "",
        telefono: step1?.data?.telefono || parsedPayload_2?.telefono || "",
    });

    // Manejar el cambio de los inputs y actualizar el estado de los datos del formulario
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Obtener la función dispatch para despachar acciones
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Verificar si imageMaster está vacío y redirigir
    const imageMaster = allMoviesBillboard.find((movie) => movie.idMasterMovie === activeTanda?.idMaster) || "";

    // Actualizar la tienda de Redux con los datos del formulario después de un retraso
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            dispatch(
                setInsertDataStep1({
                    nombre: formData.nombre,
                    apellidos: formData.apellidos,
                    correo: formData.correo,
                    cedula: formData.cedula,
                    telefono: formData.telefono,
                }),
            );
        }, 500);

        // Limpiar el timeout cuando el componente se desmonte o se actualice formData
        return () => clearTimeout(timeoutId);
    }, [formData, dispatch]);

    return (
        <Stack direction={isMobile || isTablet ? "column" : "row"} justifyContent="space-evenly" alignItems="center" style={{ marginTop: isMobile ? "25%" : "2%", marginLeft: isMobile ? "0%" : "-21%" }}>
            <Stack direction={isMobile || isTablet ? "row" : "column"} spacing={isMobile || isTablet ? 2 : 1} alignItems={isMobile || isTablet ? "center" : "flex-start"}>
                <img src={imageMaster?.image} alt="imagen" width={isMobile || isTablet ? "120px" : "313px"} height={isMobile || isTablet ? "155px" : "441px"} />
                <Stack>
                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", textTransform: "uppercase" }}>Pelicula:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", fontWeight: "100", textTransform: "uppercase" }}>{activeTanda.name_movie_bp}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", textTransform: "uppercase" }}>DÍA:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", fontWeight: "100", textTransform: "uppercase" }}>{convertTime(activeTanda.date_time_shifts_bp)}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", textTransform: "uppercase" }}>SALA:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", fontWeight: "100", textTransform: "uppercase" }}>{activeTanda.room_name_shifts_bp}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", textTransform: "uppercase" }}>HORA:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "12px" : isSurface ? "15px" : "20px", fontWeight: "100", textTransform: "uppercase" }}>{activeTanda.hora}</Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Stack spacing={isMobile || isTablet ? 2 : 15} direction="column" justifyContent="flex-start" alignItems={isMobile || isTablet ? "center" : "flex-start"} style={{ marginTop: isMobile ? "0%" : "-13%", marginLeft: isTablet ? "20%" : "-15%" }}>
                <Stack direction="row" spacing={2} style={{ marginTop: isMobile ? "8%" : isTablet ? "30%" : "0%" }}>
                    {/* <Typography style={{ color: "white", fontFamily: "FuturaP-ExtraBold", fontSize: isMobile ? "15px" : "32px", textTransform: "uppercase", backgroundColor: "#66B02E", paddingTop: isMobile ? "10px" : "11px", paddingLeft: isMobile ? "16px" : "23px", paddingRight: isMobile ? "16px" : "23px", paddingBottom: isMobile ? "0px" : "5px", borderRadius: "100%" }}>1</Typography> */}
                    <img src="/content/img/SC/step1.svg" style={{ width: "8%" }} />
                    {statusRegister === "checking" && (
                        <Stack spacing={isMobile ? 0 : -1}>
                            <Typography style={{ color: "white", fontFamily: "FuturaP-ExtraBold", fontSize: isMobile ? "15px" : "32px", fontWeight: "100", textTransform: "uppercase" }}>INFORMACIÓN DE USUARIO</Typography>
                            <Typography style={{ color: "#fff", fontFamily: "FuturaP-Light", fontSize: isMobile ? "10px" : "16px", textTransform: "uppercase" }}>INICIÁ SESIÓN AHORA O CONTINUA COMO INVITADO</Typography>
                        </Stack>
                    )}
                </Stack>

                <Stack direction={"row"} spacing={isMobile ? 2 : 8} style={{ paddingLeft: isMobile ? "0%" : "30%" }}>
                    <Stack spacing={4}>
                        {statusRegister === "checking" && (
                            <Button disabled={disableButton} className="btnLogin" onClick={handleClickOpenDialog}>
                                INICIAR SESIÓN
                            </Button>
                        )}

                        <LoginDialog open={openDialog} handleClose={handleCloseDialog} />

                        {statusRegister === "checking" && (
                            <Button className="btnRegister" onClick={handleClickOpenRegister}>
                                REGISTRARSE
                            </Button>
                        )}

                        <RegisterDialog open={openRegister} handleClose={handleCloseRegister} />
                    </Stack>

                    {statusRegister === "checking" && <Divider orientation="vertical" variant="middle" flexItem style={{ backgroundColor: "#727272" }} />}

                    <Stack justifyContent={"center"}>
                        {statusRegister === "checking" && (
                            <Button className="btnLogin" onClick={handleClickOpenGuest}>
                                INVITADO
                            </Button>
                        )}
                        <GuestDialog open={openGuest} handleClose={handleCloseGuest} />
                    </Stack>
                </Stack>

                {/* <Stack direction={isMobile || isTablet ? "column" : "row"} spacing={2} style={{ borderTop: isMobile || isTablet ? "1px solid rgba(255, 255, 255, 0.20)" : "none" }}>
                    <Stack spacing={2} style={{ paddingTop: isMobile || isTablet ? "6%" : "0%" }}>
                        <Stack>
                            <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "13px" : "16px", textTransform: "uppercase" }}>Nombre *</Typography>
                            <Input className="inputStep1" inputProps={ariaLabel} required name="nombre" value={formData.nombre} onChange={handleInputChange} />
                        </Stack>

                        <Stack>
                            <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "13px" : "16px", textTransform: "uppercase" }}>APELLIDOS *</Typography>
                            <Input className="inputStep1" inputProps={ariaLabel} required name="apellidos" value={formData.apellidos} onChange={handleInputChange} />
                        </Stack>

                        <Stack>
                            <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "13px" : "16px", textTransform: "uppercase" }}>CORREO ELECTRÓNICO *</Typography>
                            <Input className="inputStep1" inputProps={ariaLabel} required name="correo" value={formData.correo} onChange={handleInputChange} />
                            <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "13px" : "16px" }}>Importante*</Typography>
                            <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "13px" : "14px" }}>El comprobante de compra será enviado a este correo, {isSurface && <br />} asegúrate de ingresarlo correctamente.</Typography>
                        </Stack>
                    </Stack>

                    <Stack spacing={2}>
                        <Stack>
                            <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "13px" : "16px", textTransform: "uppercase" }}>CÉDULA O IDENTIFICACIÓN *</Typography>
                            <Input className="inputStep1" inputProps={ariaLabel} required name="cedula" value={formData.cedula} onChange={handleInputChange} />
                        </Stack>

                        <Stack>
                            <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "13px" : "16px", textTransform: "uppercase" }}>TELÉFONO</Typography>
                            <Input className="inputStep1" inputProps={ariaLabel} name="telefono" value={formData.telefono} onChange={handleInputChange} />
                        </Stack>
                    </Stack>
                </Stack> */}
            </Stack>
        </Stack>
    );
};

export default Step1;
