import { commonRequestData, fetchData } from "../../api";


export const setRegisterAuth = async ({ formData }) => {

    const requestData = {
        ...commonRequestData,
        formData,
    };


    return await fetchData("cineverse/users/register", requestData);
};

export const setLoginAuth = async ({ email, password }) => {
    const requestData = {
        ...commonRequestData,
        email,
        password,
    };

    return await fetchData("cineverse/users/login", requestData);
};

;

// Función exportada que realiza el proceso de cierre de sesión
export const exitLogout = async () => {
    // Retorna una cadena indicando que el proceso de cierre de sesión fue exitoso
    return "ok";
};


export const recoverPasswordAsync = async ({ email }) => {
    const requestData = {
        ...commonRequestData,
        email
    };

    return await fetchData("cineverse/users/recoverPassword", requestData);
};
