import React, { useEffect, useState } from "react";
import { Stack, Typography, Button, useMediaQuery, useTheme, ToggleButton, ToggleButtonGroup, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { Backdrop, CircularProgress } from "@mui/material";
import "../../styles/confiteria.css";
import FoodAccordion from "../componentsCheckout/FoodAccordion";
import { NavLink, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { clearArticuleList, restoreStateCheckoutNewBd, setdeleteArticule } from "../../../store/checkout/checkoutSlice";
import OptionDialog from "../componentsGlobal/OptionDialog";
import { geTemporaryPayment, getAritucule } from "../../../store/checkout";
import { restaurarDatosProcesodePago } from "../../../store/auth/authSlice";
import { restoreStateHomeNewBd } from "../../../store/home";
import useInsertTemporarySession from "../../../hooks/updatePymentClient";

/* generador de token para la url de la pelcula seleccionada */

function generateToken() {
    const date = new Date();
    const components = ["session-", Math.random().toString(36).slice(2, 11), "confiteria", date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), Math.floor(Math.random() * 1000)];
    return components.sort(() => Math.random() - 0.5).join("");
}

function generateIdSession() {
    return `session`;
}
/* generador de token para la url de la pelcula seleccionada */

const Confiteria = () => {
    const [expanded, setExpanded] = useState(false);
    const [openCategory, setOpenCategory] = useState("");
    /* Hook de Redux para acceder al estado de Redux: Extrae `step4` del reducer `checkout` del estado de Redux */
    const { step4 } = useSelector((state) => state.checkout);
    const statusRegister = useSelector((state) => state.auth.status);
    const [optionDialog, setOptionDialog] = useState(false); // Estado para controlar la apertura del diálogo de login
    /* Hooks de Material-UI para temas y responsividad */
    /* Hook `useTheme` para acceder al objeto de tema utilizado para diseño responsivo */
    const theme = useTheme();
    /* Hook `useMediaQuery` verifica si el ancho actual de la pantalla es menor o igual al punto de interrupción pequeño, identificando efectivamente dispositivos móviles */
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil

    /* Configuración de estado local para manejar el alineamiento de los componentes en la interfaz */
    const [alignment, setAlignment] = useState("web");
    const [iformacionBan, setBanck] = useState("");
     const [validarInfo, setvalidarInfo] = useState(0);

    /*  nueva implementacion de la confiteria *********************************************************/

    const [loading, setLoading] = useState(true);
    const [isEffectComplete, setIsEffectComplete] = useState(false);
    const [movieParam, setMovieParam] = useState(null);
    /* Hook para utilizar el dispatcher de Redux, permitiendo acciones para modificar el estado global */
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Función asíncrona para ejecutar el efecto
    const executeEffect = async (navigate, dispatch, setMovieParam, setIsEffectComplete, setLoading) => {
        await dispatch(getAritucule());
        // Obtenemos los parámetros de la URL actual utilizando URLSearchParams
        const urlParams = new URLSearchParams(window.location.search);

        // Extraemos el valor asociado al parámetro 'movie' de la URL
        const movieValue = urlParams.get("confiteria");

        // Verificamos si el valor del parámetro 'movie' es nulo, vacío o indefinido
        if (movieValue) {
            setMovieParam(movieValue);

            // Función para obtener los datos de pago temporal desde el servidor
            const fetchData = async () => {
                // Dispatch de la acción para obtener los datos de pago temporal relacionados con la película
                const { data } = await dispatch(geTemporaryPayment(movieValue));
                if (data.statusCode === 200) {
                    const dataUser = data.data[0];
                    await dispatch(restaurarDatosProcesodePago(dataUser.temporary_payment_data_authUser.datosUsuario));
                    await dispatch(restoreStateHomeNewBd(dataUser.temporary_payment_data_homeState));
                    await dispatch(restoreStateCheckoutNewBd(dataUser.temporary_payment_data_checkoutState));
                    setBanck(dataUser.temporary_payment_data_banck);
                    setLoading(false);
                } else {
                    dispatch(clearArticuleList());
                    navigate("/confiteria");
                    setLoading(false);
                }
                setvalidarInfo(1)
            };

            // Ejecutamos la función de fetchData y esperamos a que termine
            await fetchData();

            // Indicamos que el efecto ha terminado
            setIsEffectComplete(true);
        } else {
            dispatch(clearArticuleList());
            setLoading(false);
        }
    };

    // Dentro de useEffect llamamos a la función asíncrona
    useEffect(() => {
        const runEffect = async () => {
            // Llamamos a executeEffect y esperamos su finalización
            await executeEffect(navigate, dispatch, setMovieParam, setIsEffectComplete, setLoading);
        };

        // Ejecutamos el efecto al montar el componente
        runEffect();
    }, [navigate, dispatch, setMovieParam, setIsEffectComplete, setLoading]);

    const insertTemporarySession = useInsertTemporarySession(movieParam, iformacionBan);
useEffect(() => {
    // Verificar si movieParam está definido y validarInfo es mayor que 1
    if (movieParam && step4.articules && validarInfo > 1) {
        insertTemporarySession();
    }
}, [step4.articules, movieParam, validarInfo]);
    /* Hook de Redux para acceder al estado de Redux: Extrae `step4` del reducer `checkout` del estado de Redux */

    /* Calcula el total de los productos seleccionados en la etapa 4, sumando los subtotales */
    const total = step4.articules.reduce((acc, producto) => acc + parseFloat(producto.subTotal), 0);

    /* Verifica si la lista de artículos en el paso 4 no está vacía */
    const isNotEmpty = step4.articules.length > 0;

    /* Función para manejar cambios en el alineamiento, actualizando el estado local */
    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const handleOpenAccordion = (panelKey) => (event, isExpanded) => {
        if (isExpanded) {
            setExpanded(isExpanded ? panelKey : false);
        } else {
            expanded === panelKey ? setExpanded(false) : setExpanded(panelKey);
        }
    };

    /* Función para eliminar un artículo, despacha la acción `setdeleteArticule` con el id del artículo como payload */
    const deleteArticule = (idArticule) => {
        dispatch(setdeleteArticule({ idArticule }));
    };

    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    const handleClickOpenOption = () => {
        // Función para abrir el diálogo registro
        setOptionDialog(true);
    };
    const handleCloseOption = () => {
        // Función para cerrar el diálogo registro
        setOptionDialog(false);
    };

    const handleItemClicked = () => {
        const token = generateToken();
        const confiteriaParam = movieParam || token;
        navigate(`/confiteria/payment?confiteria=${encodeURIComponent(confiteriaParam)}`);
    };

    return (
        <>
            <Stack style={{ marginTop: isMobile || isTablet ? "8%" : "3%", backgroundImage: "url(/content/img/SC/fondo-CP.svg)", backgroundSize: "cover", padding: "50px" }}>
                <Backdrop open={loading} style={{ zIndex: 9999 }}>
                    <CircularProgress color="success" />
                </Backdrop>
                <Stack spacing={-1} justifyContent="center" alignItems={isMobile || isTablet ? "center" : "space-between"}>
                    <Typography className="tituloConfiteria">CONFITERÍA</Typography>

                    <Typography style={{ display: isMobile || isTablet ? "block" : "none" }} className="subtituloConfiteria">
                        {" "}
                        ¿Sabías que al comprar tus snacks en la confitería del cine puedes agilizar el proceso y evitar las colas?
                    </Typography>

                    <Stack direction={isMobile || isTablet ? "column" : "row"} spacing={4} style={{ border: isMobile || isTablet ? "none" : "1px solid rgba(255, 255, 255, 0.20)", backgroundColor: isMobile || isTablet ? "transparent" : "rgba(0, 0, 0, 0.24)", borderRadius: "20px", padding: "6%", paddingTop: "2%" }}>
                        <Stack style={{ display: isMobile || isTablet ? "none" : "block" }}>
                            {/* <ToggleButtonGroup color="primary" value={alignment} exclusive 
                            onChange={() => handleOpenAccordion(222)}
                            aria-label="Platform"> */}
                            <Stack spacing={3}>
                                {step4.dataProducts.map((category) => (
                                    <Button key={category.id + category.key} value={category.id} className="btnFilterAction" onClick={handleOpenAccordion(category.id)} style={{ backgroundColor: "rgba(255, 255, 255, 0.10)" }}>
                                        {category.title}
                                    </Button>
                                ))}
                            </Stack>
                            {/* </ToggleButtonGroup> */}

                            <Stack style={{ display: isNotEmpty ? (isMobile || isTablet ? "none" : "block") : "none" }}>
                                <Typography className="tituloOrden">CONFITERÍA</Typography>

                                <Stack spacing={2} style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.20)", paddingBottom: "5%" }}>
                                    {step4.articules.map((producto) => (
                                        <Stack key={producto.idArt + producto.key} direction="row" justifyContent="space-between">
                                            <Stack>
                                                <Stack direction="row" spacing={1}>
                                                    <Typography className="detalleOrden">{producto.cantProduct}</Typography>
                                                    <Stack>
                                                        <Typography className="detalleOrden">{producto.nombre}</Typography>
                                                        <Stack direction="column" spacing={0.5}>
                                                            <Typography className="detalle2Orden">
                                                                {producto.size.name && `${producto.size.name} ,`} {producto.flavors.name && `${producto.flavors.name} ,`}
                                                            </Typography>
                                                            <Typography className="detalle2Orden"> </Typography>
                                                            {producto.drinks.map((drink, index) => (
                                                                <Typography key={`${drink.name}-${drink.id}-${drink.key}-${producto.idArt}-${producto.key}-${index}`} className="detalle2Orden">
                                                                    {drink.name && `${drink.name},`}
                                                                </Typography>
                                                            ))}
                                                            {producto.extras.map((extra, index) => (
                                                                <Typography key={`${extra.id}-${extra.key}-${producto.idArt}-${producto.key}-${index}`} className="detalle2Orden">
                                                                    {extra.name && `${extra.name},`}
                                                                </Typography>
                                                            ))}
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Stack>

                                            <Typography className="detalleOrden" style={{ minWidth: "60px" }}>
                                                ₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([producto.subTotal])}
                                            </Typography>
                                            <Button onClick={() => deleteArticule(producto.idArt)} style={{ minWidth: "59px" }} className="btndelete">
                                                <img src="/content/img/SC/delete.svg" />
                                            </Button>
                                        </Stack>
                                    ))}
                                </Stack>
                                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                    <Typography className="totalOrden">TOTAL</Typography>
                                    <Typography className="totalOrden">₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([total])}</Typography>
                                </Stack>
                                <Button className="btnPay" onClick={handleItemClicked}>
                                    Pagar
                                </Button>
                            </Stack>
                        </Stack>
                        <Stack justifyContent="center" alignItems="center" style={{ paddingTop: "2%", border: isMobile || isTablet ? "1px solid rgba(255, 255, 255, 0.20)" : "none", borderRadius: "10px", paddingBottom: isMobile || isTablet ? "5%" : "0%" }}>
                            <Accordion style={{ display: isNotEmpty ? (isMobile || isTablet ? "block" : "none") : "none", width: "100%", marginLeft: "0%", backgroundColor: "transparent", borderBottom: "1px solid rgba(255, 255, 255, 0.17)" }} defaultExpanded>
                                <AccordionSummary expandIcon={<ExpandMoreIcon className="flecha" />} aria-controls="panel2-content" id="panel2-header" className="tituloacordeon flecha">
                                    <Typography style={{ color: "#fff", textAlign: "center", fontFamily: "FuturaP-Bold", fontSize: "15px" }} className="tituloacordeon">
                                        DETALLES DE ORDEN
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction="row" spacing={1}>
                                        <Stack style={{ display: isMobile || isTablet ? "block" : "none", minWidth: "40%" }}>
                                            <Typography className="tituloOrden">CONFITERÍA</Typography>

                                            <Stack spacing={2} style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.20)", paddingBottom: "5%" }}>
                                                {step4.articules.map((producto, index) => (
                                                    // Agregar key único aquí
                                                    <Stack key={producto.idArt + producto.key + index} direction="row" justifyContent="space-between">
                                                        <Stack>
                                                            <Stack direction="row" spacing={1}>
                                                                <Typography className="detalleOrden">{producto.cantProduct}</Typography>
                                                                <Stack>
                                                                    <Typography className="detalleOrden">{producto.nombre}</Typography>
                                                                    <Stack direction="column" spacing={0.5}>
                                                                        <Typography className="detalle2Orden">{producto.size.name && `${producto.size.name} ,`}</Typography>
                                                                        <Typography className="detalle2Orden">{producto.flavors.name && `${producto.flavors.name} ,`} </Typography>
                                                                        {producto.drinks.map((drink, drinkIndex) => (
                                                                            // Agregar key único aquí
                                                                            <Typography key={`${drink.name}-${drink.id}-${drink.key}-${producto.idArt}-${producto.key}-${index}-${drinkIndex}`} className="detalle2Orden">
                                                                                {drink.name && `${drink.name},`}
                                                                            </Typography>
                                                                        ))}
                                                                        {producto.extras.map((extra, extraIndex) => (
                                                                            // Agregar key único aquí
                                                                            <Typography key={`${extra.id}-${extra.key}-${producto.idArt}-${producto.key}-${index}-${extraIndex}`} className="detalle2Orden">
                                                                                {extra.name && `${extra.name},`}
                                                                            </Typography>
                                                                        ))}
                                                                    </Stack>
                                                                </Stack>
                                                            </Stack>
                                                        </Stack>

                                                        <Typography className="detalleOrden" style={{ minWidth: "40px" }}>
                                                            ₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([producto.subTotal])}
                                                        </Typography>
                                                        <Button onClick={() => deleteArticule(producto.idArt)} style={{ minWidth: "54px" }}>
                                                            <img src="/content/img/SC/delete.svg" width={"20%"} />
                                                        </Button>
                                                    </Stack>
                                                ))}
                                            </Stack>
                                            <Stack direction="row" justifyContent="flex-start" spacing={2}>
                                                <Typography className="totalOrden">TOTAL</Typography>
                                                <Typography className="totalOrden">₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([total])}</Typography>
                                            </Stack>
                                        </Stack>
                                        <Button className="btnNextCONFI" onClick={handleItemClicked}>
                                            siguiente
                                        </Button>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            <FoodAccordion expanded={expanded} handleChange={handleOpenAccordion} />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};

export default Confiteria;
