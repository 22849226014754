import { Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import "../../styles/legales.css";
import { FeeServiceAmount } from "../../../api";

const Legales = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil

    const handleDownload = () => {
        // Ruta al archivo PDF
        const pdfUrl = '/content/img/SC/TÉRMINOS Y CONDICIONES STUDIO CINEMAS.pdf';

        // Crear un enlace temporal
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'terminos_y_condicionesSC.pdf';

        // Hacer clic en el enlace
        document.body.appendChild(link);
        link.click();

        // Limpiar después de la descarga
        document.body.removeChild(link);
    };

    return (
        <Stack
            justifyContent="center"
            alignItems={isMobile || isTablet? "center" : "flex-start"}
            style={{
                marginTop: isMobile ? "15%" :  isTablet? "7%" : "3%",
                backgroundImage: "url(/content/img/SC/fondo-CP.svg)",
                backgroundSize: "cover",
                padding: isMobile ? "30px" : "50px",
            }}
        >
            <Typography className="tituloNosotros">Legales</Typography>
            <Stack
                spacing={isMobile || isTablet? 4 : 20}
                direction={isMobile || isTablet? "column" : "row"}
                style={{
                    border: "1px solid rgba(255, 255, 255, 0.20)",
                    backgroundColor: "rgba(0, 0, 0, 0.24)",
                    borderRadius: "20px",
                    padding: isMobile || isTablet? "2%" : "3%",
                    paddingTop: "2%",
                    paddingLeft: "5%",
                }}
            >
                <Stack>
                    <Typography className="subtituloLegales">TÉRMINOS Y CONDICIONES</Typography>
                </Stack>
                <Stack className="contenedorDoc">
                    <Stack className="scrollable-content" style={{ paddingRight: "10%" }}>
                        <Typography className="boldDoc">Conoce más sobre nosotros</Typography>
                        <Typography className="normalDoc">
                            Somos un cine independiente que te brinda una experiencia distinta y acogedora. Nuestro diseño está pensado para el máximo disfrute de nuestros consumidores. Somos desarrollado y operado por Rocca Portafolio Comercial. Razón Social: Studio Cinemas Entretenimiento Sociedad Anónima Cédula Jurídica: 3-101-688870 Correo: info@studiocinemascr.com Teléfono: +(506) 4035 3105 Dirección Exacta: City Place Santa Ana, Edificio A, 2 piso. Santa Ana, San José, Costa Rica.
                        </Typography>
                        <Typography className="boldDoc">Términos y condiciones</Typography>
                        <Typography className="normalDoc">Studio Cinemas y el sitio web www.studiocinemascr.com es propiedad de Rocca Portafolio Comercial. Cédula Jurídica 3-101-688870 con domicilio en Costa Rica, Santa Ana, Pozos. Edificio A, City Place, Teléfono + (506) 40-35-31-05, correo info@studiocinemascr.com</Typography>
                        <Typography className="boldDoc">Políticas de privacidad, términos y condiciones de uso</Typography>
                        <Typography className="normalDoc">El uso del sitio www.studiocinemascr.com está regido por las políticas de privacidad, términos y condiciones de uso descritos en este apartado; al acceder a este sitio web, el usuario acepta incondicionalmente y a plenitud dichas cláusulas al hacer uso de los servicios en línea proveídos por Studio Cinemas</Typography>
                        <Typography className="normalDoc">El contenido de este sitio sólo debe ser usado como recurso de promoción e información y, cuando corresponda, compra de bienes y servicios que comercializa Studio Cinemas. Cualquier uso no autorizado del contenido de este sitio se encuentra estrictamente prohibido sin el previo consentimiento, por escrito, de parte de Studio Cinemas.</Typography>
                        <Typography className="normalDoc">Todas las transacciones realizadas a través de este sitio web incluyen la aceptación de las políticas de privacidad, términos y condiciones detallados a continuación</Typography>

                        <Typography className="boldDoc">Plazo para realizar las compras</Typography>
                        <Typography className="normalDoc">
                            El cliente podrá adquirir todas las entradas que necesite a través del sitio web www.studiocinemascr.com, hasta 10 minutos posteriores a la hora de inicio de la película, siempre y cuando haya espacio disponible en la sala. El cliente acepta que conoce el horario de inicio de la tanda seleccionada y es su responsabilidad presentarse puntualmente a la función; por tanto, Studio Cinemas no se responsabiliza por llegadas tardías ni hace cambios de entradas o devoluciones
                            de dinero por esta razón.
                        </Typography>

                        <Typography className="boldDoc">Tarifas preferenciales y descuentos</Typography>
                        <Typography className="normalDoc">
                            Los descuentos para personas adultas mayores, personas menores de 12 años (personas aplican descuento de niños), personas con capacidades distintas u otros se podrán aplicar en la compra por Internet, pero su efectividad queda sujeta a previa verificación por parte del colaborador(a) de Studio Cinemas a cargo de la impresión del tiquete e ingreso a la sala en el punto de venta. Cuando se verifique que el descuento no corresponde al tipo de cliente, se deberá cancelar
                            la diferencia -si existiera-. En caso de que el cliente rehúse cancelar la diferencia, no se permitirá su ingreso a la tanda seleccionada ni se realizará devolución de dinero (ver apartado “Devoluciones y reembolsos”).
                        </Typography>

                        <Typography className="boldDoc">Cargos por servicio</Typography>
                        <Typography className="normalDoc">Cada boleto comprado a través de la página web está sujeto a un cargo por servicio adicional al precio del boleto de ¢{FeeServiceAmount} cada uno, cargo que se está aceptando pagar al momento de aceptar estos términos y condiciones.</Typography>

                        <Typography className="boldDoc">Aceptación de orden de compra</Typography>
                        <Typography className="normalDoc">Studio Cinemas se reserva el derecho de aceptar o declinar una orden de compra, aún después de que el cliente ha recibido confirmación de esta.</Typography>

                        <Typography className="boldDoc">Condiciones de entrega de la entrada</Typography>
                        <Typography className="normalDoc">
                            En los casos de compra y/o reserva de entradas a través del sitio web, el cliente deberá presentarse en la sucursal de Studio Cinemas en donde se vaya a llevar a cabo la función seleccionada. El cliente deberá presentar el documento de confirmación de orden de compra que el sistema genera y envía al correo electrónico indicado ya sea en formato impreso o digital., si este correo de confirmación de sus entradas tiene todo en regla puede ingresar mostrando su
                            comprobante al colaborador de salas y no debe gestionar el retiro de ellas, si por el contrario presenta algún inconveniente puede hacer su consulta en los medios de comunicación de Studio Cinema o hacer su retiro en forma física en el área de boletería.
                        </Typography>

                        <Typography className="normalDoc">Studio Cinemas no se hace responsable en caso de que el cliente extravíe el documento de confirmación o, bien, no reciba el correo electrónico de confirmación de compra por causas ajenas a nuestro control.</Typography>

                        <Typography className="boldDoc">Información para realizar un trámite</Typography>
                        <Typography className="normalDoc">Para cualquier información, reclamo, sugerencia puede comunicarse a nuestros siguientes medios:</Typography>
                        <Typography className="normalDoc">1. Personalmente en el área de boletería</Typography>

                        <Typography className="normalDoc">2. Número de teléfono 40-35-31-05.</Typography>
                        <Typography className="normalDoc">3. Correo electrónico info@studiocinemascr.com</Typography>
                        <Typography className="normalDoc">Con horario de atención de lunes a viernes de 12:30 MD a 10:00PM</Typography>

                        <Typography className="boldDoc">Derecho de Retracto</Typography>
                        <Typography className="normalDoc">En caso de que el consumidor decida realizar el derecho de retracto o desistimiento de su compra debe realizarlo en el plazo de 8 días hábiles a partir de perfeccionada la venta, enviando su aviso de recisión o desistimiento a los medios siguientes:</Typography>

                        <Typography className="normalDoc">1. Personalmente en el área de boletería.</Typography>

                        <Typography className="normalDoc">2. Número de teléfono 40-35-31-05.</Typography>
                        <Typography className="normalDoc">3. Correo electrónico info@studiocinemascr.com.</Typography>
                        <Typography className="normalDoc">Para estos efectos deberá enviar un escrito, ya sea al domicilio del vendedor, número de fax o correo electrónico establecido en la papelería o el contrato, dejando constancia del envío o entrega.</Typography>
                        <Typography className="normalDoc">En el caso de que el servicio ya se hubieses prestado, es decir, que la función ya hubiese pasado y el aviso de recisión o desistimiento sea posterior a la prestación de servicios, no aplica el derecho de retracto.</Typography>
                        <Typography className="normalDoc">Advertencia o limitación al derecho de retracto: Bienes consumibles.</Typography>

                        <Typography className="boldDoc">Procesos para hacer efectivo su Rembolso</Typography>
                        <Typography className="normalDoc">Studio Cinemas solicitará al cliente llenar un formulario en donde se le solicitará (cédula, nombre completo, número de cuenta, teléfono y correo) para hacer el reintegro respectivo.</Typography>
                        <Typography className="normalDoc">El formulario se les pasara por correo, o pueden llenarlo personalmente en el local.</Typography>
                        <Typography className="normalDoc">
                            Las transacciones efectuadas en el sitio web se realizan exclusivamente con tarjeta de crédito o débito, por tanto, los reembolsos se harán efectivos a través del mismo medio que se efectuó la compra. Los usuarios que requieran hacer efectivo un reembolso deben comunicarse a las oficinas A partir de que se realiza la solicitud de reembolso por parte del cliente, Studio Cinemas procederá a analizar el caso y determinar si los montos se encuentran acreditados a su
                            favor. De ser así, se realizará el reembolso en el plazo máximo de 8 días naturales contando desde el día siguiente realizada su compra.
                        </Typography>
                        <Typography className="normalDoc">Cuando se proceda a realizar una devolución de dinero, se reembolsará el valor de compra la entrada o (as) en su valor total.</Typography>

                        <Typography className="boldDoc">Aplicaciones en temas de reembolsos</Typography>
                        <Typography className="normalDoc">Studio Cinemas aplicará su debido proceso, aplicándose los siguientes puntos:</Typography>
                        <Typography className="normalDoc">1. Por inconvenientes fuera de nuestro control (luz; agua)</Typography>
                        <Typography className="normalDoc">2. Problemas técnicos que no se puedan solucionar en el momento de la función.</Typography>
                        <Typography className="normalDoc">3. Cambio en tema de filmación (fecha cambio de película).</Typography>
                        <Typography className="normalDoc">4. Problemas técnicos en página web.</Typography>
                        <Typography className="normalDoc">Studio Cinemas informará oportunamente por medio de su sitio web y/o redes sociales oficiales cambios en las tandas o funciones. Es responsabilidad del cliente verificar cualquier cambio o cancelación de última hora que pudiera presentarse.</Typography>
                        <Typography className="normalDoc">Si hay un aplazamiento de fecha o cambio de hora de la función o evento, las entradas respectivas seguirán teniendo validez para la fecha establecida a futuro. En este caso, no será necesario emitir nuevas entradas. Si un evento es anulado sin que Studio Cinemas prevea otra fecha a futuro, el cliente podrá solicitar que la entrada le sea cambiada por alguna otra película o, en su defecto, solicitar la devolución del dinero.</Typography>

                        <Typography className="boldDoc">Excepciones para realizar un rembolso</Typography>
                        <Typography className="normalDoc">1. Compras con una fecha y función vencida.</Typography>
                        <Typography className="normalDoc">2. No se realizarán sustituciones, devoluciones ni reembolsos por entradas extraviadas o dañadas por el cliente o, bien, robadas por terceros.</Typography>
                        <Typography className="normalDoc">3. Asimismo, no se realizan devoluciones en caso de que el usuario del sitio web haya elegido, por error, un producto que no era el deseado aplicable (error por fecha, asiento o película).</Typography>
                        <Typography className="normalDoc">4. Si el cliente no llega a la hora indicada, Studio Cinemas no se hace responsable por llegadas tardías de los clientes.</Typography>

                        <Typography className="boldDoc">Jurisdicción vigente</Typography>
                        <Typography className="normalDoc">Todas las controversias o diferencias que pudieren derivarse de este reglamento, de su ejecución, liquidación o interpretación, serán resueltas por medio de la legislación costarricense y deberán solventarse en primer término por las partes.</Typography>

                        <Typography className="boldDoc">Errores involuntarios</Typography>
                        <Typography className="normalDoc">
                            En caso de que una entrada tenga un precio o información incorrecta debido a un error tipográfico o de digitación, Studio Cinemas Costa Rica tendrá el derecho de rechazar o anular cualquier orden puesta con el precio incorrecto. Studio Cinemas tendrá el derecho de rechazar o de cancelar cualquier orden confirmada o ya debitada en su tarjeta. Si su compra ya ha sido cobrada y sucesivamente anulada, Studio Cinemas Costa Rica acreditará a la cuenta de su tarjeta el monto
                            correspondiente bajo el procedimiento de reembolso establecido en este documento.
                        </Typography>

                        <Typography className="boldDoc">Vínculos externos</Typography>
                        <Typography className="normalDoc">Studio Cinemas no asume responsabilidad alguna con los contenidos y servicios comerciales o de otra índole, sitios web que se puedan enlazar electrónicamente directa o indirectamente a través del sitio web www.studiocinemascr.com. El cliente reconoce y acepta que Studio Cinemas Costa Rica no es responsable, de ninguna forma, por los contenidos de otros sitios vinculados, ni de los daños que estos pudieran causar.</Typography>

                        <Typography className="boldDoc">Servicio al cliente y apoyo técnico</Typography>
                        <Typography className="normalDoc">Studio Cinemas brindará el servicio y apoyo técnico necesario a los usuarios de su plataforma digital www.studiocinemascr.com a través de personal debidamente capacitado para tales fines. Todas las solicitudes de servicio y apoyo deben realizarse al Departamento de Servicio al Cliente por medio de la central telefónica +506 4035 - 5000 o, bien, a través del correo electrónico info@studiocinemascr.com.</Typography>

                        <Typography className="boldDoc">Invalidez de cláusula</Typography>
                        <Typography className="normalDoc">La ilegalidad, nulidad, invalidez y/o ineficacia de una o varias cláusulas de los presentes términos y condiciones no afectará la legalidad, validez y/o eficacia de las cláusulas restantes.</Typography>
                        <Typography className="normalDoc">Studio Cinemas Costa Rica no será responsable en caso de no poder cumplir con cualquiera de las obligaciones contenidas en este documento debido, directamente o indirectamente, al fallo de cualquier equipo, sistema de autorización, sistema de comunicación o cualquier evento fortuito, acto de fuerza mayor o cualquier otro evento fuera del control de Studio Cinemas.</Typography>
                        <Typography className="normalDoc">Así mismo, Studio Cinemas no se responsabilizan por daños o perjuicios derivados del acceso, uso o mala utilización de los contenidos de este sitio.</Typography>

                        <Typography className="boldDoc">Consentimiento del cliente</Typography>
                        <Typography className="normalDoc">El cliente manifiesta haber leído y entendido amplia y libremente todas y cada una de las cláusulas de este documento, por lo que acepta ajustarse a sus términos, sin excepción.</Typography>

                        <Typography className="boldDoc">Protección de datos y seguridad en medios de pago</Typography>
                        <Typography className="normalDoc">
                            Studio Cinemas Costa Rica en el cumplimiento a la ley de protección de la persona frente a tratamiento de sus datos personales N # 8968 y el artículo 263, se comprometen a no revelar ningún tipo de información a la cual puedan tener acceso por las compras realizadas por el cliente en sus plataformas digital es, incluyendo datos sobre tarjetas de débito o crédito, información personal y todos aquellos datos accedidos en virtud del uso que el cliente le dé a esta
                            plataforma, el sistema por ninguna razón guardar estos datos para seguridad de sus clientes. En el sitio web NO se almacena ningún dato de usuario y tampoco ningún dato de compra. Esta información se utiliza para:
                        </Typography>

                        <Typography className="normalDoc">1. Proceder con la compra a través de la pasarela de pago de BAC usando la guía de implementación adjunta</Typography>
                        <Typography className="normalDoc">2. Enviar los datos de compra a AB Pro para que generen factura, reserva, etc.</Typography>
                        <Typography className="normalDoc">3. Enviar al cliente su comprobante por correo. Una vez enviado, no se guarda ningún dato</Typography>
                        <Typography className="normalDoc">
                            La empresa encargada de recopilar y gestionar los datos personales SSL/TLS (información en seguridad de datos) que se deriven de las operaciones realizadas en el sitio web www.studiocinemascr.com es Studio Cinemas con domicilio en City Place Santa Ana, 200 metros noroeste de la Cruz Roja, Santa Ana, San José, Costa Rica. El tratamiento de datos personales se hará según los términos indicados en esta política de privacidad y acorde con la legislación costarricense
                            vigente. [JBS7] [AS8]
                        </Typography>
                        <Typography className="normalDoc">En caso de que un cliente desee hacer uso de sus derechos de acceso, rectificación, u oposición al tratamiento de dichos datos personales, debe comunicarlo mediante el correo info@studiocinemascr.com.</Typography>

                        <Typography className="boldDoc">Seguridad</Typography>
                        <Typography className="normalDoc">
                            Studio cinemas utilizamos para su seguridad, utilizamos SSL/ TLS este protocolo es utilizado a través de SSL estos son criptográficos de amplio uso esto generalmente son para una buena comunicación entre clientes e internet. Estamos certificados con ellos SSL para asegurar que los datos del cliente estén protegidos contra la interceptación y la manipulación de parte externos no autorizados. Este protocolo tiene un cifrado de extremo a extremo.
                        </Typography>
                        <Typography className="normalDoc">Si desea más información sobre nuestro protocolo de seguridad puede conocer nuestro sistema. https://www.ssllabs.com/ssltest/analyze.html?d=studiocinemascr.com</Typography>

                        <Typography className="boldDoc">Método de Redirección seguridad medio de pago</Typography>
                        <Typography className="normalDoc">El proceso de autorización se realiza mediante la invocación del URL: https://credomatic.compassmerchantsolutions.com/api/transact.php. El siguiente diagrama ejemplifica la arquitectura que debe seguir la integración:</Typography>
                        <Typography className="normalDoc">1. El explorador web del TH inicial el proceso de pago.</Typography>
                        <Typography className="normalDoc">2. El sitio web del comercio afiliado devuelve una página de pago al cliente con el formulario apuntando a la URL de pago de Credomatic.</Typography>
                        <Typography className="normalDoc">3. El explorador web del TH envía el formulario de pago hacia Credomatic.</Typography>
                        <Typography className="normalDoc">4. Credomatic procesa la transacción y redirige el explorador web del TH hacia el sitio web del comercio afiliado, junto con el resultado de la transacción mediante una petición GET.</Typography>
                        <Typography className="normalDoc">5. Debido a la redirección, el explorador web del TH solicita al sitio web comercio afiliado una página con el resultado de la transacción.</Typography>
                        <Typography className="normalDoc">6. El sitio web del afiliado respectivo mensaje de aprobada.</Typography>
                        <Typography className="normalDoc">Al utilizar este método de autorización, el titular de la tarjeta enviará los datos de pago directamente a Credomatic, pero la respuesta debe ser transmitida de nuevo al Sitio Web del Comercio Afiliado.</Typography>
                        <Typography className="normalDoc">
                            Una vez que el titular de la tarjeta solicita la página de pago (paso 1), el comerciante debe mostrar un formulario al titular de la tarjeta (paso 2) que contiene y recoge los datos de pago con el apropiado nombre de variables (como se define más adelante). La variable adicional redirector (como se define a continuación) indicarán al Sistema de Pagos, donde debe ser redirigido el titular de la tarjeta y esencialmente la página web que analizará los resultados de la
                            transacción. Esto normalmente se pasa como un campo oculto. El Formulario de pago debe estar dirigido al URL de Credomatic.
                        </Typography>
                        <Typography className="normalDoc">Cuando el titular de la tarjeta envía el formulario, los datos se envían al Sistema de pagos de Credomatic (Paso 3). El Sistema de Pagos procesa la transacción y redirige el explorador del titular de la tarjeta a la dirección suministrada en la variable redirect (paso 4).</Typography>
                        <Typography className="normalDoc">En el paso 5, el titular solicita la URL enviada anteriormente en la variable redirect y los resultados de la operación se incluyen en la petición GET. El comerciante debe crear un script que analiza las variables de respuesta, actualiza su base de datos / sistema de pedido debidamente, y muestra el recibo o rechazo de la transacción al titular de la tarjeta.</Typography>
                        <Typography className="boldDoc">Derechos de propiedad intelectual</Typography>
                        <Typography className="normalDoc">El diseño de este sitio y su contenido, incluyendo sus gráficos, fotografías, textos, información, así como el programa de cómputo asociado, son derechos reservados y exclusivos de Studio Cinemas S.A con domicilio en City Place Santa Ana, 200 metros noroeste de la Cruz Roja, Santa Ana, San José, Costa Rica.</Typography>

                        <Typography className="boldDoc">Cambios en los términos y condiciones</Typography>
                        <Typography className="normalDoc">Studio Cinemas Costa Rica y sus empresas afiliadas se reservan el derecho de modificar la información contenida en este sitio web, así como en las políticas de privacidad, términos y condiciones de uso cuando lo estime conveniente, sin aviso previo.</Typography>
                    </Stack>
                    <Button className="btnDownloadTC" onClick={handleDownload} style={{ display: isMobile || isTablet? "none" : "flex" }}>
                        <img src="/content/img/SC/pdf.svg" />
                        <Stack justifyContent="center" alignItems="flex-start">
                            <Typography className="btnDownloadTCBold">Descargar</Typography>
                            <Typography className="btnDownloadTCtext">Términos y Condiciones</Typography>
                        </Stack>
                    </Button>
                </Stack>
            </Stack>
            <Button className="btnDownloadTC" onClick={handleDownload} style={{ display: isMobile || isTablet? "flex" : "none" }}>
                <img src="/content/img/SC/pdf.svg" width={"20%"} />
                <Stack justifyContent="center" alignItems="flex-start">
                    <Typography className="btnDownloadTCBold">Descargar</Typography>
                    <Typography className="btnDownloadTCtext">Términos y Condiciones</Typography>
                </Stack>
            </Button>
        </Stack>
    );
};

export default Legales;
