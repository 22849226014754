import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const MessageDialogConfiteria = ({ url = "", title = "opinion asas con exito", icon = "/content/img/SC/check.svg", description = "Tu opinión es muy importante para nosotros y nos ayuda a seguir ofreciendo el mejor servicio posible.", download = false, width = "20%", alert = false }) => {
    const [open, setOpen] = React.useState(false); // Initialize with false
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil

    const abrir = true; // Change this to false to control the dialog opening condition

    React.useEffect(() => {
        if (abrir) {
            setOpen(true);
            if (!download) {
                // Close the dialog after 5 seconds if download is false
                const timer = setTimeout(() => {
                    setOpen(false);
                }, 5000);

                // Cleanup timer if component unmounts before the timer finishes
                return () => clearTimeout(timer);
            }
        }
    }, [abrir, download]);

    const handleClose = () => {
        if (!download) {
            setOpen(false);
        }
    };

    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/documentos-pdf?rev=" + url);
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    sx: {
                        backgroundImage: "url(/content/img/SC/fondo-CP.svg)",
                        backgroundSize: "cover",
                        color: "white",
                        width: isMobile || isTablet ? "80%" : "25%",
                        height: isMobile || isTablet ? "auto" : "35%",
                        borderRadius: "20px",
                        border: "3px solid rgba(255, 255, 255, 0.15)",
                        alignItems: "center",
                        paddingTop: "2%",
                        overflow: "visible",
                    },
                }}
                onBackdropClick={download ? undefined : handleClose} // Prevent closing on backdrop click if download is true
            >
                <img src={icon} width={width} style={{ marginTop: alert ? "-20%" : "0%" }} />
                <DialogTitle
                    id="responsive-dialog-title"
                    sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontFamily: "FuturaP-ExtraBold",
                        fontSize: isMobile ? "16px" : "28px",
                        width: alert ? "80%" : "60%",
                        textTransform: "uppercase",
                    }}
                >
                    {title}
                </DialogTitle>
                <DialogContent style={{ overflow: "hidden" }}>
                    <DialogContentText
                        sx={{
                            color: "#fff",
                            textAlign: "center",
                            fontFamily: "FuturaP-Medium",
                            fontSize: isMobile ? "11px" : "14px",
                            textTransform: "uppercase",
                            overflow: "hidden",
                        }}
                    >
                        {description}
                    </DialogContentText>
                </DialogContent>
                {download && (
                    <DialogActions>
                        <Button
                            onClick={handleClick}
                            style={{
                                width: isMobile ? "90%" : "80%",
                                backgroundColor: "#67BF47",
                                color: "#fff",
                                fontFamily: "FuturaP-Medium",
                                textAlign: "left",
                                fontSize: isMobile ? "9px" : "12px",
                                columnGap: "6%",
                                height: isMobile ? "40px" : "70%",
                                padding: "6%",
                            }}
                        >
                            <img src="/content/img/SC/download.svg" /> DESCARGAR COMPROBANTE
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </React.Fragment>
    );
};

export default MessageDialogConfiteria;
