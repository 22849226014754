import React, { useState, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled, useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Divider from "@mui/material/Divider";
import { Button, Stack, useMediaQuery } from "@mui/material";
import "../../styles/Food.css"; // Estilos específicos para el componente

import { useDispatch, useSelector } from "react-redux";
import { AddArticule, selectDrinks, selectExtras, updatedataProductsSizes, updatedataProductsflavors, updatefromselectallFlavor, updatefromselectallSize, updatehandleDecreaseQuantity, updatehandleIncreaseQuantity } from "../../../store/checkout/checkoutSlice";

// Estilo personalizado para el componente de Paper
// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: "center",
//     color: theme.palette.text.secondary,
// }));

export default function FoodAccordion({ expanded, handleChange = () => {} }) {
    const { step4 } = useSelector((state) => state.checkout);

    // const [expanded, setExpanded] = useState(false);
    const [selections, setSelections] = useState({});
    const [selectionsFlavors, setSelectionsFlavors] = useState({});
    const [selectedDrinks, setSelectedDrinks] = useState({});
    const [selectedExtras, setSelectedExtras] = useState({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil
    const isSurface = useMediaQuery("(max-width:1370px)"); // Verifica si la pantalla es móvil

    // const handleChange = (panelKey) => (event, isExpanded) => {
    //     setExpanded(isExpanded ? panelKey : false);
    // };

    const handleVariantChange = (productId, variantId, productName, categoryId) => {
        setSelections((prevSelections) => ({
            ...prevSelections,
            [productId]: variantId,
        }));

        if (variantId === null) {
            alert("Debe seleccionar un tamaño para : " + productName);
            setTimeout(() => {
                dispatch(updatefromselectallSize({ categoryId, productId }));
            }, 0);
        }

        if (variantId === undefined) {
            setTimeout(() => {
                dispatch(updatefromselectallSize({ categoryId, productId }));
            }, 0);
        }
    };

    const handleVariantChangeFlavor = (productId, variantId, productName, categoryId) => {
        setSelectionsFlavors((prevSelections) => ({
            ...prevSelections,
            [productId]: variantId,
        }));

        if (variantId === null) {
            alert("Debe seleccionar un sabor para : " + productName);
            setTimeout(() => {
                dispatch(updatefromselectallFlavor({ categoryId, productId }));
            }, 0);
        }
        if (variantId === undefined) {
            setTimeout(() => {
                dispatch(updatefromselectallFlavor({ categoryId, productId }));
            }, 0);
        }
    };

    const dispatch = useDispatch();

    const handleSizeClickSizes = (categoryId, productId, sizeId, sizeName, sizePrice, variantId) => {
        if (variantId === null) {
            alert("Debe seleccionar un tamaño para : " + productName);
        }
        dispatch(updatedataProductsSizes({ categoryId, productId, sizeId, sizeName, sizePrice }));
    };

    const handleSizeClickFlavors = (categoryId, productId, flavorsId, flavorsName, flavorsPrice) => {
        dispatch(updatedataProductsflavors({ categoryId, productId, flavorsId, flavorsName, flavorsPrice }));
    };

    const handleDecreaseQuantity = (categoryId, productId) => {
        dispatch(updatehandleDecreaseQuantity({ categoryId, productId }));
    };

    const handleIncreaseQuantity = (categoryId, productId) => {
        dispatch(updatehandleIncreaseQuantity({ categoryId, productId }));
    };

    const drinkRefs = useRef({});
    const extraRefs = useRef({});

    const handleAddArticule = (categoryId, productId) => {
        dispatch(AddArticule({ categoryId, productId }));

        // Limpiar todos los selects de bebidas para este producto
        if (drinkRefs.current[`${categoryId}-${productId}`]) {
            Object.values(drinkRefs.current[`${categoryId}-${productId}`]).forEach((select) => {
                if (select) select.value = "0";
            });
        }

        // Limpiar todos los selects de extras para este producto
        if (extraRefs.current[`${categoryId}-${productId}`]) {
            Object.values(extraRefs.current[`${categoryId}-${productId}`]).forEach((select) => {
                if (select) select.value = "0";
            });
        }

        // Resetear el estado de los selects para este producto
        setSelectedDrinks((prev) => ({ ...prev, [`${categoryId}-${productId}`]: {} }));
        setSelectedExtras((prev) => ({ ...prev, [`${categoryId}-${productId}`]: {} }));
    };

    // Actualiza la función handleChangeDrinks:
    const handleChangeDrinks = (event, categoryId, productId, optionIndex) => {
        const selectedOptionId = event.target.value;
        setSelectedDrinks((prevSelectedDrinks) => {
            const updatedSelections = { ...prevSelectedDrinks };
            if (!updatedSelections[`${categoryId}-${productId}`]) {
                updatedSelections[`${categoryId}-${productId}`] = {};
            }
            updatedSelections[`${categoryId}-${productId}`][optionIndex] = selectedOptionId;

            dispatch(
                selectDrinks({
                    categoryId,
                    productId,
                    combination: Object.values(updatedSelections[`${categoryId}-${productId}`]),
                }),
            );
            return updatedSelections;
        });
    };

    const handleChangeExtras = (event, categoryId, productId, optionIndex) => {
        const selectedOptionId = event.target.value;
        setSelectedExtras((prevSelectedExtras) => {
            const updatedSelections = { ...prevSelectedExtras };
            if (!updatedSelections[`${categoryId}-${productId}`]) {
                updatedSelections[`${categoryId}-${productId}`] = {};
            }
            updatedSelections[`${categoryId}-${productId}`][optionIndex] = selectedOptionId;

            dispatch(
                selectExtras({
                    categoryId,
                    productId,
                    combination: Object.values(updatedSelections[`${categoryId}-${productId}`]),
                }),
            );
            return updatedSelections;
        });
    };

    const setRef = (refs, categoryId, productId, optionIndex, el) => {
        if (!refs.current[`${categoryId}-${productId}`]) {
            refs.current[`${categoryId}-${productId}`] = {};
        }
        refs.current[`${categoryId}-${productId}`][optionIndex] = el;
    };
    return (
        <>
            {step4.dataProducts.length === 0 ? (
                <Typography variant="h5" style={{ textAlign: "center", marginTop: "20px", color: "#fff" }}>
                    En este momento no hay productos disponibles
                </Typography>
            ) : (
                step4.dataProducts
                    .filter((category) => category.status === true)
                    .map((category) => (
                        <Accordion key={category.key} expanded={expanded === category.id} onChange={handleChange(category.id)} style={{ width: isMobile || isTablet ? "85%" : "95%", marginTop: "1%" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`${category.id}bh-content`}
                                id={`${category.id}bh-header`}
                                className="accordionSummaryFood"
                                style={{
                                    backgroundImage: `url("${category.image}")`,
                                    height: isMobile || isTablet ? "86px" : "149px",
                                    backgroundSize: "cover",
                                }}
                            >
                                <Typography
                                    sx={{
                                        width: isMobile ? "114.5%" : isTablet ? "106.7%" : isSurface ? "106%" : "103.6%",
                                        flexShrink: 0,
                                        fontSize: isMobile ? "16px" : "32px",
                                        color: "#fff",
                                        fontFamily: "FuturaP-ExtraBold",
                                        textTransform: "uppercase",
                                        backgroundColor: "rgba(0, 0, 0, 0.58)",
                                        marginTop: isMobile ? "30%" : isTablet? "7%" : isSurface ? "16%" : "9.7%",
                                        marginLeft: isMobile ? "-7.5%" : isTablet ? "-3%" : isSurface ? "-2.7%" : "-1.6%",
                                        paddingLeft: "20px",
                                    }}
                                >
                                    {category.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{
                                    backgroundImage: `url("${category.imageBack}")`,
                                    backgroundSize: "cover",
                                }}
                            >
                                {category.products.length === 0 ? (
                                    <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                                        <img src="/content/img/SC/nohaypelicula.svg" style={{ width: "20%" }} />
                                        <Stack style={{ marginTop: "-3%" }}>
                                            <Typography style={{ textAlign: "center", fontSize: "32px", color: "#fff", fontFamily: "FuturaP-ExtraBold" }}>OOPS!</Typography>
                                            <Typography style={{ textAlign: "center", fontSize: "16px", color: "#fff", fontFamily: "FuturaP-Medium", textTransform: "uppercase" }}>
                                                Actualmente no contamos con
                                                <br />
                                                {category.title}
                                            </Typography>
                                        </Stack>

                                        <Typography style={{ textAlign: "center", fontSize: "13px", color: "#fff", fontFamily: "FuturaP-Medium", textTransform: "uppercase" }}>
                                            próximamente tendremos muchas
                                            <br />
                                            más sorpresas
                                        </Typography>
                                    </Stack>
                                ) : (
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ padding: "5px", rowGap: "15px" }}>
                                        {category.products
                                            .filter((product) => product.status === true)
                                            .map((product) => (
                                                <Grid item xs={isMobile || isTablet || isSurface ? 12 : 6} key={product.key + product.id}>
                                                    <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ width: isMobile ? "100%" : "74%", maxWidth: isMobile ? "100%" : "400px", minWidth: isMobile ? "100%" : "400px", marginLeft: isMobile ? "-10%" : "0%" }}>
                                                        <img src={product.image} alt={product.name} width={isMobile ? "90px" : "40%"} style={{ maxWidth: isMobile ? "90px" : "160px", minWidth: isMobile ? "90px" : "160px" }} />
                                                        <Stack>
                                                            <Typography
                                                                style={{
                                                                    color: "#fff",
                                                                    fontFamily: "FuturaP-Bold",
                                                                    textTransform: "uppercase",
                                                                    fontSize: isMobile ? "13px" : "20px",
                                                                    maxWidth: isMobile ? "90px" : "230px",
                                                                    minWidth: isMobile ? "90px" : "230px",
                                                                }}
                                                            >
                                                                {product.name}
                                                            </Typography>
                                                            {product.description.map((description, index) => (
                                                                <Typography
                                                                    key={index}
                                                                    style={{
                                                                        color: "#fff",
                                                                        fontFamily: "FuturaP-Light",
                                                                        textTransform: "uppercase",
                                                                        fontSize: isMobile ? "10px" : "16px",
                                                                    }}
                                                                >
                                                                    {description}
                                                                </Typography>
                                                            ))}
                                                            {product.variants.map((variant, index) => (
                                                                <div key={`${variant.id}-${index}`}>
                                                                    {variant.sizes && (
                                                                        <ToggleButtonGroup key={`${variant.id}-sizes`} color="primary" value={selections[product.id] || null} exclusive onChange={(event, newAlignment) => handleVariantChange(product.id, newAlignment, product.name, category.id)} aria-label={`${variant.id}-${product.id}`} style={{ marginTop: "5px" }}>
                                                                            {variant.sizes
                                                                                .filter((size) => size.status === true)
                                                                                .map((size) => (
                                                                                    <ToggleButton onClick={() => handleSizeClickSizes(category.id, product.id, size.id, size.name, size.price)} key={`${size.key}-${product.id}`} value={`${size.id}-${product.id}`} className="toggleVariant">
                                                                                        {size.name}
                                                                                    </ToggleButton>
                                                                                ))}
                                                                        </ToggleButtonGroup>
                                                                    )}

                                                                    {variant.flavors && (
                                                                        <ToggleButtonGroup key={`${variant.key}-flavors`} color="primary" value={selectionsFlavors[product.id] || null} exclusive onChange={(event, newAlignment) => handleVariantChangeFlavor(product.id, newAlignment, product.name, category.id)} aria-label={`${variant.id}-${product.id}`} style={{ marginTop: "5px" }}>
                                                                            {variant.flavors
                                                                                .filter((flavor) => flavor.status === true)
                                                                                .map((flavor) => (
                                                                                    <ToggleButton onClick={() => handleSizeClickFlavors(category.id, product.id, flavor.id, flavor.name, flavor.price)} key={`${flavor.key}-${product.id}`} value={`${flavor.id}-${product.id}`} className="toggleVariant">
                                                                                        {flavor.name}
                                                                                    </ToggleButton>
                                                                                ))}
                                                                        </ToggleButtonGroup>
                                                                    )}

                                                                    {variant.drinks.map((drink, drinkIndex) => (
                                                                        <div key={`${drinkIndex}`}>
                                                                            {[...Array(drink.quantity)].map((_, optionIndex) => (
                                                                                <div key={`${optionIndex}`}>
                                                                                    <select ref={(el) => setRef(drinkRefs, category.id, product.id, optionIndex, el)} onChange={(event) => handleChangeDrinks(event, category.id, product.id, optionIndex)} value={selectedDrinks[`${category.id}-${product.id}`]?.[optionIndex] || "0"} className="selectFood" name={`drink-select-${drinkIndex}-${optionIndex}`} id={`drink-select-${drinkIndex}-${optionIndex}`}>
                                                                                        <option key="unix" disabled value="0">
                                                                                            Escoger Bebida
                                                                                        </option>
                                                                                        {drink.options
                                                                                            .filter((option) => option.status === true)
                                                                                            .map((option) => (
                                                                                                <option key={option.id} value={option.id}>
                                                                                                    {option.name}
                                                                                                </option>
                                                                                            ))}
                                                                                    </select>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ))}

                                                                    {variant.extras.map((extra, extraIndex) => (
                                                                        <div key={extraIndex}>
                                                                            {[...Array(extra.quantity)].map((_, optionIndex) => (
                                                                                <div key={optionIndex}>
                                                                                    <select ref={(el) => setRef(extraRefs, category.id, product.id, optionIndex, el)} onChange={(event) => handleChangeExtras(event, category.id, product.id, optionIndex)} value={selectedExtras[`${category.id}-${product.id}`]?.[optionIndex] || "0"} className="selectFood" name={`extra-select-${extraIndex}-${optionIndex}`} id={`extra-select-${extraIndex}-${optionIndex}`}>
                                                                                        <option key="unix" disabled value="0">
                                                                                            Escoger Extra
                                                                                        </option>
                                                                                        {extra.options
                                                                                            .filter((option) => option.status)
                                                                                            .map((option) => (
                                                                                                <option key={option.id} value={option.id}>
                                                                                                    {option.name}
                                                                                                </option>
                                                                                            ))}
                                                                                    </select>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                            <Stack direction="row" justifyContent={isMobile ? "flex-start" : "space-between"} alignItems="center" style={{ marginTop: "2%" }} spacing={1}>
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    spacing={isMobile ? 0 : -1}
                                                                    style={{
                                                                        backgroundColor: "rgba(255, 255, 255, 0.58)",
                                                                        borderRadius: "5px",
                                                                        width: isMobile ? "83px" : "93px",
                                                                        height: "30px",
                                                                    }}
                                                                >
                                                                    <Button onClick={() => handleDecreaseQuantity(category.id, product.id)} variant="text" style={{ color: "#494949", fontSize: isMobile ? "15px" : "24px" }}>
                                                                        -
                                                                    </Button>
                                                                    <Typography
                                                                        style={{
                                                                            color: "#494949",
                                                                            fontFamily: "FuturaP-Bold",
                                                                            fontSize: isMobile ? "10px" : "16px",
                                                                        }}
                                                                    >
                                                                        {product.quantity}
                                                                    </Typography>
                                                                    <Button onClick={() => handleIncreaseQuantity(category.id, product.id)} variant="text" style={{ color: "#494949", fontSize: isMobile ? "15px" : "24px" }}>
                                                                        +
                                                                    </Button>
                                                                </Stack>
                                                                <Button
                                                                    onClick={(event, newAlignment) => {
                                                                        handleAddArticule(category.id, product.id);
                                                                        handleVariantChange(product.id, newAlignment, product.name, category.id);
                                                                        handleVariantChangeFlavor(product.id, newAlignment, product.name, category.id);
                                                                    }}
                                                                    style={{ color: "#fff", display: isMobile ? "block" : "none" }}
                                                                    className="btnAgregar"
                                                                >
                                                                    Agregar
                                                                </Button>
                                                                <Stack justifyContent="flex-start" alignItems="flex-start" spacing={-1} style={{ display: isMobile ? "none" : "block" }}>
                                                                    <Typography
                                                                        style={{
                                                                            color: "#fff",
                                                                            fontFamily: "FuturaP-Light",
                                                                            fontSize: "12px",
                                                                            textTransform: "uppercase",
                                                                        }}
                                                                    >
                                                                        precio:
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            color: "#fff",
                                                                            fontFamily: "FuturaP-Bold",
                                                                            fontSize: "20px",
                                                                            textTransform: "uppercase",
                                                                        }}
                                                                    >
                                                                        ₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([product.totalProducts])}
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack>
                                                            <Stack justifyContent="flex-start" alignItems="flex-start" spacing={-1} style={{ display: isMobile ? "block" : "none" }}>
                                                                <Typography
                                                                    style={{
                                                                        color: "#fff",
                                                                        fontFamily: "FuturaP-Light",
                                                                        fontSize: "12px",
                                                                        textTransform: "uppercase",
                                                                    }}
                                                                >
                                                                    precio:
                                                                </Typography>
                                                                <Typography
                                                                    style={{
                                                                        color: "#fff",
                                                                        fontFamily: "FuturaP-Bold",
                                                                        fontSize: "20px",
                                                                        textTransform: "uppercase",
                                                                    }}
                                                                >
                                                                    ₡{new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([product.totalProducts])}
                                                                </Typography>
                                                            </Stack>
                                                            <Button
                                                                onClick={(event, newAlignment) => {
                                                                    handleAddArticule(category.id, product.id);
                                                                    handleVariantChange(product.id, newAlignment, product.name, category.id);
                                                                    handleVariantChangeFlavor(product.id, newAlignment, product.name, category.id);
                                                                }}
                                                                style={{ color: "#fff", display: isMobile ? "none" : "block" }}
                                                                className="btnAgregar"
                                                            >
                                                                Agregar
                                                            </Button>
                                                        </Stack>
                                                    </Stack>
                                                    <Divider
                                                        style={{
                                                            display: isMobile ? "block" : "none",
                                                            backgroundColor: "rgba(255, 255, 255, 0.60)",
                                                            width: "111%",
                                                            marginLeft: "-5.5%",
                                                        }}
                                                    />
                                                </Grid>
                                            ))}
                                    </Grid>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    ))
            )}
        </>
    );
}
