import { configureStore } from "@reduxjs/toolkit";
import { homeSlice } from "./home";
import { checkoutSlice } from "./checkout";
import { authSlice } from "./auth/authSlice";

export const store = configureStore({
    reducer: {
        home: homeSlice.reducer,
        checkout: checkoutSlice.reducer,
        auth: authSlice.reducer,
    },
});
