import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

export default function SimpleBackdrop() {
    // Selección de la variable de estado desde Redux
    const { isLoadingPgeHome } = useSelector((state) => state.home);

    // Estado para gestionar la apertura/cierre del Backdrop
    const [open, setOpen] = useState(false);

    // Hook useEffect para manejar cambios en isLoadingPgeHome
    useEffect(() => {
        // Si isLoadingPgeHome es verdadero, abrir el Backdrop
        if (!isLoadingPgeHome) {
            setOpen(true);
        } else {
            // Si isLoadingPgeHome es falso, cerrar el Backdrop suavemente
             setOpen(false)
        }
    }, [isLoadingPgeHome]);

    // JSX para renderizar el componente Backdrop
    return (
        <div>
            {/* Componente Backdrop con CircularProgress */}
            <Backdrop
                sx={{
                    backgroundColor: "black",
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    transition: "opacity 2s ease-in-out", // Transición suave
    
                }}
                open={open}
            >
                <CircularProgress style={{ color: "white" }} />
            </Backdrop>
        </div>
    );
}