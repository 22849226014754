import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const FirmeProcessDialog = ({
    url = "",
    title = "Compra exitosa",
    icon = "/content/img/SC/loadingIcon.png",
    download = false,
    width = "30%",
    alert = false,
    LoadingOpen = false
}) => {
    const [open, setOpen] = React.useState(false); // Initialize with false
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil

    const abrir = LoadingOpen; // Change this to false to control the dialog opening condition

    React.useEffect(() => {
        if (abrir === true) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [abrir]);

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            return; // Prevent closing when clicking outside the dialog
        }
        if (!download) {
            setOpen(false);
        }
    };

    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/documents-invoice?rev=" + url);
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    sx: {
                        backgroundImage: "url(/content/img/SC/fondo-CP.svg)",
                        backgroundSize: "cover",
                        color: "white",
                        width: isMobile || isTablet ? "80%" : "25%",
                        height: isMobile || isTablet ? "auto" : "35%",
                        borderRadius: "20px",
                        border: "3px solid rgba(255, 255, 255, 0.15)",
                        alignItems: "center",
                        paddingTop: "2%",
                        overflow: "visible",
                    },
                }}
            >
                <img src={icon} width={width} style={{ marginTop: alert ? "-20%" : "-19%" }} />
                {/* <CircularProgress color="success" /> */}
                <DialogTitle
                    id="responsive-dialog-title"
                    sx={{
                        color: "#fff",
                        textAlign: "center",
                        fontFamily: "FuturaP-ExtraBold",
                        fontSize: isMobile ? "16px" : "30px",
                        width: alert ? "80%" : "60%",
                        textTransform: "uppercase"
                    }}
                >
                    {title}
                </DialogTitle>
                <CircularProgress color="success" />    
                <DialogContent style={{ overflow: "hidden" }}>
                    <DialogContentText
                        sx={{
                            color: "#fff",
                            textAlign: "center",
                            fontFamily: "FuturaP-Light",
                            fontSize: isMobile ? "11px" : "14px",
                            textTransform: "uppercase",
                            overflow: "hidden"
                        }}
                    >
                        Por favor espera mientras generamos tu comprobante<br/><strong>Por favor no cierres la ventana.</strong>
                    </DialogContentText>
                </DialogContent>
               
            </Dialog>
        </React.Fragment>
    );
};

export default FirmeProcessDialog;
