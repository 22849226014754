import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";
import { CircularIndeterminate } from "./CircularIndeterminate";
import { startRegisterUser } from "../../../store/auth/thunks";
import { useDispatch, useSelector } from "react-redux";
import { updateStep1Complete } from "../../../store/checkout";
import { secretKey } from "../../../api";
import CryptoJS from "crypto-js";
import { saveStateToLocalStorage } from "../componentsCheckout/saveStateToLocalStorage";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const currentYear = new Date().getFullYear();
const startYear = 1940;
const yearRange = currentYear - startYear + 1;

export default function RegisterDialog({ handleOpen, open, handleClose }) {
    const dispatch = useDispatch();

    //   const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [accountSucces, setAccountSucces] = useState(false); // Estado para controlar la apertura del diálogo
    const [accountSuccess, setAccountSuccess] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil
    const isSurface = useMediaQuery("(max-width:1370px)"); // Verifica si la pantalla es móvil
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

    /* manejamos el estado de la autenticación del usuario */
    const { mensajeError } = useSelector((state) => state.auth);


    const statusRegister = useSelector((state) => state.auth.status);
    const disableButton = statusRegister === "loading" ? true : false;
    const checkoutState = useSelector((state) => state.checkout);
    const homeState = useSelector((state) => state.home);



    // Form data state
    const [formData, setFormData] = useState({
        nombre: " ",
        apellidos: "",
        telefono: "",
        correoElectronico: "",
        confirmCorreoElectronico: "",
        cedula: "",
        fechaNacimiento: {
            dia: "01",
            mes: "01",
            anio: "2021",
        },
        contrasena: "",
        confirmContrasena: "",
    });

    // Form validation errors state
    const [errors, setErrors] = useState({});

    // Effect for handling success message display and dialog close
    useEffect(() => {
        let timer;
        if (accountSuccess) {
            timer = setTimeout(() => {
                handleClose();
                setAccountSuccess(false);
            }, 8000);
        }
        return () => clearTimeout(timer);
    }, [accountSuccess, handleClose]);

    // useEffect(() => {
    //     let timer;
    //     if (accountSuccess) {
    //         timer = setTimeout(() => {
    //             handleClose();
    //             setAccountSuccess(false);
    //         }, 6000);
    //     }
    //     return () => clearTimeout(timer);
    // }, [accountSuccess, handleClose]);

    const validateForm = () => {
        let errors = {};
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^[0-9]{4,}$/;
        // Requiere al menos una mayúscula, una minúscula, un número y más de 5 caracteres
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{5,}$/;

        // Validar cada campo y agregar mensajes de error si son inválidos
        if (!formData.nombre) errors.nombre = "Nombre es requerido";
        if (!formData.apellidos) errors.apellidos = "Apellidos son requeridos";
        if (!formData.telefono || !phonePattern.test(formData.telefono)) errors.telefono = "Número de teléfono inválido";
        if (!formData.correoElectronico || !emailPattern.test(formData.correoElectronico)) errors.correoElectronico = "Correo electrónico inválido";
        if (formData.correoElectronico !== formData.confirmCorreoElectronico) errors.confirmCorreoElectronico = "Los correos electrónicos no coinciden";
        if (!formData.cedula) errors.cedula = "Cédula o identificación es requerida";
        if (!formData.fechaNacimiento.dia || !formData.fechaNacimiento.mes || !formData.fechaNacimiento.anio) errors.fechaNacimiento = "Fecha de nacimiento completa es requerida";

        // Validación de la contraseña
        if (!formData.contrasena) {
            errors.contrasena = "Contraseña es requerida";
        } else if (!passwordPattern.test(formData.contrasena)) {
            errors.contrasena = "La contraseña debe tener al menos 5 caracteres, una letra mayúscula, una letra minúscula y un número.";
        }

        if (formData.contrasena !== formData.confirmContrasena) {
            errors.confirmContrasena = "Las contraseñas no coinciden";
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Handler for register button click
    const handleRegisterClick = async () => {
          // Validate the form before proceeding
        const isFormValid = validateForm();
        
        if (isFormValid) {
            // Dispatch the action to register the user with the form data
            const result = await dispatch(startRegisterUser(formData));

            if (result?.statusCode === 200) {
                saveStateToLocalStorage(checkoutState, homeState);
                const info = {
                    nombre: formData.nombre,
                    apellidos : formData.apellidos,
                    correo : formData.correoElectronico,
                    cedula : formData.cedula,
                    telefono : formData.telefono,
                };

                const payload_2 = CryptoJS.AES.encrypt(JSON.stringify(info), secretKey).toString();
                localStorage.setItem("payload_2", payload_2);
                dispatch(updateStep1Complete(true));
               // Confirm the account creation by setting account success state to true again
               setAccountSucces(true);

               // Reset the account success state after 4 seconds
               setTimeout(() => {
                   setAccountSucces(false);
                   setAccountSuccess(false);
                   handleClose();
               }, 15000);

               // Clear the form data after successful registration
               setFormData({
                   nombre: "",
                   apellidos: "",
                   telefono: "",
                   correoElectronico: "",
                   confirmCorreoElectronico: "",
                   cedula: "",
                   fechaNacimiento: {
                       dia: "",
                       mes: "",
                       anio: "",
                   },
                   contrasena: "",
                   confirmContrasena: "",
               });
            } else {
                localStorage.removeItem("payload_2");
           }
        }
    };

    // Handler for form field changes
    const handleChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    // Handler for date field changes
    const handleDateChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            fechaNacimiento: {
                ...prevData.fechaNacimiento,
                [field]: value,
            },
        }));
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    sx: {
                        backgroundImage: "url(/content/img/SC/fondo-CP.svg)",
                        backgroundSize: "cover",
                        color: "white",
                        width: accountSucces ? (isTablet ? "50%" : "25%") : isMobile ? "80%" : "70%",
                        height: "auto",
                        borderRadius: "20px",
                        border: "3px solid rgba(255, 255, 255, 0.15)",
                        padding: "2%",
                        maxWidth: "80%",
                    },
                }}
            >
                {accountSucces ? (
                    <Stack spacing={2} direction={"row"} alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
                        <img src="/content/img/SC/check.svg" style={{ width: isTablet ? "20%" : "20%" }} />
                        <Stack>
                            <Typography
                                style={{
                                    color: "#fff",
                                    fontSize: isTablet ? "18px" : "28px",
                                    fontFamily: "FuturaP-ExtraBold",
                                    textTransform: "uppercase",
                                }}
                            >
                                Cuenta creada
                            </Typography>
                            <Typography
                                style={{
                                    color: "#29C8B9",
                                    fontSize: isTablet ? "18px" : "28px",
                                    fontFamily: "FuturaP-ExtraBold",
                                    textTransform: "uppercase",
                                }}
                            >
                                con éxito
                            </Typography>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack>
                        <Typography style={{ color: "#C7C7C7", fontSize: isTablet || isSurface ? "22px" : "36px", fontFamily: "FuturaP-ExtraBold" }}>REGISTRO</Typography>
                        <div
                            style={{
                                display: statusRegister === "loading" ? "flex" : "none",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "7vh",
                            }}
                        >
                            <CircularIndeterminate />
                        </div>

                        {!!mensajeError && (
                            <div
                                style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "7vh",
                                    color: "red",
                                    fontFamily:"FuturaP-Medium", 
                                    textTransform:"uppercase"
                                }}
                            >
                                {mensajeError}
                            </div>
                        )}
                        <Stack spacing={4} direction={isTablet ? "column" : "row"} justifyContent={"center"}>
                            <Stack style={{ minWidth: "50%" }}>
                                <Stack style={{ paddingLeft: isTablet ? "0%" : "2%" }}>
                                    <Typography style={{ color: "#C7C7C7", fontSize: isMobile ? "13px" : "16px", fontFamily: "FuturaP-Medium" }}>NOMBRE *</Typography>
                                    <input type="text" className="inputLogin" value={formData.nombre} onChange={(e) => handleChange("nombre", e.target.value)} />
                                    {errors.nombre && <Typography style={{ color: "red", fontSize: isMobile ? "10px" : "12px", fontFamily:"FuturaP-Medium", 
                                    textTransform:"uppercase" }}>{errors.nombre}</Typography>}
                                </Stack>
                                <Stack style={{ paddingLeft: isTablet ? "0%" : "2%" }}>
                                    <Typography style={{ color: "#C7C7C7", fontSize: isMobile ? "13px" : "16px", fontFamily: "FuturaP-Medium" }}>APELLIDOS *</Typography>
                                    <input type="text" className="inputLogin" value={formData.apellidos} onChange={(e) => handleChange("apellidos", e.target.value)} />
                                    {errors.apellidos && <Typography style={{ color: "red", fontSize: isMobile ? "10px" : "12px", fontFamily:"FuturaP-Medium", 
                                    textTransform:"uppercase" }}>{errors.apellidos}</Typography>}
                                </Stack>
                                <Stack style={{ paddingLeft: isTablet ? "0%" : "2%" }}>
                                    <Typography style={{ color: "#C7C7C7", fontSize: isMobile ? "13px" : "16px", fontFamily: "FuturaP-Medium" }}>TELÉFONO</Typography>
                                    <input type="text" className="inputLogin" value={formData.telefono} onChange={(e) => handleChange("telefono", e.target.value)} />
                                    {errors.telefono && <Typography style={{ color: "red", fontSize: isMobile ? "10px" : "12px",fontFamily:"FuturaP-Medium", 
                                    textTransform:"uppercase" }}>{errors.telefono}</Typography>}
                                </Stack>
                                <Stack style={{ paddingLeft: isTablet ? "0%" : "2%" }}>
                                    <Typography style={{ color: "#C7C7C7", fontSize: isMobile ? "13px" : "16px", fontFamily: "FuturaP-Medium" }}>CORREO ELECTRÓNICO *</Typography>
                                    <input type="text" className="inputLogin" value={formData.correoElectronico} onChange={(e) => handleChange("correoElectronico", e.target.value)} />
                                    {errors.correoElectronico && <Typography style={{ color: "red", fontSize: isMobile ? "10px" : "12px",fontFamily:"FuturaP-Medium", 
                                    textTransform:"uppercase" }}>{errors.correoElectronico}</Typography>}
                                </Stack>
                                <Stack style={{ paddingLeft: isTablet ? "0%" : "2%" }}>
                                    <Typography style={{ color: "#C7C7C7", fontSize: isMobile ? "13px" : "16px", fontFamily: "FuturaP-Medium" }}>CONFIRMAR CORREO ELECTRÓNICO *</Typography>
                                    <input type="text" className="inputLogin" value={formData.confirmCorreoElectronico} onChange={(e) => handleChange("confirmCorreoElectronico", e.target.value)} />
                                    {errors.confirmCorreoElectronico && <Typography style={{ color: "red", fontSize: isMobile ? "10px" : "12px",fontFamily:"FuturaP-Medium", 
                                    textTransform:"uppercase" }}>{errors.confirmCorreoElectronico}</Typography>}
                                    <Typography style={{ color: "#fff", fontSize: isMobile ? "10px" : "16px", fontFamily: "FuturaP-Light", paddingTop: "1%" }}>
                                        Importante*
                                        <br />
                                        El comprobante de compra será enviado a este correo, asegúrate de ingresarlo correctamente.
                                    </Typography>
                                </Stack>
                            </Stack>

                            <Stack style={{ minWidth: "50%" }}>
                                <Stack style={{ paddingLeft: isTablet ? "0%" : "2%" }}>
                                    <Typography style={{ color: "#C7C7C7", fontSize: isMobile ? "13px" : "16px", fontFamily: "FuturaP-Medium" }}>CÉDULA O IDENTIFICACIÓN *</Typography>
                                    <input type="text" className="inputLogin" value={formData.cedula} onChange={(e) => handleChange("cedula", e.target.value)} />
                                    {errors.cedula && <Typography style={{ color: "red", fontSize: isMobile ? "10px" : "12px", fontFamily:"FuturaP-Medium", 
                                    textTransform:"uppercase" }}>{errors.cedula}</Typography>}
                                </Stack>
                                <Stack style={{ paddingLeft: isTablet ? "0%" : "2%" }}>
                                    <Typography style={{ color: "#C7C7C7", fontSize: isMobile ? "13px" : "16px", fontFamily: "FuturaP-Medium",fontFamily:"FuturaP-Medium", 
                                    textTransform:"uppercase" }}>FECHA DE NACIMIENTO </Typography>
                                    <Stack direction={"row"} spacing={1}>
                                        <select value={formData.fechaNacimiento.dia} onChange={(e) => handleDateChange("dia", e.target.value)} className="SelectLogin">
                                            <option value="">Día</option>
                                            {[...Array(31).keys()].map((i) => (
                                                <option key={i + 1} value={i + 1}>
                                                    {i + 1}
                                                </option>
                                            ))}
                                        </select>

                                        <select value={formData.fechaNacimiento.mes} onChange={(e) => handleDateChange("mes", e.target.value)} className="SelectLogin">
                                            <option value="">Mes</option>
                                            {[...Array(12).keys()].map((i) => (
                                                <option key={i + 1} value={i + 1}>
                                                    {i + 1}
                                                </option>
                                            ))}
                                        </select>
                                        <select value={formData.fechaNacimiento.anio} onChange={(e) => handleDateChange("anio", e.target.value)} className="SelectLogin">
                                            <option value="">Año</option>
                                            {[...Array(yearRange).keys()].map((i) => (
                                                <option key={startYear + i} value={startYear + i}>
                                                    {startYear + i}
                                                </option>
                                            ))}
                                        </select>
                                    </Stack>
                                    {errors.fechaNacimiento && <Typography style={{ color: "red", fontSize: isMobile ? "10px" : "12px", fontFamily:"FuturaP-Medium", 
                                    textTransform:"uppercase" }}>{errors.fechaNacimiento}</Typography>}
                                </Stack>
                                <Stack style={{ paddingLeft: isTablet ? "0%" : "2%" }}>
                                    <Typography style={{ color: "#C7C7C7", fontSize: isMobile ? "13px" : "16px", fontFamily: "FuturaP-Medium" }}>CONTRASEÑA *</Typography>
                                    <input type={showPassword ? "text" : "password"} className="inputLogin" value={formData.contrasena} onChange={(e) => handleChange("contrasena", e.target.value)} />
                                    <Button style={{ position: "absolute", right: "3%", top: isMobile? "79%" : isTablet? "78%" : "50%", transform: "translateY(-50%)" }} onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <VisibilityOff style={{color:"#67BF47"}}/> : <Visibility style={{color:"#67BF47"}}/>}
                                </Button>
                                    {errors.contrasena && <Typography style={{ color: "red", fontSize: isMobile ? "10px" : "12px",fontFamily:"FuturaP-Medium", 
                                    textTransform:"uppercase" }}>{errors.contrasena}</Typography>}
                                </Stack>
                                <Stack style={{ paddingLeft: isTablet ? "0%" : "2%" }}>
                                    <Typography style={{ color: "#C7C7C7", fontSize: isMobile ? "13px" : "16px", fontFamily: "FuturaP-Medium" }}>CONFIRMAR CONTRASEÑA *</Typography>
                                    <input type={showPassword ? "text" : "password"} className="inputLogin" value={formData.confirmContrasena} onChange={(e) => handleChange("confirmContrasena", e.target.value)} />
                                    <Button style={{ position: "absolute", right: "3%", top:  isMobile ? "87%" : isTablet ? "86%" : "63%", transform: "translateY(-50%)" }} onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <VisibilityOff style={{color:"#67BF47"}}/> : <Visibility style={{color:"#67BF47"}}/>}
                                    </Button>
                                    {errors.confirmContrasena && <Typography style={{ color: "red", fontSize: isMobile ? "10px" : "12px",fontFamily:"FuturaP-Medium", 
                                    textTransform:"uppercase" }}>{errors.confirmContrasena}</Typography>}
                                </Stack>
                                <Stack alignItems={isTablet ? "center" : "flex-end"} style={{ paddingTop: "5%" }}>
                                    <Button
                                        variant="contained"
                                        className="btnSendRegister"
                                        onClick={handleRegisterClick}
                                        style={{
                                            backgroundColor: "#29C8B9",
                                            color: "white",
                                            fontFamily: "FuturaP-Medium",
                                            width: isMobile ? "60%" : "40%",
                                            fontSize: isMobile ? "15px" : "19px",
                                            height: isMobile ? "37.689px" : "57.689px",
                                        }}
                                        disabled={disableButton}
                                    >
                                        REGISTRARSE
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                )}
            </Dialog>
        </React.Fragment>
    );
}