

import React from 'react'
import { AppLayout } from '../../layout';
import Cartelera from '../../components/componentsCartelera/Cartelera';


export const PageCartelera = () => {
  return (
      <AppLayout>
        <Cartelera/>
      </AppLayout>
  );
}
