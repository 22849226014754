// Step1Helpers.js

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTandasBd, setActiveMovieInfo, updatearrayInfoMovie } from "../../../store/home";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../api";

/******************
 * Custom Hook: useActiveTandaEffect
 * Handles the effect when activeTanda or allMoviesBillboard changes.
 * If activeTanda is empty, it sets the first movie in the billboard as active,
 * updates movie information, and navigates to the movie page.
 ******************/
// Hook personalizado para manejar el efecto de tanda activa
// const useActiveTandaEffect = (activeTanda, allMoviesBillboard) => {
//     // Hooks para dispatch y navegación
//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     useEffect(() => {
//         // Obtener la fecha de hoy en formato 'YYYY-MM-DD'
//         const today = new Date().toISOString().split('T')[0];

//         // Verificar si no hay tanda activa
//         if (!activeTanda || activeTanda.length === 0) {
//             // Limpiar el ID de la película local almacenada
//             localStorage.removeItem("idLocalMovie");
//             // Verificar si hay películas en la cartelera
//             if (allMoviesBillboard && allMoviesBillboard.length > 0) {
//                 // Establecer la primera película de la cartelera como activa
//                 const firstMovieId = allMoviesBillboard[0].idMasterMovie;
//                 // Guardar el ID de la película en localStorage
//                 localStorage.setItem("idLocalMovie", firstMovieId);
//                 // Actualizar el estado global con la información de la película activa
//                 dispatch(setActiveMovieInfo(firstMovieId));

//                 // Actualizar la información de la película en el estado global
//                 dispatch(updatearrayInfoMovie());

//                 // Obtener las tandas para la fecha actual
//                 dispatch(getTandasBd(today, 1));
//             }

//             // Navegación comentada (posiblemente para uso futuro)
//             // navigate("/movie");
//         }
//     }, [activeTanda, allMoviesBillboard, dispatch, navigate]);
//     // El efecto se ejecutará cuando cambie alguna de estas dependencias
// };

/******************
 * Function: convertTime
 * Converts a date string into a human-readable format.
 * @param {string} time - The date string to convert.
 * @returns {string} - The formatted date string.
 ******************/
const convertTime = (dateString) => {
    const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];

    // Dividir la fecha en sus componentes
    const [year, month, day] = dateString.split("-").map(Number) || [];

    // Obtener el nombre del mes (restamos 1 porque los índices de los meses en JavaScript van de 0 a 11)
    const monthName = months[month - 1];

    // Formar la cadena de salida
    return `${day} de ${monthName} del ${year}`;
};

/******************
 * Decrypt and Parse Payload
 * Decrypts the encrypted payload from localStorage and parses it into an object.
 * If decryption fails, logs the error and ensures parsedPayload_2 is an empty object.
 ******************/
  const encryptedPayload_2 = localStorage.getItem("payload_2");

  let parsedPayload_2 = {};
  if (encryptedPayload_2) {
      try {
          const decryptedPayload_2 = CryptoJS.AES.decrypt(encryptedPayload_2, secretKey).toString(CryptoJS.enc.Utf8);
          parsedPayload_2 = JSON.parse(decryptedPayload_2);
      } catch (error) {
          console.error("Error decrypting or parsing payload_2:", error);
          parsedPayload_2 = {}; // ensure parsedPayload_2 is an object even if decryption fails
      }
  }

/******************/
/* Exports */
export { convertTime, parsedPayload_2 };
/******************/
