import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Divider, Stack, Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { startLoginUser } from "../../../store/auth/thunks";
import { useDispatch, useSelector } from "react-redux";
import { saveStateToLocalStorage } from "../componentsCheckout/saveStateToLocalStorage";

import { secretKey } from "../../../api";
import CryptoJS from "crypto-js";
import { updateStep1Complete } from "../../../store/checkout";
import RegisterDialog from "./RegisterDialog";
import GuestDialog from "./GuestDialog";
import LoginDialog from "./LoginDialog";

export default function OptionDialog({ handleOpen, open, handleClose }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)");
    const isSurface = useMediaQuery("(max-width:1370px)");
    const [rememberPassword, setRememberPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const dispatch = useDispatch();
    const [openRegister, setOpenRegister] = useState(false); // Estado para controlar la apertura del diálogo
    const [openGuest, setOpenGuest] = useState(false); // Estado para controlar la apertura del diálogo
    const [openDialog, setOpenDialog] = useState(false); // Estado para controlar la apertura del diálogo

    const { mensajeErrorLogin } = useSelector((state) => state.auth);
    const checkoutState = useSelector((state) => state.checkout);
    const homeState = useSelector((state) => state.home);

    useEffect(() => {
        let timer;
        if (rememberPassword) {
            timer = setTimeout(() => {
                handleClose();
                setRememberPassword(false);
            }, 5000);
        }

        return () => clearTimeout(timer);
    }, [rememberPassword, handleClose]);

    const handleRememberPassClick = () => {
        setRememberPassword(true);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleLoginClick = async () => {
        let isValid = true;
        if (!validateEmail(email)) {
            setEmailError("El formato del correo no es válido");
            isValid = false;
        } else {
            setEmailError("");
        }

        if (password.trim() === "") {
            setPasswordError("La contraseña no puede estar vacía");
            isValid = false;
        } else {
            setPasswordError("");
        }

        if (isValid) {
            // Lógica de inicio de sesión aquí
            const result = await dispatch(startLoginUser(email, password));

            if (result?.statusCode === 200) {
                const userData = result.userPayload;
                saveStateToLocalStorage(checkoutState, homeState);
                const info = {
                    nombre: userData.name_user,
                    apellidos: userData.lasname_user,
                    correo: userData.email_user,
                    cedula: userData.indetification_user,
                    telefono: userData.phone_user,
                };

                const payload_2 = CryptoJS.AES.encrypt(JSON.stringify(info), secretKey).toString();
                localStorage.setItem("payload_2", payload_2);
                dispatch(updateStep1Complete(true));

                setTimeout(() => {
                    handleClose();
                }, 1000);
            } else {
                localStorage.removeItem("payload_2");
            }
        }
    };

    const handleClickOpenRegister = () => {
        // Función para abrir el diálogo
        setOpenRegister(true);
    };
    const handleCloseRegister = () => {
        // Función para cerrar el diálogo
        setOpenRegister(false);
    };

    const handleClickOpenGuest = () => {
        // Función para abrir el diálogo
        setOpenGuest(true);
    };

    const handleCloseGuest = () => {
        // Función para cerrar el diálogo
        setOpenGuest(false);
    };

    const handleClickOpenDialog = () => {
        // Función para abrir el diálogo
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        // Función para cerrar el diálogo
        setOpenDialog(false);
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    sx: {
                        backgroundImage: "url(/content/img/SC/fondo-CP.svg)",
                        backgroundSize: "cover",
                        color: "white",
                        width: rememberPassword ? (isTablet ? "70%" : isSurface ? "35%" : isMobile ? "80%" : "25%") : isMobile ? "80%" : isTablet ? "50%" : "30%",
                        height: "auto",
                        borderRadius: "20px",
                        border: "3px solid rgba(255, 255, 255, 0.15)",
                        padding: "2%",
                        maxWidth: "80%",
                        minWidth: "25%",
                    },
                }}
            >
                {rememberPassword ? (
                    <Stack spacing={2} direction={"column"} alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
                        <img src="/content/img/SC/rememberPass.svg" style={{ width: isTablet ? "20%" : "20%" }} />
                        <Stack alignItems="center" justifyContent="center" spacing={2}>
                            <Typography
                                style={{
                                    color: "#fff",
                                    fontSize: isTablet ? "18px" : "28px",
                                    fontFamily: "FuturaP-ExtraBold",
                                    textTransform: "uppercase",
                                    textAlign: "center",
                                }}
                            >
                                RECUPERACIÓN DE CONTRASEÑA
                            </Typography>
                            <Typography
                                style={{
                                    color: "#fff",
                                    fontSize: isTablet ? "12px" : "15px",
                                    fontFamily: "FuturaP-Medium",
                                    textAlign: "center",
                                }}
                            >
                                Hemos enviado las instrucciones a tu correo, <br />
                                Por favor, verifica tu bandeja de entrada
                            </Typography>
                            <Stack alignItems="center" justifyContent="center" spacing={2}>
                                <Typography
                                    style={{
                                        color: "#fff",
                                        fontSize: isTablet ? "10px" : "13px",
                                        fontFamily: "FuturaP-Medium",
                                        textAlign: "center",
                                    }}
                                >
                                    ¿NO RECIBISTE EL CORREO?
                                </Typography>
                                <Button variant="contained" className="btnResendPass" style={{ width: isTablet ? "100%" : "100%", fontSize: isTablet || isSurface ? "15px" : "19px", height: isTablet || isSurface ? "47.689px" : "57.689px" }}>
                                    ENVIAR NUEVAMENTE
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack spacing={isMobile ? 2 : 0}>
                        <Typography style={{ color: "#C7C7C7", fontSize: isTablet || isSurface ? "26px" : isMobile ? "19px" : "36px", fontFamily: "FuturaP-ExtraBold", textAlign: isMobile ? "center" : "left" }}>INFORMACIÓN DE USUARIO</Typography>
                        <Typography style={{ color: "#fff", fontSize: isTablet || isSurface ? "26px" : isMobile ? "11px" : "15px", fontFamily: "FuturaP-Light", textAlign: isMobile ? "center" : "left" }}>INICIÁ SESIÓN AHORA O CONTINUA COMO INVITADO</Typography>

                        <br />
                        <Stack direction={"row"} spacing={isMobile ? 2 : 4}>
                            <Stack spacing={4}>
                            <Button className="btnLogin" onClick={handleClickOpenDialog}>
                                INICIAR SESIÓN
                            </Button>
                            <LoginDialog open={openDialog} handleClose={handleCloseDialog} />

                                <Button className="btnRegister" onClick={handleClickOpenRegister}>
                                    REGISTRARSE
                                </Button>
                                <RegisterDialog open={openRegister} handleClose={handleCloseRegister} />
                            </Stack>

                            <Divider orientation="vertical" variant="middle" flexItem style={{ backgroundColor: "#727272" }} />

                            <Stack justifyContent={"center"}>
                                <Button className="btnLogin" onClick={handleClickOpenGuest}>
                                    INVITADO
                                </Button>

                                <GuestDialog open={openGuest} handleClose={handleCloseGuest} />
                            </Stack>
                        </Stack>
                    </Stack>
                )}
            </Dialog>
        </React.Fragment>
    );
}
