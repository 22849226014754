

import React from 'react'
import { AppLayout } from '../../layout';
import Nosotros from '../../components/componentsNosotros/Nosotros';






export const PageNosotros = () => {


  return (
      <AppLayout>
        <Nosotros />
      </AppLayout>
  );
}
