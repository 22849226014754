import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../api";
import { useDispatch } from "react-redux";
import { setUserAuthentication } from "../../../store/auth/authSlice";
export default function GuestDialog({ handleOpen, open, handleClose }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)");
    const isSurface = useMediaQuery("(max-width:1370px)");
    const dispatch = useDispatch();

    const [formData, setFormData] = React.useState({
        nombre: "",
        apellidos: "",
        telefono: "",
        correo: "",
        confirmarCorreo: "",
    });

    const [errors, setErrors] = React.useState({
        nombre: "",
        apellidos: "",
        correo: "",
        confirmarCorreo: "",
    });

    const validateForm = () => {
        let valid = true;
        let newErrors = {};

        if (!formData.nombre) {
            newErrors.nombre = "El nombre es obligatorio";
            valid = false;
        }
        if (!formData.apellidos) {
            newErrors.apellidos = "Los apellidos son obligatorios";
            valid = false;
        }
        if (!formData.correo) {
            newErrors.correo = "El correo electrónico es obligatorio";
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.correo)) {
            newErrors.correo = "El formato del correo electrónico no es válido";
            valid = false;
        }
        if (formData.confirmarCorreo !== formData.correo) {
            newErrors.confirmarCorreo = "Los correos electrónicos no coinciden";
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = () => {
        if (validateForm()) {
           const userPayload = {
               id_user: "",
               name_user: formData.nombre, // Asegúrate de que estos valores existan en userData
               email_user: formData.correo,
               status_user: "",
               lasname_user: formData.apellidos,
               phone_user: formData.telefono,
               indetification_user: "",
               birthdate: "",
               token_user: "",
               statusCode: 1,
               type_user: "guest"
               
           };

           // Despacha la acción con el objeto userPayload
           dispatch(setUserAuthentication(userPayload));

           // Si todo sale bien guardamos al usuario en nuestro navegador pendiente de localStorage encriptado
           const payload_1 = CryptoJS.AES.encrypt(JSON.stringify(userPayload), secretKey).toString();
           localStorage.setItem("payload_1", payload_1);

            // Aquí puedes agregar la lógica para enviar el formulario o realizar otras acciones
        } else {
          // console.log("Formulario inválido");
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    sx: {
                        backgroundImage: "url(/content/img/SC/fondo-CP.svg)",
                        backgroundSize: "cover",
                        color: "white",
                        width: "50%",
                        height: "auto",
                        borderRadius: "20px",
                        border: "3px solid rgba(255, 255, 255, 0.15)",
                        padding: "2%",
                    },
                }}
            >
                <Stack>
                    <Typography
                        style={{
                            color: "#C7C7C7",
                            fontSize: isTablet || isSurface ? "26px" : "36px",
                            fontFamily: "FuturaP-ExtraBold",
                            textAlign: isTablet || isSurface ? "center" : "left",
                        }}
                    >
                        INVITADO
                    </Typography>

                    <Typography
                        style={{
                            color: "#fff",
                            fontSize: isTablet || isSurface ? "10px" : "16px",
                            textAlign: isTablet || isSurface ? "center" : "left",
                            fontFamily: "FuturaP-Light",
                        }}
                    >
                        Te recomendamos registrarte para disfrutar de compras rápidas, promociones exclusivas y recordatorios de tus películas favoritas.
                        <br/>
                        <strong>¡Mejorá tu experiencia!</strong>
                    </Typography>
                    <br/>

                    <Stack spacing={1}>
                        <Stack style={{ paddingLeft: "2%" }}>
                            <Typography
                                style={{
                                    color: "#C7C7C7",
                                    fontSize: isTablet || isSurface ? "12px" : "16px",
                                    fontFamily: "FuturaP-Medium",
                                }}
                            >
                                NOMBRE *
                            </Typography>
                            <input type="text" name="nombre" className="inputLogin" value={formData.nombre} onChange={handleChange} />
                            {errors.nombre && <Typography style={{ color: "red" }}>{errors.nombre}</Typography>}
                        </Stack>

                        <Stack style={{ paddingLeft: "2%" }}>
                            <Typography
                                style={{
                                    color: "#C7C7C7",
                                    fontSize: isTablet || isSurface ? "12px" : "16px",
                                    fontFamily: "FuturaP-Medium",
                                }}
                            >
                                APELLIDOS *
                            </Typography>
                            <input type="text" name="apellidos" className="inputLogin" value={formData.apellidos} onChange={handleChange} />
                            {errors.apellidos && <Typography style={{ color: "red" }}>{errors.apellidos}</Typography>}
                        </Stack>

                        <Stack style={{ paddingLeft: "2%" }}>
                            <Typography
                                style={{
                                    color: "#C7C7C7",
                                    fontSize: isTablet || isSurface ? "12px" : "16px",
                                    fontFamily: "FuturaP-Medium",
                                }}
                            >
                                TELÉFONO
                            </Typography>
                            <input type="text" name="telefono" className="inputLogin" value={formData.telefono} onChange={handleChange} />
                        </Stack>

                        <Stack style={{ paddingLeft: "2%" }}>
                            <Typography
                                style={{
                                    color: "#C7C7C7",
                                    fontSize: isTablet || isSurface ? "12px" : "16px",
                                    fontFamily: "FuturaP-Medium",
                                }}
                            >
                                CORREO ELECTRÓNICO *
                            </Typography>
                            <input type="text" name="correo" className="inputLogin" value={formData.correo} onChange={handleChange} />
                            {errors.correo && <Typography style={{ color: "red" }}>{errors.correo}</Typography>}
                        </Stack>

                        <Stack style={{ paddingLeft: "2%" }}>
                            <Typography
                                style={{
                                    color: "#C7C7C7",
                                    fontSize: isTablet || isSurface ? "12px" : "16px",
                                    fontFamily: "FuturaP-Medium",
                                }}
                            >
                                CONFIRMAR CORREO ELECTRÓNICO *
                            </Typography>
                            <input type="text" name="confirmarCorreo" className="inputLogin" value={formData.confirmarCorreo} onChange={handleChange} />
                            {errors.confirmarCorreo && <Typography style={{ color: "red" }}>{errors.confirmarCorreo}</Typography>}
                            <Typography
                                style={{
                                    color: "#fff",
                                    fontSize: isMobile ? "10px" : "13px",
                                    fontFamily: "FuturaP-Light",
                                    paddingTop: "1%",
                                    textAlign: "center",
                                }}
                            >
                                Importante*
                                <br />
                                El comprobante de compra será enviado a este correo, asegúrate de ingresarlo correctamente.
                            </Typography>
                        </Stack>

                        <Stack alignItems="center">
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#29C8B9",
                                    color: "white",
                                    fontFamily: "FuturaP-Medium",
                                    width: "35%",
                                    fontSize: isTablet || isSurface ? "15px" : "19px",
                                    height: isTablet || isSurface ? "37.689px" : "57.689px",
                                }}
                                onClick={handleSubmit}
                            >
                                COMPRAR
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Dialog>
        </React.Fragment>
    );
}
