import { useLocation } from "react-router-dom";
import { AppLayout } from "../../layout";
import { usePDF } from "react-to-pdf";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { convertTime } from "../../../hooks/useTimeBillboard";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FeeServiceAmount, secretKey } from "../../../api";

import QRCode from "qrcode";
import CryptoJS from "crypto-js";
import { getInvoiceId } from "../../../store/checkout";
import { descargaInvoice } from "../../../store/pymentStoree/pymentStoree";


export const DocumentsInvoice = () => {
    // Definición del componente funcional 'DocumentsInvoice'.

    const location = useLocation();
    // Utiliza 'useLocation' para obtener la URL actual de la aplicación. Esto se usa para extraer parámetros de la URL.

    const searchParams = new URLSearchParams(location.search);
    // Crea una instancia de 'URLSearchParams' para analizar los parámetros de la URL actual.

    const rev = searchParams.get("rev");
    // Obtiene el valor del parámetro 'rev' de la URL usando 'searchParams.get'. Este valor podría ser usado para identificar una revisión o versión específica de un documento.

    // Se obtiene el tema y se verifica si el dispositivo es móvil
    const [isPdfGenerating, setIsPdfGenerating] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { toPDF, targetRef } = usePDF({ filename: "invoice.pdf" });
    const { step1, step2, step3, step4, activeTanda, tokenSessions } = useSelector((state) => state.checkout);
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Use useNavigate from react-router-dom
    const [loading, setLoading] = useState(true);
    const [transactionData, setTransactionData] = useState({ invoiceIdBp: "", transactionId: "" });
    const [error, setError] = useState(false);
    const { arrayInfoMovie } = useSelector((state) => state.home);

    const [qrImage, setQrImage] = useState("");

    const modifiedToPDF = async () => {
        setIsPdfGenerating(true);

        try {
            // Esperar a que se apliquen los cambios en el DOM
            await new Promise((resolve) => setTimeout(resolve, 500));

            // Generar el PDF
            await toPDF();
        } finally {
            setIsPdfGenerating(false);
        }
    };

    // State variables for storing fetched data
    const [idinvoice_transaction_log_cv, setidinvoice_transaction_log_cv] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [nombrePelis, setNombrePelis] = useState(null);
    const [fechaPelis, setFechaPelis] = useState(null);
    const [NombreCliente, setNombreCliente] = useState(null);
    const [CorreoCliente, setCorreoCliente] = useState(null);
    const [asientos, setAsientos] = useState([]);
    const [censura, setCensura] = useState(null);
    const [totalPayment, setTotalPayment] = useState(0);
    const [entradas, setEntradas] = useState([]);
    const [confiteria, setConfiteria] = useState([]);
    const [costFeeService, setCostFeeService] = useState();
    const [imgPage, setImgPage] = useState();
    const [token, setToken] = useState();

    useEffect(() => {
        // Async function to fetch data and update state
        const fetchData = async () => {
            try {
                setError(false); // Reset error state
                // Fetch invoice data from the server
                const invoice = await dispatch(getInvoiceId(rev));

                // console.log("invoice", invoice);
                // Check if the fetch was successful
                if (invoice.statusCode === 210) {
                    setError(true);
                    setLoading(false);
                    return;
                }

                // Extract and set data from the fetched invoice
                const globalResult = invoice.data[0];
                setToken(globalResult.idsession_transaction_log_cv); //  chekiado
                const tk = globalResult.idsession_transaction_log_cv;

                const globalResultDetails = globalResult.details_transaction_log_cv;

                setidinvoice_transaction_log_cv(globalResult.idinvoice_transaction_log_cv);

                setTransactionId(globalResult.trans_bank_transaction_log_cv);

                setNombrePelis(`${globalResultDetails.movieData.name_movie_bp} | ${globalResultDetails.movieData.room_name_shifts_bp}`);
                setFechaPelis(`${convertTime(globalResultDetails.movieData.date_time_shifts_bp)} | ${globalResultDetails.movieData.hora}`);


                 setNombreCliente(`${globalResultDetails.infoCLient.name_user} ${globalResultDetails.infoCLient.lasname_user}`);
                 setCorreoCliente(globalResultDetails.infoCLient.email_user);

                setAsientos(globalResultDetails.seating.seating);

                setCensura(globalResultDetails.movieDetail.censura);


                const filterP = globalResultDetails.priceList.priceList.filter((item) => item.quantity > 0);

                // Sumar las cantidades de los objetos filtrados
                const totalQuantity = filterP.reduce((sum, item) => sum + item.quantity, 0);
                const totalFeeService = totalQuantity * FeeServiceAmount;
                setCostFeeService(totalFeeService);
                setTotalPayment(globalResult.total_transaction_log_cv);
                // Filter price list to include only items with quantity greater than 0
                const filteredPriceList = globalResultDetails.priceList.priceList.filter((item) => item.quantity > 0);
                setEntradas(filteredPriceList);
                // Set confiteria (snacks) details
                setConfiteria(globalResultDetails.articules);

                setImgPage(globalResultDetails.movieDetail.imgheader);
                // Generate QR code after data is fetched
                await generateQRCode(rev, tk);


                await dispatch(descargaInvoice(globalResult.idIncrement_transaction_log));
                localStorage.removeItem("pytmi");
                localStorage.removeItem("dti");
                localStorage.removeItem("step");
                localStorage.removeItem("invoiceIdBp");
                localStorage.removeItem("movieParam");
            } catch (error) {
                //console.error("Error fetching invoice data:", error);
                setError(true);
            } finally {
                setLoading(false); // Set loading to false once fetching is complete
            }
        };

        // Function to generate QR code
        const generateQRCode = async (rev, tk) => {
            try {
                // Generate QR code URL
                const qrUrl = `https://theaterboss.studiocinemascr.com/ValidatePymetn?rectype=${rev}`;

                // Generate QR code image
                const qrImage = await QRCode.toDataURL(qrUrl);

                // Update state with QR code image
                setQrImage(qrImage);
            } catch (err) {
               // console.error("Error generating QR code:", err);
            }
        };

        fetchData(); // Call fetchData on component mount
    }, [dispatch, rev]); // Dependencies array to re-run effect if dispatch or rev changes

    const handleBackButtonBack = () => {
        navigate("/");
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Custom message or action before the user leaves
            const message = "Are you sure you want to leave? Your changes may not be saved.";
            event.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    return (
        <>
            <AppLayout>
                <div style={{ paddingTop: "5%", width: "100%", backgroundImage: "url(/content/img/SC/fondoComprobante.png)", backgroundSize: "cover", backgroundRepeat: "no-repeat", fontFamily: "Arial, sans-serif", color: "#FFF", display: "flow-root" }}>
                    <div style={{ display: "inline-block", marginBottom: "9px", marginTop: isMobile ? "80px" : "9px", alignContent: "center", marginLeft: isMobile ? "14%" : "38%" }}>
                        <button onClick={() => handleBackButtonBack()} type="button" className="btn btn-secondary" style={{ width: isMobile ? "150px" : "217px", height: isMobile ? "47px" : "57.689px", borderRadius: "6px", backgroundColor: "#29C8B9", boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)", border: "none", color: "#FFF", textAlign: "center", fontFamily: "FuturaP-Medium", fontSize: isMobile ? "15px" : "19px", textTransform: "uppercase", marginRight: isMobile ? "5px" : "10px" }}>
                            Volver al inicio
                        </button>
                    </div>

                    {loading ? (
                        <div style={{ height: "425px", width: "858px", margin: "0 auto", backgroundSize: "cover", backgroundRepeat: "no-repeat", fontFamily: "Arial, sans-serif", color: "#FFF" }}>
                            <div style={{ textAlign: "center" }}>
                                <img src="/content/img/SC/newLogoSC.png" alt="Logo" style={{ width: "159px", height: "92.25px" }} />
                            </div>
                            <div style={{ width: "738px", margin: "0 auto", background: "rgba(0, 0, 0, 0.24)", border: "1px solid rgba(255, 255, 255, 0.20)", borderRadius: "20px", padding: "20px" }}>
                                <h1 style={{ textAlign: "center", fontSize: "30px", fontWeight: "700", margin: "0" }}>Cargando...</h1>
                            </div>
                        </div>
                    ) : error ? (
                        <div style={{ height: "425px", width: "858px", margin: "0 auto", backgroundSize: "cover", backgroundRepeat: "no-repeat", fontFamily: "Arial, sans-serif", color: "#FFF" }}>
                            <div style={{ textAlign: "center" }}>
                                <img src="/content/img/SC/newLogoSC.png" alt="Logo" style={{ width: "159px", height: "92.25px" }} />
                            </div>
                            <div style={{ width: "738px", margin: "0 auto", background: "rgba(0, 0, 0, 0.24)", border: "1px solid rgba(255, 255, 255, 0.20)", borderRadius: "20px", padding: "20px" }}>
                                <h1 style={{ textAlign: "center", fontSize: "30px", fontWeight: "700", margin: "0" }}>LO SENTIMOS</h1>
                                <p style={{ textAlign: "center", fontWeight: "100", margin: "0" }}>en este momento no podemos generar la factura</p>
                                <p style={{ textAlign: "center", fontWeight: "600", margin: "0" }}>Favor le invitamos a revisar su correo electrónico para ver su factura </p>
                            </div>
                        </div>
                    ) : (
                        <>
                            <button onClick={() => modifiedToPDF(false)} type="button" className="btn btn-secondary" style={{ width: isMobile ? "150px" : "217px", height: isMobile ? "47px" : "57.689px", borderRadius: "6px", backgroundColor: "#67BF47", boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)", border: "none", color: "#FFF", textAlign: "center", fontFamily: "FuturaP-Medium", fontSize: isMobile ? "15px" : "19px", textTransform: "uppercase" }}>
                                Descargar PDF
                            </button>
                            <div ref={targetRef} style={{ width: isPdfGenerating ? "858px" : isMobile ? "90%" : "858px", margin: "0 auto", backgroundSize: "cover", backgroundRepeat: "no-repeat", fontFamily: "Arial, sans-serif", color: "#FFF" }}>
                                <div style={{ textAlign: "center" }}>
                                    <img src={isPdfGenerating ? "/content/img/SC/logonegroSC.png" : "/content/img/SC/newLogoSC.png"} alt="Logo" style={{ width: "159px", height: "92.25px" }} />
                                </div>
                                <div style={{ width: isPdfGenerating ? "738px" : isMobile ? "90%" : "738px", margin: "0 auto", background: isPdfGenerating ? "none" : "rgba(0, 0, 0, 0.24)", border: isPdfGenerating ? "1px solid rgba(0, 0, 0, 0.24)" : "1px solid rgba(255, 255, 255, 0.20)", borderRadius: "20px", padding: "20px" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <img src={imgPage} style={{ width: isPdfGenerating ? "85%" : isMobile ? "90%" : "85%" , display: isPdfGenerating ? "none" : "inline-block"}} />
                                    </div>
                                    <br />

                                    <div style={{ margin: "20px 0", borderBottom: isPdfGenerating ? "1px solid rgba(0, 0, 0, 0.24)" : "1px solid rgba(255, 255, 255, 0.20)", paddingBottom: "20px", paddingLeft: "8%", paddingRight: "8%" }}>
                                        <div style={{ float: "left", maxWidth: "50%" }}>
                                            <h1 style={{ textAlign: "left", fontSize: isPdfGenerating ? "25px" : isMobile ? "17px" : "25px", fontWeight: "700", margin: "0", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>
                                                GRACIAS POR
                                                <br />
                                                TU COMPRA
                                            </h1>
                                            <h2 style={{ textAlign: "left", fontSize: isPdfGenerating ? "20px" : isMobile ? "18px" : "20px", fontWeight: "100", margin: "0", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>VIVÍ EL CINE</h2>
                                            <p style={{ fontWeight: "600", fontSize: isPdfGenerating ? "16px" : isMobile ? "12px" : "16px", marginBottom: "0px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>NÚMERO DE CONFIRMACIÓN</p>
                                            <p style={{ marginTop: "0px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>{idinvoice_transaction_log_cv}</p>
                                            <p style={{ fontWeight: "600", fontSize: isPdfGenerating ? "16px" : isMobile ? "12px" : "16px", marginBottom: "0px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>NÚMERO DE TRANSACCIÓN</p>
                                            <p style={{ marginTop: "0px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>{transactionId}</p>
                                            <p style={{ fontWeight: "600", fontSize: isPdfGenerating ? "16px" : isMobile ? "12px" : "16px", marginBottom: "0px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>PELÍCULA</p>
                                            <p style={{ color: "#29C8B9", marginBottom: "0px", marginTop: "0px", color: isPdfGenerating ? "black" : isMobile ? "rgb(41, 200, 185)" : "rgb(41, 200, 185)" }}>{nombrePelis}</p>
                                            <p style={{ color: isPdfGenerating ? "black" : isMobile ? "white" : "white", marginTop: "0px" }}>{fechaPelis}</p>
                                        </div>
                                        <div style={{ float: "right", maxWidth: "50%" }}>
                                            <h3 style={{ textAlign: "left", fontSize: isPdfGenerating ? "20px" : isMobile ? "12px" : "20px", fontWeight: "600", margin: "0", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>DETALLES DE ORDEN</h3>
                                            <br />
                                            <h2 style={{ textAlign: "left", fontSize: isPdfGenerating ? "18px" : isMobile ? "13px" : "18px", fontWeight: "600", margin: "0", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>{NombreCliente}</h2>
                                            <h3 style={{ textAlign: "left", fontSize: isPdfGenerating ? "16px" : isMobile ? "13px" : "16px", fontWeight: "100", margin: "0", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>{CorreoCliente}</h3>

                                            <p style={{ fontWeight: "600", fontSize: isPdfGenerating ? "16px" : isMobile ? "12px" : "16px", marginBottom: "0px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>UBICACIÓN</p>
                                            <p style={{ marginTop: "0px", fontSize: isPdfGenerating ? "16px" : isMobile ? "12px" : "16px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>SANTA ANA- CITY PLACE</p>

                                            <p style={{ fontWeight: "600", fontSize: isPdfGenerating ? "16px" : isMobile ? "12px" : "16px", marginBottom: "0px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>ASIENTOS</p>
                                            <p style={{ color: isPdfGenerating ? "black" : isMobile ? "#29C8B9" : "#29C8B9", marginTop: "0px" }}>
                                                {asientos.length > 0 ? (
                                                    asientos.map((item, index) => (
                                                        <span key={item.idButaca}>
                                                            {item.nombreButaca}
                                                            {index < asientos.length - 1 && ", "}
                                                        </span>
                                                    ))
                                                ) : (
                                                    <span>No hay asientos disponibles</span>
                                                )}
                                            </p>
                                            <p style={{ fontWeight: "600", fontSize: isPdfGenerating ? "16px" : isMobile ? "12px" : "16px", marginBottom: "0px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>CENSURA</p>
                                            <p style={{ color: "#29C8B9", marginTop: "0px", color: isPdfGenerating ? "black" : isMobile ? "#29C8B9" : "#29C8B9" }}>{censura}</p>
                                            <p style={{ fontWeight: "600", fontSize: "14px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>
                                                MÉTODO DE PAGO:<span style={{ fontWeight: "100" }}> TARJETA</span>
                                            </p>
                                        </div>

                                        <div style={{ clear: "both" }} />
                                    </div>

                                    <div style={{ margin: "20px 0", paddingBottom: "20px" }}>
                                        <div style={{ float: "left", width: "43%", paddingLeft: "8%" }}>
                                            <p style={{ fontWeight: "600", margin: "0", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>
                                                TOTAL: <span style={{ float: "right" }}>₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([totalPayment])}</span>
                                            </p>
                                            <br />
                                            <p style={{ fontWeight: "600", fontSize: "16px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white", marginBottom: "0px" }}>ENTRADAS</p>
                                            <div>
                                                {entradas
                                                    .filter((item) => item.quantity > 0)
                                                    .map((item, index) => (
                                                        <p key={index} style={{ fontSize: "14px", fontWeight: "100", margin: "0", color: isPdfGenerating ? "black" : isMobile ? "white" : "white", paddingLeft: "4%" }}>
                                                            {item.name} ({item.quantity}) <span style={{ float: "right" }}>₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([item.subtotal])}</span>
                                                            <br />
                                                            <br />
                                                        </p>
                                                    ))}
                                            </div>
                                            <p style={{ fontWeight: "600", fontSize: "16px", marginBottom: "0px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>
                                                CARGO DE SERVICIO
                                                <span style={{ float: "right", fontWeight: "100", fontSize: "14px" }}>
                                                    ₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([costFeeService])}
                                                </span>
                                            </p>
                                            <p style={{ fontSize: "14px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white", marginTop: "0px", borderBottom:isPdfGenerating ? "1px solid rgba(0, 0, 0, 0.24)" : "1px solid rgba(255, 255, 255, 0.20)", paddingBottom: "20px", marginLeft: "14px" }}>Cargo por boleto ₡{FeeServiceAmount} colones</p>

                                            <p style={{ fontWeight: "600", fontSize: "16px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white", marginBottom: "0px" }}>CONFITERÍA</p>
                                            <div style={{  paddingBottom: "20px" }}>
                                                {confiteria.length === 0 ? (
                                                    <p style={{ fontSize: "14px", fontWeight: "100", margin: "0", color: isPdfGenerating ? "black" : isMobile ? "white" : "white", paddingLeft: "4%" }}>
                                                        Sin productos seleccionados <span style={{ float: "right" }}></span>
                                                    </p>
                                                ) : (
                                                    confiteria.map((producto, index) => (
                                                        <div key={producto.idArt + producto.key + index} style={{ fontSize: "14px", fontWeight: "100", margin: "0", color: isPdfGenerating ? "black" : isMobile ? "white" : "white", borderBottom:isPdfGenerating ? "1px solid rgba(0, 0, 0, 0.24)" : "1px solid rgba(255, 255, 255, 0.20)", paddingTop:"5px" }}>
                                                            {producto.cantProduct} {producto.nombre} <span style={{ float: "right" }}>₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([producto.subTotal])}</span>
                                                            
                                                            <p style={{marginTop:"0px", marginBottom:"0px", paddingLeft:"13px"}}>
                                                                {producto.size.name && `${producto.size.name} ,`} {producto.flavors.name && `${producto.flavors.name} ,`}
                                                            </p>
                                                            {producto.drinks.map((drink, drinkIndex) => (
                                                                <span style={{marginTop:"0px", marginBottom:"0px", paddingLeft:"13px"}} key={drinkIndex}>{drink.name && `${drink.name},`}</span>
                                                            ))}
                                                            {producto.extras.map((extra, extraIndex) => (
                                                                <span style={{marginTop:"0px", marginBottom:"0px", paddingLeft:"13px"}} key={extraIndex}>{extra.name && `${extra.name},`}</span>
                                                            ))}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                            <br />

                                            <br />
                                            <br />
                                        </div>

                                        <div style={{ float: "right", width: "48%" }}>
                                            <div style={{ textAlign: "center" }}>{qrImage ? <img src={qrImage} alt="QR Code" style={{ width: isPdfGenerating ? "60%" : isMobile ? "70%" : "60%" }} /> : <p>Generating QR Code...</p>}</div>
                                            {/* <div>
                                            <p style={{ fontWeight: "600", fontSize: "14px" }}>
                                                CONSECUTIVO: <span style={{ fontWeight: "100" }}>00101099040000101642</span>
                                            </p>
                                        </div> */}
                                            {/* <div>
                                            <p style={{ fontWeight: "600", lineHeight: isPdfGenerating ? "28px" : isMobile ? "16px" : "28px", fontSize: isPdfGenerating ? "14px" : isMobile ? "11px" : "14px" }}>
                                                CLAVE: <span style={{ fontWeight: "100" }}>5061705240031016888700010109</span>
                                            </p>
                                        </div> */}
                                            <div></div>
                                        </div>
                                        <br />
                                    </div>


                                    <div style={{ textAlign: "center", color: "#fff", textDecoration: "none", marginTop:"50%"}}>
                                        <a href="https://ul.waze.com/ul?preview_venue_id=180748387.1807549410.6704307&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location">
                                            <img style={{ marginRight: "24px", marginTop: "18px", width: isPdfGenerating ? "6%" : "6%" }} src={isPdfGenerating ? "/content/img/SC/wazenegro.png" : "/content/img/SC/wazeblanco.png"} />
                                        </a>
                                        <a href="https://maps.app.goo.gl/pFJhU8yXhSjwCYWF8">
                                            <img width="51px" src={isPdfGenerating ?  "/content/img/SC/mapsnegro.png" : "/content/img/SC/mapsblanco.png"} />
                                        </a>
                                        <div>
                                            <p style={{ marginBottom: "0px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>Estamos ubicados en City Place Santa Ana.</p>
                                            <p style={{ fontWeight: "600", fontSize: "24px", marginTop: "0px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>ENCUÉNTRANOS EN WAZE Y GOOGLE MAPS</p>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                            <p style={{ lineHeight: "20px", fontSize: "14px", color: isPdfGenerating ? "black" : isMobile ? "white" : "white" }}>POR LA COMPRA DE ENTRADAS EN EL CINE OBTIENE 1 HORA DE PARQUEO GRATIS.</p>

                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                        </>
                    )}
                </div>
            </AppLayout>
        </>
    );
}
