import { Accordion, AccordionDetails, AccordionSummary, Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FoodAccordion from "./FoodAccordion";
import { useDispatch, useSelector } from "react-redux";

import { setdeleteArticule } from "../../../store/checkout/checkoutSlice";
import { NavLink } from "react-router-dom";
import "../../styles/step4.css";

import { convertTime } from "../../../hooks/useTimeBillboard";
import { FeeServiceAmount } from "../../../api";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "transparent",
        color: "#fff",
        fontFamily: "FuturaP-Bold",
        textTransform: "uppercase",
        fontSize: 32,
        borderBottom: "none",
        "@media only screen and (max-width: 600px)": {
            backgroundColor: "transparent",
            color: "#fff",
            fontFamily: "FuturaP-Bold",
            textTransform: "uppercase",
            fontSize: 15,
            borderBottom: "none",
        },
    },
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: "transparent",
        color: "#fff",
        fontFamily: "FuturaP-Bold",
        textTransform: "uppercase",
        fontSize: 32,
        borderBottom: "none",
        "@media only screen and (max-width: 600px)": {
            backgroundColor: "transparent",
            color: "rgba(255, 255, 255, 0.70)",
            fontFamily: "FuturaP-Bold",
            textTransform: "uppercase",
            fontSize: 15,
            borderBottom: "none",
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [createData("Frozen yoghurt", 159, 6.0, 24, 4.0), createData("Ice cream sandwich", 237, 9.0, 37, 4.3), createData("Eclair", 262, 16.0, 24, 6.0), createData("Cupcake", 305, 3.7, 67, 4.3), createData("Gingerbread", 356, 16.0, 49, 3.9)];

const Step4 = () => {
    /* Hook de Redux para acceder al estado de Redux: Extrae `step4` del reducer `checkout` del estado de Redux */
    const { step1, step2, step3, step4, addSeating, tokenSessions, message } = useSelector((state) => state.checkout);

    /* Calcula el total de los productos seleccionados en la etapa 4, sumando los subtotales */
    const total = step4.articules.reduce((acc, producto) => acc + parseFloat(producto.subTotal), 0);

    /* Verifica si la lista de artículos en el paso 4 no está vacía */
    const isNotEmpty = step4.articules.length > 0;

    const [expanded, setExpanded] = useState(false);

    /* Hook para utilizar el dispatcher de Redux, permitiendo acciones para modificar el estado global */
    const dispatch = useDispatch();
    /* Función para eliminar un artículo, despacha la acción `setdeleteArticule` con el id del artículo como payload */
    const deleteArticule = (idArticule) => {
        dispatch(setdeleteArticule({ idArticule }));
    };

    const ariaLabel = { "aria-label": "description" };
    const theme = useTheme(); // Obtiene el tema actual
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Determina si es móvil basado en el tamaño de la pantalla
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil

    const { allMoviesBillboard } = useSelector((state) => state.home);
    const { activeTanda } = useSelector((state) => state.checkout);

    // Llamar al hook personalizado para manejar los efectos de activeTanda


    // Encontrar la película correspondiente en allMoviesBillboard usando idMasterMovie y activeTanda.idMaster
    const imageMaster = allMoviesBillboard.find((movie) => movie.idMasterMovie === activeTanda.idMaster);

    const totalQuantity = step2.priceList.filter((item) => item.quantity > 0).reduce((sum, item) => sum + item.quantity, 0);

    // Calcular la cantidad total de personas
    const totalquantityPeople = step2.quantityPeople > 0 ? step2.quantityPeople : 0;

    // Calcular la tarifa de servicio
    const FeeService = totalquantityPeople * FeeServiceAmount;

    // Calcular el precio total de la lista
    const totalPriceList = step2.quantityPeople > 0 ? step2.totalStep2 : 0;

    // Calcular el subtotal de los artículos en el paso 4
    const totalSubTotal = step4.articules.reduce((acc, producto) => acc + producto.subTotal, 0);

    // Calcular el pago total sumando el subtotal, la tarifa de servicio y el precio total de la lista
    const totalPayment = totalSubTotal + FeeService + totalPriceList;

    

    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };
    const handleOpenAccordion = (panelKey) => (event, isExpanded) => {
        if (isExpanded) {
            setExpanded(isExpanded ? panelKey : false);
        } else {
            expanded === panelKey ? setExpanded(false) : setExpanded(panelKey);
        }
    };

    return (
        <Stack direction={isMobile || isTablet? "column" : "row"} justifyContent="flex-start" alignItems="flex-start" spacing={20} style={{ marginTop: isMobile ? "25%" : "2%", paddingLeft: "10%" }}>
            <Stack direction={isMobile|| isTablet ? "row" : "column"} spacing={isMobile || isTablet? 2 : 1} alignItems={isMobile || isTablet? "center" : "center"} style={{ minWidth: isMobile || isTablet? "" : "257px" }}>
                <img src={imageMaster.image} alt="imagen" width={isMobile || isTablet? "120px" : "175px"} height={isMobile || isTablet? "155px" : "247px"} />
                <Stack>
                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "12px" : "13px", textTransform: "uppercase" }}>Pelicula:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "12px" : "13px", fontWeight: "100", textTransform: "uppercase" }}>{activeTanda.name_movie_bp}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "12px" : "13px", textTransform: "uppercase" }}>DÍA:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "12px" : "13px", fontWeight: "100", textTransform: "uppercase" }}>{convertTime(activeTanda.date_time_shifts_bp)}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "12px" : "13px", textTransform: "uppercase" }}>SALA:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "12px" : "13px", fontWeight: "100", textTransform: "uppercase" }}>{activeTanda.room_name_shifts_bp}</Typography>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: isMobile ? "12px" : "13px", textTransform: "uppercase" }}>HORA:</Typography>
                        <Typography style={{ color: "white", fontFamily: "FuturaP-Light", fontSize: isMobile ? "12px" : "13px", fontWeight: "100", textTransform: "uppercase" }}>{activeTanda.hora}</Typography>
                    </Stack>
                </Stack>

                <Stack style={{ borderTop: "1px solid rgba(255, 255, 255, 0.20)", borderBottom: "1px solid rgba(255, 255, 255, 0.20)", paddingBottom: "5%", display: isMobile || isTablet? "none" : "flex" }} spacing={1}>
                    <Typography className="tituloOrden">DETALLES DE ORDEN</Typography>
                    <Typography style={{ fontFamily: "FuturaP-Bold", color: "#FFF", fontSize: "16px", textTransform: "uppercase" }}>entradas</Typography>
                    <Stack style={{ paddingLeft: "10px" }} spacing={1}>
                        {step2.priceList
                            .filter((item) => item.quantity > 0)
                            .map((item, index) => (
                                // Agregar key único aquí
                                <Stack key={item.name + index} direction="row" justifyContent="space-between">
                                    <Stack direction="row" spacing={1}>
                                        <Typography className="detalleOrden">{item.name}</Typography>
                                        <Typography className="detalleOrden">({item.quantity})</Typography>
                                    </Stack>
                                    <Typography className="detalleOrden">₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([item.subtotal])}</Typography>
                                </Stack>
                            ))}
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <Typography style={{ fontFamily: "FuturaP-Bold", color: "#7E7E7E", fontSize: "12px", textTransform: "uppercase" }}>asientos</Typography>
                        <Typography style={{ fontFamily: "FuturaP-Bold", color: "#7E7E7E", fontSize: "12px", textTransform: "uppercase" }}>
                            {step3.seating.length === 1
                                ? step3.seating[0].nombreButaca
                                : step3.seating.map((item, index) => (
                                      <span key={item.nombreButaca}>
                                          {item.nombreButaca}
                                          {index < step3.seating.length - 1 && ", "}
                                      </span>
                                  ))}
                        </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography style={{ fontFamily: "FuturaP-Bold", color: "#FFF", fontSize: "15px", textTransform: "uppercase" }}>CARGO DE SERVICIO</Typography>

                        <Typography className="detalleOrden">₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([totalQuantity * FeeServiceAmount])}</Typography>
                    </Stack>
                    <Typography style={{ fontFamily: "FuturaP-Light", color: "#FFF", fontSize: "12px", textTransform: "uppercase", fontWeight: "100", marginTop: "-2px" }}>Cargo por boleto ₡{FeeServiceAmount} colones</Typography>
                    <Stack style={{ display: isNotEmpty ? (isMobile ? "none" : "block") : "none" }}>
                        <Typography className="tituloOrden">CONFITERÍA</Typography>

                        <Stack spacing={2} style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.20)", paddingBottom: "5%" }}>
                            {step4.articules.map((producto, index) => (
                                // Agregar key único aquí
                                <Stack key={producto.idArt + producto.key + index} direction="row" justifyContent="space-between" spacing={1}>
                                    <Stack>
                                        <Stack direction="row" spacing={1}>
                                            <Typography className="detalleOrden">{producto.cantProduct}</Typography>
                                            <Stack>
                                                <Typography className="detalleOrden">{producto.nombre}</Typography>
                                                <Stack direction="column" spacing={0.5}>
                                                    {/* <Typography className="detalle2Orden">{producto.size.name && `${producto.size.name} ,`}{producto.flavors.name && `${producto.flavors.name} ,`}</Typography> */}
                                                    <Typography className="detalle2Orden">{producto.size.name && `${producto.size.name} ,`}</Typography>
                                                    <Typography className="detalle2Orden">{producto.flavors.name && `${producto.flavors.name} ,`} </Typography>
                                                    {producto.drinks.map((drink, drinkIndex) => (
                                                        // Agregar key único aquí
                                                        <Typography key={`${drink.name}-${drink.id}-${drink.key}-${producto.idArt}-${producto.key}-${index}-${drinkIndex}`} className="detalle2Orden">
                                                            {drink.name && `${drink.name},`}
                                                        </Typography>
                                                    ))}
                                                    {producto.extras.map((extra, extraIndex) => (
                                                        // Agregar key único aquí
                                                        <Typography key={`${extra.id}-${extra.key}-${producto.idArt}-${producto.key}-${index}-${extraIndex}`} className="detalle2Orden">
                                                            {extra.name && `${extra.name},`}
                                                        </Typography>
                                                    ))}
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Stack>

                                    <Typography className="detalleOrden" style={{ minWidth: "60px" }}>
                                        ₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([producto.subTotal])}
                                    </Typography>
                                    <Button onClick={() => deleteArticule(producto.idArt)} style={{ minWidth: "29px" }} className="btndelete">
                                        <img src="/content/img/SC/delete.svg" />
                                    </Button>
                                </Stack>
                            ))}
                        </Stack>
                        <Stack direction="row" justifyContent="flex-end" spacing={2}>
                            <Typography className="totalOrden">TOTAL</Typography>
                            <Typography className="totalOrden">₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([totalPayment])}</Typography>
                        </Stack>

                        {/* <Button className="btnPay">
                        <NavLink className={"navLinkPay"} to="/confiteria/payment">
                            Pagar
                        </NavLink>
                    </Button> */}
                    </Stack>
                </Stack>
            </Stack>

            <Stack spacing={isMobile || isTablet? 2 : 5} direction="column" justifyContent="flex-start" className="confPrincipal" alignItems={isMobile || isTablet? "center" : "flex-start"} style={{ marginTop: isMobile || isTablet? "-2%" : "0%" }}>
                <Stack direction="row" spacing={2} style={{ marginTop: isMobile || isTablet? "8%" : "0%" }}>
                    <Typography style={{ color: "white", fontFamily: "FuturaP-ExtraBold", fontSize: isMobile ? "15px" : "32px", textTransform: "uppercase", backgroundColor: "#66B02E", paddingTop: isMobile ? "3px" : "3px", paddingLeft: isMobile ? "8px" : "15px", paddingRight: isMobile ? "8px" : "14px", paddingBottom: isMobile ? "1px" : "1px", borderRadius: "100%", display: isMobile || isTablet? "block" : "none", marginLeft: isMobile || isTablet? "-20%" : "0%" }}>4</Typography>
                    <Typography style={{ color: "white", fontFamily: "FuturaP-ExtraBold", fontSize: isMobile ? "15px" : "32px", fontWeight: "100", textTransform: "uppercase" }}>CONFITERÍA</Typography>
                </Stack>
                <Typography style={{ color: "white", fontFamily: "FuturaP-Bold", fontSize: "12px", textAlign: "center", display: isMobile || isTablet? "block" : "none", marginLeft: "-15%", width: "90%" }}> ¿Sabías que al comprar tus snacks en la confitería del cine puedes agilizar el proceso y evitar las colas?</Typography>
                <Accordion style={{ display: isMobile || isTablet? "block" : "none", width: "105%", marginLeft: "-24%", backgroundColor: "transparent", borderBottom: "1px solid rgba(255, 255, 255, 0.17)", borderTop: "1px solid rgba(255, 255, 255, 0.17)" }} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header" className="tituloacordeon" style={{ justifyContent: "center" }}>
                        <Typography style={{ color: "#fff", textAlign: "center", fontFamily: "FuturaP-Bold", fontSize: "15px" }}>DETALLES DE ORDEN</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack direction="row" spacing={1}>
                            <Stack style={{ minWidth: "45%" }}>
                                <Typography style={{ fontFamily: "FuturaP-Bold", color: "#FFF", fontSize: "10px", textTransform: "uppercase" }}>entradas</Typography>

                                {step2.priceList
                                    .filter((item) => item.quantity > 0)
                                    .map((item, index) => (
                                        // Agregar key único aquí
                                        <Stack key={item.name + index} direction="row" justifyContent="space-between">
                                            <Stack direction="row" spacing={1}>
                                                <Typography className="detalleOrden">{item.name}</Typography>
                                                <Typography className="detalleOrden">({item.quantity})</Typography>
                                            </Stack>
                                            <Typography className="detalleOrden">₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([item.subtotal])}</Typography>
                                        </Stack>
                                    ))}

                                <Stack direction="row" spacing={1}>
                                    <Typography style={{ fontFamily: "FuturaP-Bold", color: "#7E7E7E", fontSize: "8px", textTransform: "uppercase" }}>asientos</Typography>
                                    <Typography style={{ fontFamily: "FuturaP-Bold", color: "#7E7E7E", fontSize: "8px", textTransform: "uppercase" }}>
                                        {step3.seating.length === 1
                                            ? step3.seating[0].nombreButaca
                                            : step3.seating.map((item, index) => (
                                                  <span key={item.nombreButaca}>
                                                      {item.nombreButaca}
                                                      {index < step3.seating.length - 1 && ", "}
                                                  </span>
                                              ))}
                                    </Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography style={{ fontFamily: "FuturaP-Bold", color: "#FFF", fontSize: "8px", textTransform: "uppercase" }}>CARGO DE SERVICIO</Typography>

                                    <Typography className="detalleOrden">₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([totalQuantity * FeeServiceAmount])}</Typography>
                                </Stack>
                                <Typography style={{ fontFamily: "FuturaP-Light", color: "#FFF", fontSize: "8px", textTransform: "uppercase", fontWeight: "100", marginTop: "-2px" }}>Cargo por boleto ₡{FeeServiceAmount} colones</Typography>
                            </Stack>
                            <Stack style={{ display: isNotEmpty ? "block" : "none", minWidth: "40%" }}>
                                <Typography className="tituloOrden">CONFITERÍA</Typography>

                                <Stack spacing={2} style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.20)", paddingBottom: "5%" }}>
                                    {step4.articules.map((producto, index) => (
                                        // Agregar key único aquí
                                        <Stack key={producto.idArt + producto.key + index} direction="row" justifyContent="space-between">
                                            <Stack>
                                                <Stack direction="row" spacing={1}>
                                                    {/* <Typography className="detalleOrden">{producto.cantProduct}</Typography> */}
                                                    <Stack>
                                                        <Typography className="detalleOrden">
                                                            {producto.nombre} ({producto.cantProduct})
                                                        </Typography>
                                                        <Stack direction="column" spacing={0.5}>
                                                            <Typography className="detalle2Orden">{producto.size.name && `${producto.size.name} ,`}</Typography>
                                                            <Typography className="detalle2Orden">{producto.flavors.name && `${producto.flavors.name} ,`} </Typography>
                                                            {producto.drinks.map((drink, drinkIndex) => (
                                                                // Agregar key único aquí
                                                                <Typography key={`${drink.name}-${drink.id}-${drink.key}-${producto.idArt}-${producto.key}-${index}-${drinkIndex}`} className="detalle2Orden">
                                                                    {drink.name && `${drink.name},`}
                                                                </Typography>
                                                            ))}
                                                            {producto.extras.map((extra, extraIndex) => (
                                                                // Agregar key único aquí
                                                                <Typography key={`${extra.id}-${extra.key}-${producto.idArt}-${producto.key}-${index}-${extraIndex}`} className="detalle2Orden">
                                                                    {extra.name && `${extra.name},`}
                                                                </Typography>
                                                            ))}
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Stack>

                                            <Typography className="detalleOrden">₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([producto.subTotal])}</Typography>
                                            <Button onClick={() => deleteArticule(producto.idArt)} style={{ minWidth: "54px" }}>
                                                <img src="/content/img/SC/delete.svg" width={"20%"} />
                                            </Button>
                                        </Stack>
                                    ))}
                                </Stack>
                                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                    <Typography className="totalOrden">TOTAL</Typography>
                                    <Typography className="totalOrden">₡ {new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format([totalPayment])}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </AccordionDetails>
                </Accordion>

                <Stack>
                    <FoodAccordion expanded={expanded} handleChange={handleOpenAccordion} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Step4;
