import React, { useState, useEffect } from "react";
import { Container, Typography, Grid, TextField, Select, MenuItem, Checkbox, Button, FormControl, InputLabel, FormControlLabel } from "@mui/material";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import { InserApiAritucule, getAritucule } from "../../../store/checkout";
import "../../styles/formConfi.css";
const FormConfiteria = () => {


    const { step4 } = useSelector((state) => state.checkout);
    const dataProducts = step4.dataProducts;
    const [category, setCategory] = useState("");
    const [productId, setProductId] = useState("");
    const [isTypeArticule, setIsTypeArticule] = useState("");
    const [key, setKey] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState(0);
    const [image, setImage] = useState("");
    const [groupGlobal, setGroupGlobal] = useState("");
    const [mandatorySize, setMandatorySize] = useState(false);
    const [sizes, setSizes] = useState([]);
    const [flavors, setFlavors] = useState([]);
    const [drinks, setDrinks] = useState([]);
    const [extras, setExtras] = useState([]);
    const [mandatoryFlavors, setMandatoryFlavors] = useState(false);
    const [mandatoryDrinks, setMandatoryDrinks] = useState(false);
    const [drinkQuantity, setDrinkQuantity] = useState(1);
    const [groupDrinks, setGroupDrinks] = useState("");
    const [mandatoryExtras, setMandatoryExtras] = useState(false);
    const [extraQuantity, setExtraQuantity] = useState(0);
    const [groupExtras, setGroupExtras] = useState("");
    const dispatch = useDispatch();
    useEffect(() => {
        populateCategorySelect();
    }, []);


    const populateCategorySelect = async () => {

        await dispatch(getAritucule());

        if (dataProducts.length > 0) {
            setCategory(dataProducts[0].id);
        }
    };

    const generateKey = (id, prefix, lineIndex, suffix) => {
        return `${id}-${prefix}-${lineIndex}-${suffix}`;
    };

    const handleAddSize = () => {
        setSizes([...sizes, { id: "", key: "", name: "", price: 0, status: true, select: false }]);
    };

    const handleAddFlavor = () => {
        setFlavors([...flavors, { id: "", key: "", name: "", price: 0, status: true, select: false }]);
    };

    const handleAddDrink = () => {
        setDrinks([
            ...drinks,
            {
                id: "",
                key: "",
                name: "",
                status: true,
                select: false,
                cantSelect: 1,
                groupDrinks: groupDrinks,
            },
        ]);
    };

    const handleAddExtra = () => {
        setExtras([
            ...extras,
            {
                id: "",
                key: "",
                name: "",
                price: 0,
                status: true,
                select: false,
                applyDefault: false,
                groupExtras: groupExtras,
            },
        ]);
    };

    const handleChange = (index, array, field, value, setArray) => {
        const updatedArray = [...array];
        updatedArray[index][field] = value;
        // Regenerate the key when ID changes
        if (field === "id") {
            updatedArray[index].key = generateKey(productId, array === sizes ? "sizes" : array === flavors ? "flavors" : array === drinks ? "drinks" : "extras", index + 1, value);
        }
        setArray(updatedArray);
    };

    const handleRemove = (index, array, setArray) => {
        setArray(array.filter((_, i) => i !== index));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const productData = {
            id: parseInt(productId),
            isTypeArticule: parseInt(isTypeArticule),
            key: key,
            select: false,
            totalSizes: 0, // Sumatoria de precios de tamaños
            totalFlavors: 0, // Sumatoria de precios de sabores
            totalDrinks: 0, // Sumatoria de cantidades de bebidas
            totalExtras: 0, // Sumatoria de precios de extras
            totalProducts: parseInt(price), // Puedes ajustar esta lógica según lo que necesites
            totalIndividual: parseInt(price), // Puedes ajustar esta lógica según lo que necesites
            quantity: parseInt(quantity),
            name: name,
            description: [description],
            price: parseInt(price),
            image: image,
            variants: [
                {
                    groupGlobal: parseInt(groupGlobal),
                    mandatorySize: mandatorySize,
                    sizes: sizes.length
                        ? sizes.map((size, index) => ({
                              id: parseInt(size.id),
                              key: generateKey(productId, "sizes", index + 1, size.id),
                              name: size.name,
                              price: parseInt(size.price),
                              status: size.status,
                              select: size.select,
                              default: size.select,
                          }))
                        : [],
                    mandatoryFlavors: mandatoryFlavors,
                    flavors: flavors.length
                        ? flavors.map((flavor, index) => ({
                              id: parseInt(flavor.id),
                              key: generateKey(productId, "flavors", index + 1, flavor.id),
                              name: flavor.name,
                              price: parseInt(flavor.price),
                              status: flavor.status,
                              select: flavor.select,
                              default: flavor.select,
                              group: parseInt(groupGlobal),
                          }))
                        : [],
                    mandatoryDrinks: mandatoryDrinks,
                    drinks: drinks.length
                        ? [
                              {
                                  quantity: parseInt(drinkQuantity),
                                  combination: [],
                                  options: drinks.map((drink, index) => ({
                                      id: parseInt(drink.id),
                                      key: generateKey(productId, "drinks", index + 1, drink.id),
                                      name: drink.name,
                                      status: drink.status,
                                      select: drink.select,
                                      cantSelect: parseInt(drink.cantSelect),
                                      groupDrinks: parseInt(drink.groupDrinks),
                                  })),
                              },
                          ]
                        : [
                              {
                                  quantity: 0,
                                  combination: [],
                                  options: [],
                              },
                          ],
                    mandatoryExtras: mandatoryExtras,
                    extras: extras.length
                        ? [
                              {
                                  quantity: parseInt(extraQuantity),
                                  default: extras.filter((extra) => extra.applyDefault).map((extra) => extra.id.toString()),
                                  combination: extras.filter((extra) => extra.applyDefault).map((extra) => extra.id.toString()),
                                  options: extras.map((extra, index) => ({
                                      id: parseInt(extra.id),
                                      key: generateKey(productId, "extras", index + 1, extra.id),
                                      name: extra.name,
                                      price: parseInt(extra.price),
                                      status: extra.status,
                                      select: extra.select,
                                      applyDefault: extra.applyDefault,
                                      groupExtras: parseInt(extra.groupExtras),
                                  })),
                              },
                          ]
                        : [
                              {
                                  quantity: 0,
                                  default: [],
                                  combination: [],
                                  options: [],
                              },
                          ],
                },
            ],
            status: true,
        };

        await dispatch(InserApiAritucule(category, productData));
    };

    useEffect(() => {
        setKey(`${productId}-category-${category}`);
    }, [productId, category]);

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Formulario Completo
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth required>
                            <InputLabel id="category-label">Seleccionar Categoría</InputLabel>
                            <Select labelId="category-label" id="category" value={category} label="Seleccionar Categoría" onChange={(e) => setCategory(e.target.value)}>
                                {dataProducts.map((cat) => (
                                    <MenuItem key={cat.id} value={cat.id}>
                                        {`${cat.id} - ${cat.title}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth label="ID DEL PRODUCTO / COMBO" type="number" required value={productId} onChange={(e) => setProductId(e.target.value)} onBlur={() => setKey(`${productId}-category-${category}`)} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth required>
                            <InputLabel id="isTypeArticule-label">Tipo de Articulo</InputLabel>
                            <Select labelId="isTypeArticule-label" id="isTypeArticule" value={isTypeArticule} label="Tipo de Articulo" onChange={(e) => setIsTypeArticule(e.target.value)}>
                                <MenuItem value={0}>0 - Articulo individual sin escogencias (id de producto)</MenuItem>
                                <MenuItem value={1}>1 - Combo (id de producto)</MenuItem>
                                <MenuItem value={2}>2 - Articulo individual con escogencias (tamaño) (id de size)</MenuItem>
                                <MenuItem value={3}>3 - Articulo individual con escogencias (sabor) (id de flavor)</MenuItem>
                                <MenuItem value={4}>4 - Articulo individual con escogencias (bebida) (id de drink)</MenuItem>
                                <MenuItem value={5}>5 - Articulo individual con escogencias (extras) (id de extra)</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="LLAVE UNICA"
                            value={key}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth label="Cantidad por Defecto" type="number" required value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth label="Nombre del Producto / Combo" required value={name} onChange={(e) => setName(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth label="Descripción del Producto / Combo" required value={description} onChange={(e) => setDescription(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth label="Precio" type="number" required value={price} onChange={(e) => setPrice(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth label="Imagen del Producto / Combo" required value={image} onChange={(e) => setImage(e.target.value)} />
                    </Grid>
                </Grid>
                <hr />
                <Typography variant="h5">Variantes del Producto</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth label="Numero ID Global del Producto" type="number" required value={groupGlobal} onChange={(e) => setGroupGlobal(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControlLabel className="formConfi" control={<Checkbox checked={mandatorySize} onChange={(e) => setMandatorySize(e.target.checked)} className="formConfi"/>} label="Selección Obligatoria Tamaño" />
                    </Grid>
                </Grid>
                <hr />
                <Typography variant="h6">Agregar Tamaño</Typography>
                {sizes.map((size, index) => (
                    <Grid container spacing={3} key={index} className="size-entry">
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="ID" type="number" required value={size.id} onChange={(e) => handleChange(index, sizes, "id", e.target.value, setSizes)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Key"
                                value={size.key}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="Name" required value={size.name} onChange={(e) => handleChange(index, sizes, "name", e.target.value, setSizes)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="Price" type="number" required value={size.price} onChange={(e) => handleChange(index, sizes, "price", e.target.value, setSizes)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Status</InputLabel>
                                <Select value={size.status ? "true" : "false"} onChange={(e) => handleChange(index, sizes, "status", e.target.value === "true", setSizes)}>
                                    <MenuItem value="true">Mostrar</MenuItem>
                                    <MenuItem value="false">Ocultar</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Preseleccionado</InputLabel>
                                <Select value={size.select ? "true" : "false"} onChange={(e) => handleChange(index, sizes, "select", e.target.value === "true", setSizes)}>
                                    <MenuItem value="true">Preseleccionado</MenuItem>
                                    <MenuItem value="false">No Preseleccionado</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="secondary" onClick={() => handleRemove(index, sizes, setSizes)}>
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                ))}
                <Button variant="contained" color="primary" onClick={handleAddSize}>
                    Agregar Tamaño
                </Button>
                <hr />
                <Typography variant="h6">Agregar Sabores</Typography>
                <FormControlLabel className="formConfi" control={<Checkbox checked={mandatoryFlavors} onChange={(e) => setMandatoryFlavors(e.target.checked)} className="formConfi"/>} label="Selección Obligatoria Sabor" />
                {flavors.map((flavor, index) => (
                    <Grid container spacing={3} key={index} className="flavor-entry">
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="ID" type="number" required value={flavor.id} onChange={(e) => handleChange(index, flavors, "id", e.target.value, setFlavors)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Key"
                                value={flavor.key}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="Name" required value={flavor.name} onChange={(e) => handleChange(index, flavors, "name", e.target.value, setFlavors)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="Price" type="number" required value={flavor.price} onChange={(e) => handleChange(index, flavors, "price", e.target.value, setFlavors)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Status</InputLabel>
                                <Select value={flavor.status ? "true" : "false"} onChange={(e) => handleChange(index, flavors, "status", e.target.value === "true", setFlavors)}>
                                    <MenuItem value="true">Mostrar</MenuItem>
                                    <MenuItem value="false">Ocultar</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Preseleccionado</InputLabel>
                                <Select value={flavor.select ? "true" : "false"} onChange={(e) => handleChange(index, flavors, "select", e.target.value === "true", setFlavors)}>
                                    <MenuItem value="true">Preseleccionado</MenuItem>
                                    <MenuItem value="false">No Preseleccionado</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="secondary" onClick={() => handleRemove(index, flavors, setFlavors)}>
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                ))}
                <Button variant="contained" color="primary" onClick={handleAddFlavor}>
                    Agregar Sabor
                </Button>
                <hr />
                <Typography variant="h6">Agregar Bebidas</Typography>
                <FormControlLabel className="formConfi" control={<Checkbox checked={mandatoryDrinks} onChange={(e) => setMandatoryDrinks(e.target.checked)} className="formConfi"/>} label="Selección Obligatoria Bebida" />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth label="Cantidad Permitida de Selección" type="number" required value={drinkQuantity} onChange={(e) => setDrinkQuantity(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth label="Grupo de Bebida" type="number" required value={groupDrinks} onChange={(e) => setGroupDrinks(e.target.value)} />
                    </Grid>
                </Grid>
                {drinks.map((drink, index) => (
                    <Grid container spacing={3} key={index} className="drink-entry">
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="ID" type="number" required value={drink.id} onChange={(e) => handleChange(index, drinks, "id", e.target.value, setDrinks)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Key"
                                value={drink.key}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="Name" required value={drink.name} onChange={(e) => handleChange(index, drinks, "name", e.target.value, setDrinks)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Status</InputLabel>
                                <Select value={drink.status ? "true" : "false"} onChange={(e) => handleChange(index, drinks, "status", e.target.value === "true", setDrinks)}>
                                    <MenuItem value="true">Mostrar</MenuItem>
                                    <MenuItem value="false">Ocultar</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Preseleccionado</InputLabel>
                                <Select value={drink.select ? "true" : "false"} onChange={(e) => handleChange(index, drinks, "select", e.target.value === "true", setDrinks)}>
                                    <MenuItem value="true">Preseleccionado</MenuItem>
                                    <MenuItem value="false">No Preseleccionado</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="secondary" onClick={() => handleRemove(index, drinks, setDrinks)}>
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                ))}
                <Button variant="contained" color="primary" onClick={handleAddDrink}>
                    Agregar Bebida
                </Button>
                <hr />
                <Typography variant="h6">Agregar Extras</Typography>
                <FormControlLabel className="formConfi" control={<Checkbox checked={mandatoryExtras} onChange={(e) => setMandatoryExtras(e.target.checked)} className="formConfi"/>} label="Selección Obligatoria Extras" />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth label="Cantidad Permitida" type="number" required value={extraQuantity} onChange={(e) => setExtraQuantity(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth label="Grupo de Extras" type="number" required value={groupExtras} onChange={(e) => setGroupExtras(e.target.value)} />
                    </Grid>
                </Grid>
                {extras.map((extra, index) => (
                    <Grid container spacing={3} key={index} className="extra-entry">
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="ID" type="number" required value={extra.id} onChange={(e) => handleChange(index, extras, "id", e.target.value, setExtras)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Key"
                                value={extra.key}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="Name" required value={extra.name} onChange={(e) => handleChange(index, extras, "name", e.target.value, setExtras)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="Price" type="number" required value={extra.price} onChange={(e) => handleChange(index, extras, "price", e.target.value, setExtras)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Status</InputLabel>
                                <Select value={extra.status ? "true" : "false"} onChange={(e) => handleChange(index, extras, "status", e.target.value === "true", setExtras)}>
                                    <MenuItem value="true">Mostrar</MenuItem>
                                    <MenuItem value="false">Ocultar</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Preseleccionado</InputLabel>
                                <Select value={extra.select ? "true" : "false"} onChange={(e) => handleChange(index, extras, "select", e.target.value === "true", setExtras)}>
                                    <MenuItem value="true">Preseleccionado</MenuItem>
                                    <MenuItem value="false">No Preseleccionado</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Aplicar por Defecto</InputLabel>
                                <Select value={extra.applyDefault ? "true" : "false"} onChange={(e) => handleChange(index, extras, "applyDefault", e.target.value === "true", setExtras)}>
                                    <MenuItem value="true">Aplicar por defecto</MenuItem>
                                    <MenuItem value="false">No aplicar por defecto</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="Grupo de Extras" type="number" required value={extra.groupExtras} onChange={(e) => handleChange(index, extras, "groupExtras", e.target.value, setExtras)} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="secondary" onClick={() => handleRemove(index, extras, setExtras)}>
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                ))}
                <Button variant="contained" color="primary" onClick={handleAddExtra}>
                    Agregar Extra
                </Button>
                <Button type="submit" variant="contained" color="success" className="mt-3">
                    Submit
                </Button>
            </form>
        </Container>
    );
};

export default FormConfiteria;
