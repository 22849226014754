
import { AppRouter } from "./router";

function App() {
    return (
        <>
            {/* llamamos al componente AppRouter para definir hacia donde queremos ir en la aplicación */}
            <AppRouter />
        </>
    );
}

export default App;
