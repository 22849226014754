import { Box, Button, Typography } from "@mui/material"; // Importación de componentes de Material-UI
import { styled } from "@mui/material/styles"; // Importación de la función styled de Material-UI para crear estilos personalizados

// Estilos para el contenedor principal del slider
export const SliderContainer = styled(Box)({
    width: "100%",
    overflow: "hidden",
    position: "relative",
});

// Estilos para cada slide del slider
export const Slide = styled(Box)(({ theme, backgroundimage }) => ({
    width: "100%",
    height: "60vh",
    marginTop: "4%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${backgroundimage})`,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
        height: "45vh",
        marginTop: "2%",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
}));

// Estilos para la superposición en los slides
export const Overlay = styled(Box)(({ theme }) => ({
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.57) 32.82%, #000 76.23%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
        background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.57) 32.82%, #000 76.23%)",
    },
}));

// Estilos para el contenedor de la paginación de los slides
export const PaginationContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    bottom: "10px",
    width: "100%",
    alignItems: "center",
});

// Estilos para los indicadores de la paginación de los slides
export const PaginationDot = styled(Box)(({ isactive }) => ({
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: isactive === "true" ? "white" : "gray",
    margin: "0 5px",
}));

// Estilos para el tipo de película en los slides
export const TipeMovieB = styled(Typography)(({ theme }) => ({
    fontFamily: "FuturaP-Light",
    color: "#fff",
    fontSize: "24px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
    },
}));

// Estilos para el título de la película en los slides
export const TitleMovieB = styled(Typography)(({ theme }) => ({
    fontFamily: "FuturaP-ExtraBold",
    color: "#fff",
    fontSize: "50px",
    textTransform: "uppercase",
    [theme.breakpoints.up("xs")]: {
        fontSize: "24px",
    },[theme.breakpoints.up("sm")]: {
        fontSize: "24px",
    },[theme.breakpoints.up("md")]: {
        fontSize: "30px",
    },[theme.breakpoints.up("lg")]: {
        fontSize: "50px",
    },
}));

// Estilos para la descripción de la película en los slides
export const DesciptionMovieB = styled(Typography)(({ theme }) => ({
    fontFamily: "FuturaP-Light",
    color: "#fff",
    fontSize: "20px",
    maxWidth: "700px",
    minWidth: "700px",
    [theme.breakpoints.up("xs")]: {
        fontSize: "12px",
        maxWidth: "350px",
        minWidth: "350px",
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "12px",
        maxWidth: "500px",
        minWidth: "500px",
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "20px",
        maxWidth: "700px",
        minWidth: "700px",
    },
}));

// Estilos para el botón en los slides
export const BtnBanner = styled(Button)(({ theme }) => ({
    backgroundColor: "#67BF47",
    borderRadius: "6px",
    color: "white",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
    padding: "10px",
    fontSize: "19px",
    border: "none",
    fontFamily: "FuturaP-Bold",
    width: "255px",
    height: "58px",
    "&:hover": {
        backgroundColor: "#3e8e41",
    },
    [theme.breakpoints.down("sm")]: {
        width: "124px",
        height: "21px",
        fontSize: "9px",
    },
}));
