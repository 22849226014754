import axios from "axios";

/********************************************** BASE API URL DEFINITION **********************************************/
// Define la URL base de la API
// const apiUrl = window.location.hostname === "localhost" ? "http://localhost:4000/api/v2/" : "https://showtime.rdghub.com/api/v2/";

const apiUrl = window.location.hostname === "localhost" ? "http://localhost:8000/api/v2/" : "https://showtime.rdghub.com/api/v2/";

// Define la URL base de la API para imágenes
const apiUrlImg = window.location.hostname === "localhost" ? "https://showtime.rdghub.com" : "https://showtime.rdghub.com";

/********************************************** TOKEN DE ACCESO         **********************************************/

// Clave secreta (constante, debe almacenarse de forma segura)
const secretKey = "9e-@5Y4cHdQ)5wT!uL*BzR#e^T@6f2X!";

const FeeServiceAmount = 250;

/********************************************** COMMON REQUEST DATA **********************************************/

/**
 * Objeto que contiene los datos comunes para todas las solicitudes a la API.
 * Estos datos se enviarán en cada solicitud a la API.
 *token de acceso para autenticación
 *database: Nombre de la base de datos (PRUEBAS O PRODUCCIÓN)
 *sqlQuery: Consulta SQL (inicialmente vacía)
 *type: Tipo de solicitud (cadena vacía por defecto)
 */
const commonRequestData = {
    token_access: "4jH6k-3m.b@s_T8",
    database: "produccion",
    sqlQuery: "",
    type: "",
};

/********************************************** FUNCTION TO MAKE API REQUESTS **********************************************/

/**
 esta función se encarga de realizar solicitudes a la API, recibe dos parámetros:
    - endpoint: la ruta de la API a la que se realizará la solicitud
    - requestData: los datos que se enviarán en la solicitud
    La función retorna un objeto con dos propiedades:
    - ok: un booleano que indica si la solicitud fue exitosa o no
    - data: los datos obtenidos de la API (si la solicitud fue exitosa)
    - errorMessage: un mensaje de error (si la solicitud falla)
    - se envia commonRequestData y requestData en la solicitud
*/

const fetchData = async (endpoint, requestData) => {
    try {
        const url = `${apiUrl}${endpoint}`;
        const response = await axios.post(url, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
             timeout: 120000 // Timeout de 2 minutos
        });
        return { ok: true, data: response.data };
    } catch (error) {
        return { ok: false, errorMessage: error.message };
    }
};

/********************************************** EXPORT FUNCTIONS AND DATA **********************************************/

// Exporta funciones y datos comunes para su uso en otros módulos
export { fetchData, commonRequestData, secretKey, apiUrlImg, FeeServiceAmount };
