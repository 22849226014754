import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";



export const CircularIndeterminate = () => {
  return (
      <Box sx={{ display: "flex", fontFamily:"FuturaP-Medium", textTransform:"uppercase" }}>
          <CircularProgress color="success"/>
          &#160; &#160;&#160;&#160;Creando Usuario...
      </Box>
  );
}
