
import { commonRequestData, fetchData } from "../../api";



/**
 * Elimina una factura temporal en el servidor.
 *
 * @param {Object} params - Los parámetros necesarios para la operación.
 * @param {string} params.idSesion - El identificador único de la sesión que corresponde a la factura temporal a eliminar.
 * @returns {Promise<Object>} - Una promesa que resuelve con la respuesta del servidor, o lanza un error si la operación falla.
 */
export const insertPymentLog = async ({ idSession, details_json }) => {
    // Crea el cuerpo de la solicitud que se enviará al servidor, combinando datos comunes y el ID de la sesión.
    const requestData = {
        ...commonRequestData,
        idSession, // ID de la sesión
        details_json, // Detalles en formato JSON del pago pendiente
    };

    // Envía la solicitud al endpoint 'cineverse/delete_Temporal_Invoice' usando la función fetchData,
    // que maneja las operaciones asíncronas de red. Retorna la respuesta del servidor.
    return await fetchData("cineverse/insertPymentLog", requestData);
};






/**
 * Elimina una factura temporal en el servidor.
 *
 * @param {Object} params - Los parámetros necesarios para la operación.
 * @param {string} params.idSesion - El identificador único de la sesión que corresponde a la factura temporal a eliminar.
 * @returns {Promise<Object>} - Una promesa que resuelve con la respuesta del servidor, o lanza un error si la operación falla.
 */
export const delete_Temporal_InvoiceAsync = async ({ idSesion }) => {
    // Crea el cuerpo de la solicitud que se enviará al servidor, combinando datos comunes y el ID de la sesión.
    const requestData = {
        ...commonRequestData,
        idSesion,
    };

    // Envía la solicitud al endpoint 'cineverse/delete_Temporal_Invoice' usando la función fetchData,
    // que maneja las operaciones asíncronas de red. Retorna la respuesta del servidor.
    return await fetchData("cineverse/delete_Temporal_Invoice", requestData);
};

/**
 * Elimina una transacción temporal en el servidor.
 *
 * @param {Object} params - Los parámetros necesarios para la operación.
 * @param {string} params.idSesion - El identificador único de la sesión que corresponde a la transacción temporal a eliminar.
 * @returns {Promise<Object>} - Una promesa que resuelve con la respuesta del servidor, o lanza un error si la operación falla.
 */
export const delete_Temporal_transactionAsync = async ({ idSesion }) => {
    // Crea el cuerpo de la solicitud que se enviará al servidor, combinando datos comunes y el ID de la sesión.
    const requestData = {
        ...commonRequestData,
        idSesion,
    };

    // Envía la solicitud al endpoint 'cineverse/delete_Temporal_transactionAsync' usando la función fetchData,
    // que maneja las operaciones asíncronas de red. Retorna la respuesta del servidor.
    return await fetchData("cineverse/delete_Temporal_transactionAsync", requestData);
};



/**
 * Res puesta del banco
 *
 * @param {Object} params - Los parámetros necesarios para la operación.
 * @param {string} params.idSesion - El identificador único de la sesión que corresponde a la factura temporal a eliminar.
 * @returns {Promise<Object>} - Una promesa que resuelve con la respuesta del servidor, o lanza un error si la operación falla.
 */
export const apiModificarInfoDecompra = async ({ idSession, details_json }) => {
    // Crea el cuerpo de la solicitud que se enviará al servidor, combinando datos comunes y el ID de la sesión.
    const requestData = {
        ...commonRequestData,
        idSession, // ID de la sesión
        details_json, // Detalles en formato JSON del pago pendiente
    };

    // Envía la solicitud al endpoint 'cineverse/delete_Temporal_Invoice' usando la función fetchData,
    // que maneja las operaciones asíncronas de red. Retorna la respuesta del servidor.
    return await fetchData("cineverse/insertPymentLog", requestData);
};



export const apidescargaInvoice = async ({ idCompra }) => {
    // Crea el cuerpo de la solicitud que se enviará al servidor, combinando datos comunes y el ID de la sesión.
    const requestData = {
        ...commonRequestData,
        idCompra,
    };

    // Envía la solicitud al endpoint 'cineverse/delete_Temporal_Invoice' usando la función fetchData,
    // que maneja las operaciones asíncronas de red. Retorna la respuesta del servidor.
    return await fetchData("cineverse/apidescargaInvoice", requestData);
};


export const envioCorreoError = async ({ infoData }) => {
    // Crea el cuerpo de la solicitud que se enviará al servidor, combinando datos comunes y el ID de la sesión.
    const requestData = {
        ...commonRequestData,
        infoData,
    };

    // Envía la solicitud al endpoint 'cineverse/delete_Temporal_Invoice' usando la función fetchData,
    // que maneja las operaciones asíncronas de red. Retorna la respuesta del servidor.
    return await fetchData("cineverse/envioCorreoError", requestData);
};
