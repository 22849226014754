

import React from 'react'
import { AppLayout } from '../../layout';
import Checkout from '../../components/componentsCheckout/Checkout';
import CommonPage from '../PageCommon/PageCommon';



export const PageCheckout = () => {
  return (
      <CommonPage>
          <AppLayout>
              <Checkout />
          </AppLayout>
      </CommonPage>
  );
}
