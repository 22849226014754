import { useEffect, useState } from "react"; // Importar hooks useEffect y useState de React
import { IconButton, Stack } from "@mui/material"; // Importar componentes de Material-UI
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material"; // Importar iconos de Material-UI
import { SliderContainer, Slide, Overlay, PaginationContainer, PaginationDot, TipeMovieB, TitleMovieB, DesciptionMovieB, BtnBanner } from "./Functions/slider"; // Importar componentes estilizados personalizados
import { useDispatch, useSelector } from "react-redux"; // Importar el hook useSelector de Redux
import { useNavigate } from "react-router-dom";
import { setActiveMovieInfo } from "../../../store/home";

// Componente principal del slider
const Slider = () => {
    // Obtener estado desde el store de Redux
    const { statusGetSlider, slidesHome } = useSelector((state) => state.home);

    // Estado para controlar la diapositiva actual y si la imagen se ha cargado
    const [currentSlide, setCurrentSlide] = useState(0);
    const [imageLoaded, setImageLoaded] = useState(Array(slidesHome.length).fill(false));

    // Efecto para cambiar automáticamente las diapositivas cada 5 segundos
    useEffect(() => {
        const slideInterval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slidesHome.length);
        }, 5000);

        // Limpiar el intervalo cuando el componente se desmonte
        return () => clearInterval(slideInterval);
    }, [slidesHome.length]);

    // Manejar la carga de imágenes
    const handleImageLoad = (index) => {
        setImageLoaded((prevImageLoaded) => {
            const newImageLoaded = [...prevImageLoaded];
            newImageLoaded[index] = true;
            return newImageLoaded;
        });
    };

    // Función para ir a una diapositiva específica
    const goToSlide = (index) => {
        setCurrentSlide(index);
    };

    // Función para ir a la diapositiva anterior
    const goToPreviousSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? slidesHome.length - 1 : prevSlide - 1));
    };

    // Función para ir a la siguiente diapositiva
    const goToNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slidesHome.length);
    };

    /* Obtiene la función dispatch desde react-redux */
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const getIdMovieInfo = (idMovie) => {
        localStorage.removeItem("idLocalMovie");
        localStorage.setItem("idLocalMovie", idMovie);
        dispatch(setActiveMovieInfo(idMovie));
        navigate("/movie");
    };

    return (
        <SliderContainer>
            {slidesHome.map((slide, index) => (
                <Slide
                    key={index}
                    backgroundimage={imageLoaded[index] ? slide.backgroundImage : "none"}
                    sx={{
                        display: index === currentSlide ? "flex" : "none",
                        position: "relative",
                        overflow: "hidden",
                        backgroundColor: !imageLoaded[index] ? "black" : "transparent",
                        transition: "background-color 1s ease-in-out",
                    }}
                >
                    <img
                        src={slide.backgroundImage}
                        onLoad={() => handleImageLoad(index)}
                        alt=""
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            zIndex: 0,
                        }}
                    />
                    <Stack
                        spacing={2}
                        justifyContent="center"
                        alignItems="flex-start"
                        sx={{
                            marginLeft: { xs: "7%", sm: "2%", md: "-17%", lg: "-46%" },
                            position: "relative",
                            zIndex: 1,
                            marginTop: { xs: "18%", sm: "0%" },
                        }}
                    >
                        {/* Mostrar los textos y botón del banner */}
                        <TipeMovieB className="sobreposition" variant="h4" gutterBottom>
                            {slide.text1}
                        </TipeMovieB>
                        <TitleMovieB className="sobreposition" variant="h4" gutterBottom>
                            {slide.text2}
                        </TitleMovieB>
                        <DesciptionMovieB className="sobreposition" variant="h4" gutterBottom>
                            {slide.text3}
                        </DesciptionMovieB>
                        <BtnBanner onClick={() => getIdMovieInfo(slide.idMasterMovie)} className="sobreposition" variant="contained" color="primary">
                            {slide.buttonText}
                        </BtnBanner>
                    </Stack>
                    <Overlay /> {/* Overlay para efecto de sombra */}
                </Slide>
            ))}
            <PaginationContainer>
                {/* Botón para ir a la diapositiva anterior */}
                <IconButton onClick={goToPreviousSlide} className="prev-button" sx={{ color: "white" }}>
                    <ArrowBackIos />
                </IconButton>
                {/* Puntos de paginación para navegar entre diapositivas */}
                {slidesHome.map((_, index) => (
                    <PaginationDot key={index} isactive={index === currentSlide ? "true" : "false"} onClick={() => goToSlide(index)} />
                ))}
                {/* Botón para ir a la siguiente diapositiva */}
                <IconButton onClick={goToNextSlide} className="next-button" sx={{ color: "white" }}>
                    <ArrowForwardIos />
                </IconButton>
            </PaginationContainer>
        </SliderContainer>
    );
};

export default Slider; // Exportar el componente Slider por defecto
