

import React from 'react'
import { AppLayout } from '../../layout';
import Confiteria from '../../components/componentsConfiteria/Confiteria';
import { useLocation } from 'react-router-dom';
import ViewConfirmacionPago from '../../view/ViewConfiteria/ViewConfirmacionPago';



export const PageConfiteria = () => {
 //como extraer la url actual y extrar cada https://studiocinemascr.com/Bugs/list ejemplo extraer Bugs y list por separado y hacer un consoel.log de cada uno
 const location = useLocation();
 // este codigo divide la url en partes y muestra la posicion 2 de la url
 const path = location.pathname.split("/");

  return (
      <AppLayout>
          {path[1] === "confiteria" && path[2] === "payment" && <ViewConfirmacionPago /> || <Confiteria />}

      </AppLayout>
  );
}
