import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";

export const PaymenPro = () => {
    const [orderid, setOrderid] = useState("");
    const [amount, setAmount] = useState("0.50");
    const [ccnumber, setCcnumber] = useState("4249050601222910");
    const [ccexp, setCcexp] = useState("1029");
    const [cvv, setCvv] = useState("821");
    const [time, setTime] = useState("");
    const [hash, setHash] = useState("");

    const secretKey = "p5fm35mA6wUcweRz8s8VEx793mRYNWz9";

    useEffect(() => {
        const generateUniqueOrderId = () => {
            return `Order-${Math.random().toString(36).substr(2, 9)}`;
        };

        const currentTime = Math.floor(Date.now() / 1000);
        const newOrderId = generateUniqueOrderId();

        setOrderid(newOrderId);
        setTime(currentTime);
        setHash(createHash(newOrderId, amount, currentTime, secretKey));
    }, [amount]);

    const createHash = (orderid, amount, time, secret) => {
        return CryptoJS.MD5(`${orderid}|${amount}|${time}|${secret}`).toString();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        document.getElementById("credomaticForm").submit();
    };

    return (
        <div>
            <form id="credomaticForm" method="post" action="https://credomatic.compassmerchantsolutions.com/api/transact.php" onSubmit={handleSubmit}>
                <input type="hidden" name="type" value="sale" />
                <input type="hidden" name="key_id" value="9745798" />
                <input type="hidden" name="hash" value={hash} />
                <input type="hidden" name="time" value={time} />
                <input type="hidden" name="redirect" value="https://studiocinemascr.com/success" />

                <label htmlFor="orderid">Order ID:</label>
                <input type="text" name="orderid" id="orderid" value={orderid} readOnly />
                <br />
                <label htmlFor="amount">Amount:</label>
                <input type="text" name="amount" id="amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                <br />
                <label htmlFor="ccnumber">Credit Card Number:</label>
                <input type="text" name="ccnumber" id="ccnumber" value={ccnumber} onChange={(e) => setCcnumber(e.target.value)} />
                <br />
                <label htmlFor="ccexp">Expiry Date (MMYY):</label>
                <input type="text" name="ccexp" id="ccexp" value={ccexp} onChange={(e) => setCcexp(e.target.value)} />
                <br />
                <label htmlFor="cvv">CVV:</label>
                <input type="text" name="cvv" id="cvv" value={cvv} onChange={(e) => setCvv(e.target.value)} />
                <br />
                <button type="submit">Submit Payment</button>
            </form>
        </div>
    );
};
