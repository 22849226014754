import { createSlice } from "@reduxjs/toolkit";
import axios from "axios"; // Corrected import statement for axios
// import findProductByConcatenado from "./productsCombination";

// Función genérica para actualizar datos basados en categoryId y productId
const updateProductData = (state, action, updateFunction) => {
    const { categoryId, productId } = action.payload;

    // Encontrar la categoría correspondiente en state.step4.dataProducts
    const category = state.step4.dataProducts.find((cat) => cat.id === categoryId);
    if (!category) return;

    // Encontrar el producto correspondiente dentro de la categoría
    const product = category.products.find((prod) => prod.id === productId);
    if (!product) return;

    // Ejecutar la función de actualización específica para el producto encontrado
    updateFunction(product, action.payload);


    if (product.isTypeArticule === 0 || product.isTypeArticule === 4 || product.isTypeArticule === "temporal" ) {
        const total = product.quantity * product.price;
        product.totalProducts = total;
        return;
    }


    // Calcular el total de productos considerando tamaños, sabores, bebidas y extras multiplicado por la cantidad
    product.totalProducts = (product.totalSizes + product.totalFlavors + product.totalDrinks + product.totalExtras) * product.quantity;

};
const generateTimeToken = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const timeString = `${hours}${minutes}${seconds}`;

    // Generar token de 8 letras aleatorias
    const randomLetters = Array(15)
        .fill(null)
        .map(() => String.fromCharCode(65 + Math.floor(Math.random() * 26)))
        .join("");

     localStorage.removeItem("rft");
     localStorage.removeItem("ss");
    // Combinar tiempo con token
    const token = `${randomLetters}${timeString}`;
    return token;
};

// Creamos el slice de Redux para el estado de la página de inicio
export const checkoutSlice = createSlice({
    name: "checkout",
    initialState: {
        isPaymentInProgress: false,
        payment: {
            step: 0,
            message: " ",
            status: false,
            restore: false,
            transactionId: "",
            amount: 0,
            currency: "USD",
            paymentMethod: "",
            customerId: "",
            timestamp: "",
            numberCard: "",
            nameUser: "",
            expiryNumers: "",
            cvvNumber: "",
            items: [],
        },
        timeToken: "",
        step1: {
            complete: false,
            data: {
                nombre: "",
                apellidos: "",
                correo: "",
                cedula: "",
                telefono: "",
            },
        }, // Initial state object for step1
        step2: {
            complete: false,
            totalStep2: 0,
            quantityPeople: 0,
            priceList: [],
        },
        step3: {
            complete: false,
            seating: [],
        }, // Initial state object for step3 (currently empty)

        step4: {
            business_pro: [],
            dataProducts: [],
            articules: [],
        },

        addSeating: {
            complete: false,
            salaid: 0,
            seatsData: [],
        },
        activeTanda: [],
        tokenSessions: "",
        message: "",
    },
    reducers: {
        restoreStateCheckoutNewBd(state, action) {
            const { peliculaActiva, precioDelista, precioDelistaArray, asientosSeleccionados, articuloSeleccionado, timeToken, tokenSessions, payment } = action.payload;
            state.activeTanda = peliculaActiva;
            state.step2 = precioDelista;
            state.step2.priceList = precioDelistaArray;
            state.step3 = asientosSeleccionados;
            state.step4.articules = articuloSeleccionado || [];
            state.tokenSessions = tokenSessions;
            state.timeToken = timeToken;
            state.payment = payment;
        },

        useNmaeUser(state, action) {
            let numberCard = action.payload;
            state.payment.nameUser = numberCard;
        },
        useTarjet(state, action) {
            let numberCard = action.payload;
            // Extraer los últimos 4 caracteres que sean números
            let lastFourDigits = numberCard.replace(/\D/g, "").slice(-4);
            state.payment.numberCard = lastFourDigits;
        },
        clearTarjet(state, action) {
            state.payment.numberCard = "";
            state.payment.nameUser = "";
        },
        clearArticuleList(state, action) {
            state.step4.articules = [];
        },
        clearPriceList(state, action) {
            state.step2.priceList = [];
            state.step2.totalStep2 = 0;
            state.step2.quantityPeople = 0;
            state.step3.seating = [];
            state.addSeating.seatsData = [];
        },
        insertFactura(state, action) {
            state.step4.business_pro = action.payload;
        },
        cleararticule(state, action) {
            state.step4.articules = [];
            state.addSeating.seatsData = [];
            state.addSeating.complete = false;
            state.step2.priceList = [];
            state.payment.step = 0;
            state.step3.seating = 0;
        },
        InsertArticule(state, action) {
            state.step4.dataProducts = action.payload;
        },
        setPaymentInProgress(state, action) {
            state.isPaymentInProgress = action.payload;
        },
        paymentProcess: (state, action) => {
            const { paso, status, message } = action.payload;
            state.payment.step = paso;
            state.payment.message = message;
            state.payment.status = status;
            state.payment.transactionId = "";
            state.payment.amount = 0;
            state.payment.currency = "USD";
            state.payment.paymentMethod = "";
            state.payment.customerId = "";
            state.payment.timestamp = "";
            state.payment.items = [];
        },

        restoreStateCheckout(state, action) {
            const { step1, step2, step3, step4, addSeating, activeTanda, tokenSessions, timeToken, payment } = action.payload;
            state.step1 = step1;
            state.step2 = step2;
            state.step3 = step3;
            state.step4.articules = step4.articules;
            state.addSeating = addSeating;
            state.activeTanda = activeTanda;
            state.tokenSessions = tokenSessions;
            state.timeToken = timeToken;
            state.payment = payment;
        },
        insertmessage: (state, action) => {
            state.message = action.payload;
        },
        /* insertar el tokenSessions para la session del usuario */
        insertSessionIdSlice: (state, action) => {
            state.tokenSessions = action.payload;
        },

        // Reducer function for updating price list in step2
        updatePriceList: (state, action) => {
            // Destructuring payload
            const { id, quantity, subtotal } = action.payload;

            // Find the index of the item in priceList
            const index = state.step2.priceList.findIndex((item) => item.id === id);

            if (index !== -1) {
                // Update the priceList item using immer
                state.step2.priceList[index].quantity = quantity;
                state.step2.priceList[index].subtotal = subtotal;

                // Calculate totals after the update
                let totalQuantity = 0;
                let totalSubtotal = 0;

                state.step2.priceList.forEach((item) => {
                    totalQuantity += item.quantity;
                    totalSubtotal += item.subtotal;
                });

                state.step2.quantityPeople = totalQuantity;
                state.step2.totalStep2 = totalSubtotal;
            }
        },

        // Reducer function to update the completion status of step1
        updateStep1Complete: (state, action) => {
            state.step1.complete = action.payload;
        },

        // Reducer function to update the completion status of step2
        updateStep2Complete: (state, action) => {
            state.step2.complete = action.payload;
        },

        // Reducer function to update the completion status of step3
        updateStep3Complete: (state, action) => {
            state.step3.complete = action.payload;
        },

        // Reducer function to update the seating data in step3
        updateStep3Seating: (state, action) => {
            // Filter out duplicate values
            const newSeating = action.payload.filter((newSeat) => !state.step3.seating.some((existingSeat) => existingSeat.idButaca === newSeat.idButaca));
            state.step3.seating = [...state.step3.seating, ...newSeating];
        },
        backStep3Seating: (state, action) => {
            // Filter out duplicate values
            state.step3.seating = [...state.step3.seating];
        },

        // Reducer function to remove a seat from the seating data in step3
        removeSeatFromSeating: (state, action) => {
            state.step3.seating = state.step3.seating.filter((seat) => seat.idButaca !== action.payload.idButaca);
        },

        // Reducer function to set addSeating state
        getaddSeating: (state, action) => {
            state.addSeating.complete = true;
            state.addSeating.seatsData = action.payload;
        },

        // Reducer function to set the active checkout tanda
        setActiveChekout: (state, action) => {
            state.activeTanda = action.payload;
            const token = generateTimeToken();
            state.timeToken = token;
        },

        // Reducer function to set the data for step1
        setInsertDataStep1: (state, action) => {
            state.step1.data = action.payload;
        },

        // Reducer function para actualizar tamaños seleccionados en step4
        updatedataProductsSizes: (state, action) => {
            updateProductData(state, action, (product, payload) => {
                const { sizeId, sizePrice } = payload;

                // Asumir que hay solo una variante y encontrarla
                const variant = product.variants[0];
                if (!variant) return;

                // Encontrar los tamaños dentro de la variante
                const sizes = variant.sizes;
                if (!sizes) return;

                // Establecer todas las selecciones de tamaño a false
                sizes.forEach((size) => {
                    if (!size.default || size.default === false) {
                        size.select = false;
                    }
                });

                // Encontrar el tamaño seleccionado y establecer su select a true
                const selectedSize = sizes.find((size) => size.id === sizeId);
                if (selectedSize) {
                    selectedSize.select = true;
                    product.totalSizes = sizePrice; // Actualizar totalSizes con sizePrice
                }
            });
        },

        // Reducer function para actualizar sabores seleccionados en step4
        updatedataProductsflavors: (state, action) => {
            updateProductData(state, action, (product, payload) => {
                const { flavorsId, flavorsPrice } = payload;

                // Asumir que hay solo una variante y encontrarla
                const variant = product.variants[0];
                if (!variant) return;

                // Encontrar los sabores dentro de la variante
                const flavors = variant.flavors;
                if (!flavors) return;

                // Establecer todas las selecciones de sabor a false
                flavors.forEach((flavor) => {
                    if (!flavor.default || flavor.default === false) {
                        flavor.select = false;
                    }
                });

                // Encontrar el sabor seleccionado y establecer su select a true
                const selectedFlavor = flavors.find((flavor) => flavor.id === flavorsId);
                if (selectedFlavor) {
                    selectedFlavor.select = true;
                    product.totalFlavors = flavorsPrice; // Actualizar totalFlavors con flavorsPrice
                }
            });
        },

        // Reducer function para disminuir la cantidad de productos en step4
        updatehandleDecreaseQuantity: (state, action) => {
            updateProductData(state, action, (product) => {
                // Disminuir la cantidad solo si es mayor que 1
                if (product.quantity > 1) {
                    product.quantity -= 1;
                }
            });
        },

        // Reducer function para aumentar la cantidad de productos en step4
        updatehandleIncreaseQuantity: (state, action) => {
            updateProductData(state, action, (product) => {
                // Aumentar la cantidad de productos
                product.quantity += 1;
            });
        },

        // Reducer function para seleccionar todos los tamaños disponibles en step4
        updatefromselectallSize: (state, action) => {
            updateProductData(state, action, (product) => {
                product.variants.forEach((variant) => {
                    // Calcular el total de tamaños seleccionados para el producto
                    product.totalSizes = 0;

                    variant.sizes.forEach((size) => {
                        if (!size.default || size.default === false) {
                            size.select = false;
                        }
                    });
                });
            });
        },
        updatefromselectallFlavor: (state, action) => {
            updateProductData(state, action, (product) => {
                product.variants.forEach((variant) => {
                    // Calcular el total de tamaños seleccionados para el producto
                    product.totalFlavors = 0;

                    variant.flavors.forEach((flavor) => {
                        if (!flavor.default || flavor.default === false) {
                            flavor.select = false;
                        }
                    });
                });
            });
        },

        AddArticule: (state, action) => {
            // Desestructuración del objeto payload para obtener categoryId y productId
            const { categoryId, productId } = action.payload;

            // Busca la categoría correspondiente en el array dataProducts del estado
            const category = state.step4.dataProducts.find((cat) => cat.id === categoryId);
            // Si no se encuentra la categoría, muestra una alerta y retorna el estado sin cambios
            if (!category) {
                alert("Categoría no encontrada.");
                return state;
            }

            // Busca el producto correspondiente dentro de la categoría encontrada
            const product = category.products.find((prod) => prod.id === productId);
            // Si no se encuentra el producto, muestra una alerta y retorna el estado sin cambios
            if (!product) {
                alert("Producto no encontrado.");
                return state;
            }

            // Genera un ID de sesión único utilizando Math.random()
            const sessionId = Math.floor(10000000 + Math.random() * 90000000);
            // Genera un ID de sesión único para el tamaño
            const sessionIdSize = Math.floor(10000000 + Math.random() * 90000000);
            // Genera un ID de sesión único para el sabor
            const sessionIdFl = Math.floor(10000000 + Math.random() * 90000000);

            // Selecciona la primera variante del producto como predeterminada
            const selectedVariant = product.variants[0];
            // Desestructura las propiedades relevantes de la variante seleccionada
            const { mandatorySize, mandatoryFlavors, mandatoryDrinks, mandatoryExtras, groupGlobal } = selectedVariant;

            // Busca un tamaño seleccionado dentro de las opciones de tamaño de la variante
            const selectedSize = selectedVariant.sizes.find((size) => size.select);
            // Si el tamaño es obligatorio y no se ha seleccionado uno, muestra una alerta y retorna el estado sin cambios
            if (mandatorySize && !selectedSize) {
                alert("Para agregar al carrito, debes seleccionar un tamaño.");
                return state;
            }

            // Inicializa un objeto vacío para almacenar los detalles del tamaño seleccionado
            let sizeDetails = {};
            // Si se ha seleccionado un tamaño, crea un objeto con sus detalles
            if (selectedSize) {
                sizeDetails = {
                    id: selectedSize.id,
                    key: selectedSize.key + sessionIdSize, // Crea una clave única combinando la clave original y el ID de sesión
                    name: selectedSize.name,
                    price: selectedSize.price,
                    status: selectedSize.status,
                    select: selectedSize.select,
                    group: selectedSize.group || 0,
                };
            }

            // Busca un sabor seleccionado dentro de las opciones de sabores de la variante
            const selectedFlavors = selectedVariant.flavors.find((flavor) => flavor.select);
            // Si los sabores son obligatorios y no se ha seleccionado uno, muestra una alerta y retorna el estado sin cambios
            if (mandatoryFlavors && !selectedFlavors) {
                alert("Para agregar al carrito, debes seleccionar al menos un sabor.");
                return state;
            }

            // Inicializa un objeto vacío para almacenar los detalles del sabor seleccionado
            let flavorDetails = {};
            // Si se ha seleccionado un sabor, crea un objeto con sus detalles
            if (selectedFlavors) {
                flavorDetails = {
                    id: selectedFlavors.id,
                    name: selectedFlavors.name,
                    key: selectedFlavors.key + sessionIdFl, // Crea una clave única combinando la clave original y el ID de sesión
                    price: selectedFlavors.price,
                    status: selectedFlavors.status,
                    select: selectedFlavors.select,
                    group: selectedFlavors.group,
                };
            }

            // Inicializa un array vacío para almacenar las bebidas seleccionadas
            let selectedDrinks = [];

            // Obtiene los detalles de las bebidas del primer elemento del array de bebidas de la variante seleccionada
            const drinkDetails = selectedVariant.drinks[0];

            // Verifica si las bebidas son obligatorias
            if (mandatoryDrinks) {
                // Comprueba si la cantidad de bebidas seleccionadas coincide con la cantidad requerida
                if (drinkDetails.combination.length !== drinkDetails.quantity) {
                    // Muestra una alerta si no coinciden y retorna el estado actual
                    alert(`Debes seleccionar exactamente ${drinkDetails.quantity} bebida(s).`);
                    return state;
                }
            }

            // Si hay bebidas seleccionadas en la combinación
            // if (drinkDetails.combination.length > 0) {
            //     // Mapea los IDs de las bebidas seleccionadas a sus detalles completos
            //     selectedDrinks = drinkDetails.combination.map((drinkId) => drinkDetails.options.find((drink) => drink.id === parseInt(drinkId, 10)));
            // }
            if (drinkDetails.combination.length > 0) {
                let combinations = drinkDetails.combination;
                let difGroup = drinkDetails.groups;
                selectedDrinks = drinkDetails.combination.map((drinkId) => drinkDetails.options.find((drink) => drink.id === parseInt(drinkId, 10)));
                //console.log("combinations", selectedDrinks);
                if (drinkDetails.quantity > 1) {
                    // Contar cuántas veces aparece cada ID
                    const idCount = selectedDrinks.reduce((acc, drink) => {
                        acc[drink.id] = (acc[drink.id] || 0) + 1;
                        return acc;
                    }, {});

                    // Crear un mapa para almacenar las posiciones de los valores de `groups` a asignar
                    const groupValues = {};
                    let currentIndex = 0;

                    // Recorrer la lista y asignar valores de `groups` a `groupDrinks` para IDs repetidos
                    const updatedDrinks = selectedDrinks.map((drink) => {
                        if (idCount[drink.id] > 1) {
                            if (!groupValues[drink.id]) {
                                groupValues[drink.id] = [];
                            }
                            // Asignar valores en orden según `groups`
                            if (currentIndex >= drinkDetails.groups.length) {
                                currentIndex = 0; // Reiniciar si hay más valores que `groups`
                            }
                            const newGroupValue = drinkDetails.groups[currentIndex++];
                            return { ...drink, groupDrinks: newGroupValue };
                        }
                        return drink;
                    });

                    selectedDrinks = updatedDrinks;
                }
            }

            // Inicializa un array vacío para almacenar los extras seleccionados
            let selectedExtras = [];

            // Obtiene los detalles de los extras del primer elemento del array de extras de la variante seleccionada
            const extrasDetails = selectedVariant.extras[0];

            // Verifica si los extras son obligatorios
            if (mandatoryExtras) {
                // Calcula el total de extras requeridos (cantidad + extras por defecto)
                const totalDefault = extrasDetails.quantity + extrasDetails.default.length;
                // Comprueba si la cantidad de extras seleccionados coincide con el total requerido
                if (extrasDetails.combination.length !== totalDefault) {
                    // Muestra una alerta si no coinciden y retorna el estado actual
                    alert(`Debes seleccionar exactamentes ${extrasDetails.quantity} extras`);
                    return state;
                }
            }

            // Si hay extras seleccionados en la combinación
            if (extrasDetails.combination.length > 0) {
                // Mapea los IDs de los extras seleccionados a sus detalles completos
                selectedExtras = extrasDetails.combination.map((extraId) => extrasDetails.options.find((extra) => extra.id === parseInt(extraId, 10)));
            }

            // Crea un objeto con los detalles del artículo seleccionado
            const selectedArticle = {
                groupGlobal: groupGlobal,
                isCombo: product.isTypeArticule,
                combination: 0,
                idArt: `${category.id}${product.id}${sessionId}`,
                idcategory: category.id,
                idproduct: product.id,
                key: product.key + sessionId,
                nombre: product.name,
                size: sizeDetails,
                flavors: flavorDetails,
                drinks: selectedDrinks,
                extras: selectedExtras,
                cantProduct: product.quantity,
                subTotal: product.totalProducts,
                sp_bp_ecext: [],
            };

            // Agrega el artículo seleccionado al array de artículos en el estado
            state.step4.articules.push(selectedArticle);

            // Obtiene los extras por defecto
            const deFaulExtra = extrasDetails.default;
            // Establece la combinación de extras como los extras por defecto
            extrasDetails.combination = [...deFaulExtra];

            product.quantity = 1;

            // Desestructura algunas propiedades del artículo seleccionado (no se usa en el código proporcionado)
            const { idcategory, idproduct, cantProduct, nombre, subTotal, isCombo, size } = selectedArticle;

            /*
             * Determina el valor de `idProducType` basado en el valor de `isCombo`.
             * Dependiendo del valor de `isCombo`, selecciona un ID diferente para `idProducType`.
             *
             * - Si `isCombo` es 1 o 0, `idProducType` será igual a `idproduct`.
             * - Si `isCombo` es 2, `idProducType` será igual al ID del tamaño del artículo seleccionado (`selectedArticle.size.id`).
             * - Si `isCombo` es 3, `idProducType` será igual al ID del sabor del artículo seleccionado (`selectedArticle.flavors.id`).
             * - Si `isCombo` es 4, `idProducType` será igual al ID de la primera bebida del artículo seleccionado (`selectedArticle.drinks[0].id`).
             * - Si `isCombo` no es ninguno de los valores anteriores, `idProducType` será igual al ID del primer extra del artículo seleccionado (`selectedArticle.extras[0].id`).
             */

            const idProducType = isCombo === 1 || isCombo === 0 || isCombo === "temporal" ? idproduct : isCombo === 2 ? selectedArticle.size.id : isCombo === 3 ? selectedArticle.flavors.id : isCombo === 4 ? selectedArticle.drinks[0].id : selectedArticle.extras[0].id;

            /*
              si SP_padre_temp ==1 significa que existe padre y existe escogencias 
            */

            let SP_padre_temp = 0;

            //console.log("isCombo", isCombo);

            // console.log("isCombo", isCombo);
            if (isCombo === 1 || isCombo === "temporal") {
                SP_padre_temp = 1;
            }

            let isComboTemp = 0;
            if (isCombo === "temporal") {
                isComboTemp = 1;
            }

            const numeroLinea = state.step4.articules.length;

            const arraySP = [
                {
                    SP_isCombo: isComboTemp === 1 ? 1 : isCombo,
                    SP_idproduct: idProducType,
                    SP_comp_id: 4,
                    SP_str: 1,
                    SP_idsession: "",
                    SP_nameProduct: nombre,
                    SP_subTotalProduct: subTotal,
                    SP_cantidad: cantProduct,
                    SP_presentacion: selectedArticle.size && selectedArticle.size.id > 0 ? selectedArticle.size.id : isCombo === 1 ? 1 : 0,
                    SP_number_line: numeroLinea,
                    SP_padre: SP_padre_temp,
                    SP_escogencias: [], // Inicialmente vacío
                    SP_individaules: [], // Inicialmente vacío
                },
            ];

            // Verificar si `selectedArticle.flavors` tiene un sabor seleccionado y agregarlo
            if (SP_padre_temp === 1) {
                const flavor = selectedArticle.flavors.id > 0 ? selectedArticle.flavors.id : 0;

                if (flavor > 0) {
                    arraySP[0].SP_escogencias.push({ ...selectedArticle.flavors }); // Clonando el objeto para evitar problemas con Proxies
                }

                // Verificar si `selectedArticle.drinks` tiene elementos y agregarlos
                const drinks = selectedArticle.drinks.length > 0 ? selectedArticle.drinks : [{ id: 0 }];

                if (drinks.length > 0) {
                    drinks.forEach((drink) => {
                        arraySP[0].SP_escogencias.push({ ...drink }); // Clonando el objeto para evitar problemas con Proxies
                    });
                }

                // Verificar si `selectedArticle.extras` tiene elementos y agregarlos
                const extras = selectedArticle.extras.length > 0 ? selectedArticle.extras : [{ id: 0 }];

                if (extras.length > 0) {
                    extras.forEach((extra) => {
                        if (extra.applyDefault !== undefined) {
                            // Validar que `applyDefault` esté definido
                            if (!extra.applyDefault && extra.id !== 0) {
                                arraySP[0].SP_individaules.push({ ...extra });
                            } else if (extra.applyDefault && extra.id !== 0) {
                                arraySP[0].SP_escogencias.push({ ...extra });
                            }
                        }
                    });
                }
            }

            /* hacen falta de mapear los productos de la combinacion */
            if (SP_padre_temp === 2) {
            }
            if (SP_padre_temp === 3) {
            }
            if (SP_padre_temp === 4) {
            }
            // console.log("arraySP", arraySP);

            selectedArticle.sp_bp_ecext = arraySP;

            // Resetear la selección de variantes del productodonde
            product.variants.forEach((variant) => {
                variant.sizes.forEach((size) => {
                    if (!size.default || size.default === false) {
                        size.select = false;
                    }
                });

                // Iterar sobre flavors y cambiar select a false solo si no tiene default o default es false
                variant.flavors.forEach((flavor) => {
                    if (!flavor.default || flavor.default === false) {
                        flavor.select = false;
                    }
                });

                variant.drinks.forEach((drink) => {
                    // Limpiar el array combination
                    drink.combination = [];

                    // Actualizar el campo select en cada opción
                    // drink.options.forEach((option) => {
                    //     option.select = false;
                    // });
                });

                variant.extras.forEach((extra) => {
                    // Limpiar el array combination
                    extra.combination = [];

                    // Validar si default no está vacío
                    if (extra.default.length > 0) {
                        // Agregar los valores de default a combination
                        extra.combination = [...extra.default];
                    }
                });
            });

            // Reiniciar contadores de producto
            product.totalSizes = 0;
            product.totalFlavors = 0;
            product.totalDrinks = 0;
            product.totalExtras = 0;
            product.totalProducts = 0;
            product.quantity = 1;

            // Devolver el nuevo estado modificado
            return state;
        },

        setdeleteArticule: (state, action) => {
            const { idArticule } = action.payload;

            // Filtrar los elementos de articules manteniendo los demás
            const newArticules = state.step4.articules.filter((articule) => {
                // Comparar con el idArticule que se desea eliminar
                return articule.idArt !== idArticule;
            });

            // Retornar el nuevo estado con newArticules y mantener dataProducts sin cambios
            return {
                ...state,
                step4: {
                    ...state.step4,
                    articules: newArticules,
                },
            };
        },
        selectDrinks: (state, action) => {
            const { categoryId, productId, combination } = action.payload;

            const category = state.step4.dataProducts.find((cat) => cat.id === categoryId);
            if (!category) return state;

            const product = category.products.find((prod) => prod.id === productId);
            if (!product) return state;

            const variant = product.variants[0];
            if (!variant) return state;

            const drinks = variant.drinks;
            if (!drinks) return state;

            // Asigna el arreglo de combinaciones a la propiedad adecuada
            drinks.forEach((drink) => {
                drink.combination = combination; // Asigna el arreglo de combinaciones
            });
        },

        // Acción que se encarga de seleccionar extras para un producto
        selectExtras: (state, action) => {
            // Desestructuramos el payload para obtener los valores necesarios
            const { categoryId, productId, combination } = action.payload;

            // Buscamos la categoría correspondiente en el estado
            const category = state.step4.dataProducts.find((cat) => cat.id === categoryId);
            if (!category) return state; // Si no se encuentra la categoría, retornamos el estado sin cambios

            // Buscamos el producto correspondiente dentro de la categoría
            const product = category.products.find((prod) => prod.id === productId);
            if (!product) return state; // Si no se encuentra el producto, retornamos el estado sin cambios

            // Seleccionamos el primer variant del producto
            const variant = product.variants[0];
            if (!variant) return state; // Si no hay variantes, retornamos el estado sin cambios

            // Accedemos a la lista de extras del variant
            const extras = variant.extras;
            if (!extras) return state; // Si no hay extras, retornamos el estado sin cambios

            // Inicializamos un array para almacenar todas las combinaciones de extras
            let arregloConbinaciones = [];
            // Iteramos sobre cada extra para manejar sus combinaciones
            extras.forEach((extra) => {
                extra.combination = [];

                const defaul = extra.default; // Obtenemos el array de combinaciones por defecto

                // Si extra.combination no está inicializado, inicializamos con una copia de defaul
                if (!extra.combination) {
                    extra.combination = [...defaul];
                } else {
                    // Si extra.combination ya está inicializado, aseguramos que solo una copia de defaul esté presente
                    const uniqueDefault = new Set(defaul);
                    extra.combination = extra.combination.filter((item) => !uniqueDefault.has(item));

                    //  console.log("defaul aaaaaaaaaaaa", defaul);

                    // Agregamos las combinaciones por defecto al array de combinaciones
                    extra.combination.push(...defaul);
                }

                // Agregamos las nuevas combinaciones al array de combinaciones
                extra.combination.push(...combination);
                arregloConbinaciones.push(...extra.combination);
            });

            // Obtenemos las opciones disponibles en los extras del variant
            const options = variant.extras?.[0]?.options || []; // Aseguramos que options no sea undefined

            // Inicializamos una variable para almacenar la suma total de los precios de las opciones seleccionadas
            let total = 0;

            // Recorremos cada número en arregloConbinaciones
            arregloConbinaciones.forEach((num) => {
                // Convertimos num a número si es necesario
                const numId = Number(num);

                // Buscamos el objeto en options que tenga el mismo id que numId
                const matchedOption = options.find((option) => option.id === numId);

                // Si encontramos el objeto y su precio es mayor que 0, sumamos su precio al total
                if (matchedOption && matchedOption.price > 0) {
                    total += matchedOption.price;
                }
            });

            // Asignamos el total de extras al producto
            product.totalExtras = total;

            // Calculamos el total de productos multiplicando la suma de tamaños, sabores, bebidas y extras por la cantidad del producto
            product.totalProducts = (product.totalSizes + product.totalFlavors + product.totalDrinks + product.totalExtras) * product.quantity;
        },

        insertPriceList: (state, action) => {
            state.step2.priceList = action.payload;
            (state.step2.complete = false), (state.step2.totalStep2 = 0), (state.step2.quantityPeople = 0), (state.step3.seating = []), (state.step4.articules = []);
            state.addSeating.seatsData = [];
            state.step4.business_pro = [];
            state.payment.step = 0;
            state.payment.message = " ";
            state.payment.status = false;
        },
        clearSeat: (state, action) => {
            state.addSeating.seatsData = [];
        },
    },
});

// Action creators generados para cada función reductora
export const {
    clearSeat,
    restoreStateCheckoutNewBd,
    useTarjet,
    useNmaeUser,
    clearTarjet,
    insertFactura,
    cleararticule,
    setPaymentInProgress,
    paymentProcess,
    restoreStateCheckout,
    backStep3Seating,
    insertPriceList,
    insertmessage,
    insertSessionIdSlice,
    selectExtras,
    selectDrinks,
    setdeleteArticule,
    AddArticule,
    updatefromselectallFlavor,
    updatefromselectallSize,
    updatehandleIncreaseQuantity,
    updatehandleDecreaseQuantity,
    updatedataProductsflavors,
    updatedataProductsSizes,
    setInsertDataStep1,
    setActiveChekout,
    updatePriceList,
    updateStep1Complete,
    updateStep2Complete,
    updateStep3Complete,
    updateStep3Seating,
    removeSeatFromSeating,
    getaddSeating,
    InsertArticule,
    clearArticuleList,
    clearPriceList,
} = checkoutSlice.actions; // Currently no actions defined, you can add action creators here

export default checkoutSlice.reducer;
